import $ from 'jquery';

import 'imports-loader?imports=default|jquery|jQuery!jquery-ui';
import 'imports-loader?imports=default|jquery|jQuery!tablesorter/dist/js/jquery.tablesorter.js';
import 'imports-loader?imports=default|jquery|jQuery!tablesorter/dist/js/widgets/widget-uitheme.min.js';
import 'imports-loader?imports=default|jquery|jQuery!tablesorter/dist/js/widgets/widget-sortTbodies.min.js';
import 'imports-loader?imports=default|jquery|jQuery!tablesorter/dist/js/widgets/widget-filter.min.js';

import 'tablesorter/dist/css/theme.default.min.css';
import './css/tablesorter.css';
// make DOM tables sortable
import {uimanager} from 'components/ui-manager';

(function ($) {
  // reconfigure tablesorter

  $.tablesorter.themes.mavora = {
    // these classes are added to the table. To see other table classes available,
    // look here: http://getbootstrap.com/css/#tables
    table: 'table',
    caption: 'caption',
    // header class names
    header: 'bootstrap-header', // give the header a gradient background (theme.bootstrap_2.css)
    sortNone: '',
    sortAsc: '',
    sortDesc: '',
    active: '', // applied when column is sorted
    hover: '', // custom css required - a defined bootstrap style may not override other classes
    // icon class names
    icons: '', // add "icon-white" to make them white; this icon class is added to the <i> in the header
    iconSortNone: 'bootstrap-icon-unsorted', // class name added to icon when column is not sorted
    iconSortAsc: 'glyphicon glyphicon-chevron-up', // class name added to icon when column has ascending sort
    iconSortDesc: 'glyphicon glyphicon-chevron-down', // class name added to icon when column has descending sort
    filterRow: '', // filter row class; use widgetOptions.filter_cssFilter for the input/select element
    footerRow: '',
    footerCells: '',
    even: '', // even row zebra striping
    odd: '' // odd row zebra striping
  };

  $.tablesorter.addParser({
    id: 'casenumber',
    is: function () {
      return false;
    },
    format: function (s) {
      return s.replace(/^(\d+)\/(\d+)$/, '$2/$1');
    },
    type: 'text'
  });

  $.tablesorter.addParser({
    id: 'dummy',
    is: function () {
      return false;
    },
    format: function (s) {
      return '';
    },
    type: 'text'
  });
})($);

uimanager.add(function (el) {
  const $dom_element = $(el);
  var add_tablesorter = function ($entity) {
    var widgets = [
      'uitheme', 'filter'
    ];
    var widgetOptions = {
      filter_liveSearch: true,
      // filter_hideFilters : true,
      filter_columnFilters: false,
      filter_serversideFiltering: true
    };
    if ($entity.find('tbody:not(.tablesorter-no-sort)').length > 1) {
      widgets.push('sortTbody');
      widgetOptions['sortTbody_primaryRow'] = 'tr';
    }

    // reflect initial sorting order
    var column = 0;
    var sortList = [];
    $entity.find('thead').first().find('th').each(function () {
      var sorting_order = $(this).data('sorting-order');
      if (sorting_order === 'asc') {
        sortList.push([
          column, 0
        ]);
      } else if (sorting_order === 'desc') {
        sortList.push([
          column, 1
        ]);
      }
      column += parseInt($(this).attr('colspan')) || 1;
    });

    // restrict sorting actions if we have incomplete data
    if ($entity.data('incomplete')) {
      $entity.find('thead').first().find('th').each(function () {
        // allow sorting only for columns with server-side sorting url
        if ($(this).data('sorting-url'))
          $(this).attr('data-sorter', 'dummy'); // prevent client-side sorting (serverSideSorting does not seem to work)
        else
          $(this).attr('data-sorter', 'false');
      });
    }

    $entity.tablesorter({
      theme: 'mavora',
      widgets: widgets,
      sortResetKey: '', // FIXME: sort reset is disabled as it breaks sorting in the current version
      headerTemplate: '{content} {icon}',
      sortList: sortList,
      serverSideSorting: $entity.data('incomplete'),
      cancelSelection: false,
      sortStable: true,
      cssInfoBlock: "tablesorter-no-sort",
      widgetOptions: widgetOptions
    }).on('sortBegin', function (e, table) {
      // if table is incomplete, navigate to server-side sorting url instead
      if ($(table).data('incomplete') && !$(table).data('filtered')) {
        // determine column that has been clicked
        var target_column_index = table.config.sortList[0][0], current_column_index = 0, column = null;
        $(table).find('thead').first().find('th').each(function () {
          if (target_column_index === current_column_index)
            column = this;
          current_column_index += parseInt($(this).attr('colspan')) || 1;
        });

        // redirect to server-side sorting url
        var url = $(column).data('sorting-url');

        if ($(column).attr('data-custom-order'))
          url = update_sorting_url(url, $.parseJSON($(column).attr('data-custom-order')));

        window.location.href = url;

        // throw error to abort local sorting
        /*error = new Error('abort local sorting due to incomplete table (redirect to server-side sorting url instead)');
        error.ignore_error = true;
        throw error;*/
      }

      if ($(table).data('filtered')) {
        // $(table).trigger('sortReset');
        $(table).trigger('update');
      }
    }).on('sortStart', function (e, table) {
      // disable sorting for filtered results as it is not implemented yet
      if ($(table).data('filtered'))
        throw {
          message: "cannot sort filtered results",
          ignore_error: true
        };
    });

    // cache initial table rows
    var $table = $entity, last_search_query = '';
    var detached_table_rows = {};
    var unfiltered_table_row_ids = [];
    $table.find('[data-mavodb-row]').each(function () {
      var id = $(this).attr('data-mavodb-row');
      unfiltered_table_row_ids.push(id);
    });
    var $rows = $table.find('[data-mavodb-rows]').addBack('[data-mavodb-rows]');
    var dbname = $rows.attr('data-mavodb-rows');
    var row_template = $rows.attr('data-mavodb-row-template') || '<tr data-mavodb-row="{key}"><td>loading...</td></tr>';
    var row_api_url = $rows.attr('data-mavodb-row-api') || '';
    var max_rows = 10;
    var current_max_rows = max_rows;

    // implement search functionality
    $dom_element.find('.panel_search').each(function () {
      if ($(this).data('panel_search_initialized'))
        return;
      else
        $(this).data('panel_search_initialized', true);
      $(this).bind('change keyup', function () {
        var search_query = $(this).val();

        // set current_max_rows
        if (last_search_query !== undefined)
          current_max_rows = max_rows;

        // avoid redundant search queries
        if (search_query === last_search_query)
          return;
        last_search_query = search_query;

        // propagate value to all other panel_search fields
        $('.panel_search').each(function () {
          if ($(this).val() !== search_query)
            $(this).val(search_query);
        });

        // detach all rows from the table
        $table.find('[data-mavodb-row]').each(function () {
          var id = $(this).attr('data-mavodb-row');
          if (detached_table_rows[id] === undefined)
            detached_table_rows[id] = {};
          detached_table_rows[id].$row = $(this).detach();
          detached_table_rows[id].detached = true;
        });

        // query ids that match the search query (or use the initial list of row ids if the search query is cleared)
        var ids = undefined;
        if (search_query)
          ids = $('[data-mavodb=' + dbname + ']').mavodb('get_ids_by_search_query', search_query);
        else
          ids = unfiltered_table_row_ids;

        // attach matching rows
        var missing_ids = [];
        for (const i in ids) {
          if (search_query && i >= current_max_rows)
            break;

          var id = ids[i];

          let shouldProcess = false;
          if (detached_table_rows[id] === undefined) {
            missing_ids.push(id);

            var template = row_template.replace('{key}', id).replace('__ID__', id);

            var row_data = $('[data-mavodb=' + dbname + ']').mavodb('get_by_id', id);
            if (row_data)
              for (var key in row_data)
                template = template.replace('{' + key + '}', $('<span/>').text(row_data[key]).html());

            var $row = $(template).attr('data-mavodb-row', id);
            detached_table_rows[id] = {
              '$row': $row,
              'is_placeholder': true
            };

            uimanager.process($row.get(0));
          } else if (detached_table_rows[id].is_placeholder) {
            missing_ids.push(id);
          } else {
            // Re-process every time the node is mounted.
            shouldProcess = true;
          }

          detached_table_rows[id].$row.appendTo($rows);
          detached_table_rows[id].detached = false;
          if (shouldProcess) {
            try {
              uimanager.process(detached_table_rows[id].$row.get(0));
            } catch (e) {
              console.error(e);
            }
          }
        }

        // request missing rows via AJAX
        if (missing_ids.length > 0) {
          const that = this;
          const query_string = {
            items: missing_ids.join(',')
          };
          $.getJSON(row_api_url + '?' + $.param(query_string), function ({rows}) {
            // process items
            for (const row of rows) {
              let {id, html} = row;
              let $row = $(html);
              uimanager.process($row.get(0));

              if (detached_table_rows[id].is_placeholder) {
                if (detached_table_rows[id].detached)
                  detached_table_rows[id].$row = $row;
                else
                  detached_table_rows[id].$row.replaceWith($row);
                delete detached_table_rows[id].is_placeholder;
              }
            }
          }).fail(function () {
            // retry after 2 seconds
            console.warn('fetching missing rows failed, retry');
            setTimeout(function () {
              last_search_query = undefined;
              $(that).change();
            }, 5000);

            // FIXME: if there are repeating errors, the server will receive a lot of requests if the user
            // continues issuing search queries

            // note that it is safe to continue retrying as the AJAX query is only executed
            // if the respective IDs are actually still missing (i.e., retry will not continue
            // if the items have been fetched in the interim due to another search query)
          });
        }

        // update panel title
        var $panel_title = $('#panel_title');
        if (!$panel_title.attr('data-title-unfiltered'))
          $panel_title.attr('data-title-unfiltered', $panel_title.html());
        if (search_query) {
          var title_template = $panel_title.attr('data-title-filtered') || 'Suchergebnisse';
          if (ids.length === 1)
            title_template = $panel_title.attr('data-title-filtered-one') || $panel_title.attr('data-title-filtered') || 'Suchergebnisse'
          $panel_title.text(title_template.replace('{count}', ids.length));
        } else
          $panel_title.html($panel_title.attr('data-title-unfiltered'));

        $rows.trigger('update'); // update MUST take place before sortReset
        $rows.trigger('sortReset');

        // hide pagination links if filter is enabled
        if (search_query)
          $('#panel_pagination').addClass('hidden');
        else
          $('#panel_pagination').removeClass('hidden');

        // show search extension link if applicable
        if (search_query && ids.length > current_max_rows)
          $('#panel_search_extend').removeClass('hidden');
        else
          $('#panel_search_extend').addClass('hidden');

        // store whether we show filtered results
        if (search_query)
          $table.data('filtered', 1);
        else
          $table.data('filtered', 0);

        // update sort links
        if (search_query) {
          // disable sort links
          $table.find('thead').first().find('th').each(function () {
            if (!$(this).attr('data-sorter-unfiltered-set')) {
              $(this).attr('data-sorter-unfiltered-set', true);
              $(this).attr('data-sorter-unfiltered', $(this).attr('data-sorter'));
            }
            $(this).attr('data-sorter', 'false');
            $(this).find('.tablesorter-icon').addClass('hidden');
          });
        } else {
          // re-enable sort links
          $table.find('thead').first().find('th').each(function () {
            if ($(this).attr('data-sorter-unfiltered-set')) {
              if ($(this).attr('data-sorter-unfiltered') !== undefined)
                $(this).attr('data-sorter', $(this).attr('data-sorter-unfiltered'));
              else {
                $(this).removeAttr('data-sorter');
                $(this).removeClass('sorter-false');
              }
              $(this).removeAttr('data-sorter-unfiltered-set');
              if ($(this).attr('data-sorter') !== 'false')
                this.sortDisabled = false;
            }
            $(this).find('.tablesorter-icon').removeClass('hidden');
          });
        }

        // TODO: implement sorting of filtered results
      });
    });

    // allow enter in search box if there is only one result
    $dom_element.find('#panel_search').each(function () {
      if ($(this).data('panel_search_keyup_initialized'))
        return;
      else
        $(this).data('panel_search_keyup_initialized', true);
      $(this).on('keyup', function (event, data) {
        if (event.keyCode == 13) {
          var $current_rows = $table.find('[data-mavodb-row]');
          if ($current_rows.length == 1)
            $current_rows.get(0).click();
        }
      });
    });

    // allow to extend search results
    $dom_element.find('#panel_search_extend a').each(function () {
      if ($(this).data('panel_search_extend_initialized'))
        return;
      else
        $(this).data('panel_search_extend_initialized', true);
      $(this).on('click', function (evt) {
        current_max_rows = current_max_rows + max_rows;
        last_search_query = undefined;
        $('.panel_search').first().change();
        evt.preventDefault();
        this.blur();
      });
    });
  };

  $dom_element.find('.tablesorter').addBack('.tablesorter').each(function () {
    var that = this;
    if (!$(that).find('.still-encrypted').length)
      // there is no encrypted data, we can add the tablesorter immediately
      add_tablesorter($(this));
    else
      // initialize the tablesorter as soon as all data has been decrypted
      $(that).on('decrypted', function () {
        if (!$(that).find('.still-encrypted').length)
          add_tablesorter($(that));
      });
  });

  var update_sorting_url = function (url, data) {
    // determine data from url
    var query_string = {};
    url.split("?")[1].split('&').forEach(function (pair) {
      if (pair === "")
        return;
      var parts = pair.split("=");
      var key = parts[0];
      var value = decodeURIComponent(parts[1]);
      query_string[key] = value;
    });

    // include data from query string
    for (var key in query_string)
      if (!data[key])
        data[key] = query_string[key];

    // determine range
    var bottom = ((data.page || 1) - 1) * (data.paginate_by);
    var top = bottom + data.paginate_by + data.paginate_orphans - 1;

    // determine ids
    var item_list = $('[data-mavodb=' + data.db + ']').mavodb('get_ids_for_range', data.order_by, bottom, top);

    // build updated url
    query_string.items = item_list.join();
    return url.split("?")[0] + '?' + $.param(query_string);
  };

  // add support for client-supported server-side sorting
  $dom_element.find('[data-custom-order-link]').addBack('[data-custom-order-link]').each(function () {
    $(this).click(function (evt) {
      var that = this;

      // determine data from url
      var url = $(this).attr('href');
      try {
        $(this).attr('href', update_sorting_url(url, $.parseJSON($(this).attr('data-custom-order-link'))));
      } catch (e) {
        if (e !== 'database not ready')
          throw e;

        // wait a little if database is not ready yet
        // TODO: show status information to user
        $(this).attr('data-retry', '1');
        evt.preventDefault();
        console.warn('database not ready for custom sort order - try again later');
        setTimeout(function () {
          $(that).click();
        }, 300);
        return false;
      }
      if ($(this).attr('data-retry'))
        window.location.href = $(this).attr('href');
      else
        return true;
    });
  });
});
