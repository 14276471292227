import React, {useState, useEffect} from 'react';
import {TextDocumentViewer} from "./index";
import {getDocument} from "../../../features/entities/document";
import { useSelector } from 'react-redux';
import {getView} from "../../../features/ui/view";
import UnsupportedDocumentViewer from "./UnsupportedDocumentViewer";

export const RELATED_OBJECT_DOCUMENT_VIEWERS = {
};

export default function DocumentViewer({id, ...props}) {
  const open = useSelector(getView('viewDocument', id)).visible;
  const [openedOnce, setOpenedOnce] = useState(open);

  const {related_object} = useSelector(getDocument(id));
  const relatedObjectType = related_object?.type;

  useEffect(() => {
    if (open) {
      setOpenedOnce(true);
    }
  }, [open])

  if (!openedOnce) {
    return null;
  }

  if (related_object) {
    const RelatedObjectDocumentViewer = RELATED_OBJECT_DOCUMENT_VIEWERS[relatedObjectType];

    return (
      RelatedObjectDocumentViewer ? (
        <RelatedObjectDocumentViewer id={id} {...props}/>
      ) : (
        <UnsupportedDocumentViewer id={id} {...props}/>
      )
    );
  } else {
    return (
      <TextDocumentViewer id={id} {...props}/>
    );
  }
}
