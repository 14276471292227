import {forEachMatchingElement, uimanager} from 'ui-manager';

import React from 'react';

import {HelpPopOverButton} from '../components/help';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getOrCreateRoot} from "../utils/renderer-utils";
import {Providers} from "../ui/providers";

uimanager.add(el =>
  // Instantiate help-button components.
  forEachMatchingElement(el, "[data-help-button]", node => {
    const {helpURL, tabIndex, loadTimeoutMs = 2500, faIcon} = JSON.parse(node.dataset.helpButton);
    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <HelpPopOverButton
          icon={faIcon && <FontAwesomeIcon icon={faIcon}/>}
          url={helpURL}
          loadTimeoutMs={loadTimeoutMs}
          tabIndex={tabIndex}
        />
      </Providers>
    );
  })
);
