import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import {PersonAddress, PersonName, usePersonLinkAsVCardMaker} from "./person";
import {
  useAddressesPerson,
  useAddressesPersonLink,
} from "../../features/addresses/addresses-hooks";
import {Box} from "@mui/material";
import {useDecryptedEntityApi} from "../../features/decryptedEntity/decrypted-entity-hooks";
import {ENTITY_LIST_URLS, ENTITY_TYPES} from "../../api/api-schemas";
import {PersonLinkForm} from "./forms/PersonLinkForm";
import {OwnPersonLinkForm} from "./forms/OwnPersonLinkForm";
import {ForeignPersonLinkForm} from "./forms/ForeignPersonLinkForm";
import {DownloadButton} from "./DownloadButton";
import Typography from "@mui/material/Typography";


export function PersonLinkPanel({id, sx, onEdit, onHide, contextPersonId, onShowPerson, readOnly, showButtons, showDefault, onShowLists, topButtonsSx, ...props}) {
  const [editPersonLink, setEditPersonLink] = useState(null);

  const {
    parent_person: parentPersonId,
    child_person: childPersonId,
    database,
    isDeleted,
  } = useAddressesPersonLink({id});
  const parent_person = useAddressesPerson({id: parentPersonId, observe: true});

  const makeVCard = usePersonLinkAsVCardMaker({id});

  const isOwnAddress = parentPersonId === childPersonId;
  const isSourceContext = parentPersonId === contextPersonId;

  const SuitablePersonLinkForm = isOwnAddress ? OwnPersonLinkForm : isSourceContext ? PersonLinkForm : ForeignPersonLinkForm;

  useEffect(() => {
    if (isDeleted) {
      onHide();
    }
  }, [isDeleted]);

  const entityApi = useDecryptedEntityApi(ENTITY_TYPES['addresses_person']);
  const baseUrl = ENTITY_LIST_URLS['addresses_person'];

  const [updating, setUpdating] = useState(false);

  const update = async (validatedData) => {
    const {id} = validatedData;

    setUpdating(true);

    try {
      await entityApi.patch(
        `${baseUrl}${id}/`,
        validatedData,
      );
    } finally {
      setUpdating(false);
    }
  };

  return (
    <Box sx={sx}>
      {(parent_person?.default_person_link === id || showButtons) ? (
        <Box px={1} pt={1} sx={{display: 'flex', flexDirection: 'row', maxWidth: '67vw', width: '100%'}}>
          {showDefault ? (
            <Box sx={{flex: 1, display: 'flex', overflow: 'hidden'}}>
              {parent_person?.default_person_link === id ? (
                <Typography variant="body1" style={{whiteSpace: 'nowrap', display: 'inline-block', alignSelf: 'flex-end', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}} component={Box}>
                  Standard-{isOwnAddress ? "Adresse" : "Kontakt"} für <PersonName id={parentPersonId}/>
                </Typography>
              ) : null}
            </Box>
          ) : null}
          {showButtons ? (
            <Box sx={{flex: 0, textAlign: 'right', pl: 1, maxWidth: '100%', ...(topButtonsSx || {})}}>
              {showButtons && onShowPerson && parentPersonId !== contextPersonId && (
                <Button
                  onClick={() => onShowPerson(parentPersonId)}
                  sx={{maxWidth: '100%', overflow: 'hidden'}}
                >
                  <Box sx={{whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    Zeige <PersonName id={parentPersonId}/>
                  </Box>
                </Button>
              )}
              {showButtons && onShowPerson && childPersonId !== parentPersonId && childPersonId !== contextPersonId && (
                <Button
                  onClick={() => onShowPerson(childPersonId)}
                  sx={{maxWidth: '100%', overflow: 'hidden'}}
                >
                  <Box sx={{whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    Zeige <PersonName id={childPersonId}/>
                  </Box>
                </Button>
              )}
              {showButtons && onShowLists && (
                <Button
                  onClick={() => onShowLists()}
                  sx={{whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}
                >
                  <Box sx={{whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    Verteiler suchen...
                  </Box>
                </Button>
              )}
            </Box>
          ) : null}
        </Box>
      ) : null}
      <PersonAddress
        key={id}
        id={id}
        contextPersonId={contextPersonId}
        onShowPerson={onShowPerson}
        {...props}
      />
      {editPersonLink !== null ? (
        <SuitablePersonLinkForm
          editPersonLink={editPersonLink}
          onClose={() => setEditPersonLink(null)}
          databaseId={database}
        />
      ) : null}
      {showButtons ? (
        <Box sx={{textAlign: 'right', px: 1, pb: 1}}>
          <DownloadButton
            color="primary"
            buildBlob={makeVCard}
            variant="outlined"
            options={[
              {
                id: 'vcard',
                name: "vCard",
                caption: "vCard herunterladen",
              },
            ]}
            defaultOption="vcard"
            sx={{mr: 1}}
          />
          {contextPersonId && !readOnly && (
            <>
              {parent_person?.default_person_link !== id && parentPersonId === contextPersonId && !readOnly && (
                <Button
                  disabled={updating}
                  onClick={() => update({id: contextPersonId, default_person_link: id})}
                >
                  als Standard-{isOwnAddress ? "Adresse" : "Kontakt"} festlegen
                </Button>
              )}
              <Button
                onClick={() => setEditPersonLink(id)}
              >
                {isOwnAddress ? "Adresse" : "Kontakt"} bearbeiten
              </Button>
            </>
          )}
        </Box>
      ) : null}
    </Box>
  );
}
