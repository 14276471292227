import React from 'react';
import {Box} from "@mui/material";
import {useAddressesDatabase} from "../../features/addresses/addresses-hooks";
import AddressDatabaseMenu from "./AddressesDatabaseMenu";

export default function AddressDatabaseTitle({id, children, ...props}) {
  const database = useAddressesDatabase({id});

  return (
    <>
      {children}
      {database?.id ? (
        <Box sx={{position: 'absolute', right: 8, top: 8}}>
          <AddressDatabaseMenu id={database?.id} {...props}/>
        </Box>
      ) : null}
    </>
  );
}
