import {PERSON_SCHEMA} from "../validation";
import React from "react";
import AddressEntityFormDialog from "./AddressEntityFormDialog";
import {Collapse, Grid} from "@mui/material";
import {YupField} from "../../../packages/react-hook-form-mui-yup-helpers";
import {useFormContext} from "react-hook-form";
import {usePersonFieldSuggestions} from "../../../features/addresses/addresses-hooks";

function PersonFormContent({databaseId}) {
  const {watch} = useFormContext();
  const type = watch('type');
  const firstName = watch('first_name');
  const birthday = watch('birthday');
  const titlePrefix = watch('title_prefix');
  const titleSuffix = watch('title_suffix');

  const showNaturalPersonFields = type !== 'company' || firstName || birthday || titlePrefix || titleSuffix;

  const titlePrefixSuggestions = usePersonFieldSuggestions({databaseId, field: 'title_prefix'});
  const titleSuffixSuggestions = usePersonFieldSuggestions({databaseId, field: 'title_suffix'});

  return (
    <>
      <Grid item xs={12} pb={2}>
        <YupField name="type"/>
      </Grid>

      <Collapse in={!!showNaturalPersonFields} component={Grid} item xs={12} sm={6} pb={showNaturalPersonFields ? 2 : 0} pr={showNaturalPersonFields ? 2 : 0}>
        <YupField
          name="title_prefix"
          suggestions={titlePrefixSuggestions}
        />
      </Collapse>

      <Collapse in={!!showNaturalPersonFields} component={Grid} item xs={12} sm={6} pb={showNaturalPersonFields ? 2 : 0}>
        <YupField
          name="title_suffix"
          suggestions={titleSuffixSuggestions}
        />
      </Collapse>

      <Grid item xs={12} sm={showNaturalPersonFields ? 6 : 12} pb={2} pr={showNaturalPersonFields ? 2 : 0}>
        <YupField name="name"/>
      </Grid>

      <Collapse in={!!showNaturalPersonFields} component={Grid} item xs={12} sm={6} pb={showNaturalPersonFields ? 2 : 0}>
        <YupField name="first_name"/>
      </Collapse>
      <Collapse in={!!showNaturalPersonFields} component={Grid} item xs={12} pb={showNaturalPersonFields ? 2 : 0}>
        <YupField name="birthday"/>
      </Collapse>

      <Grid item xs={12} sm={6} pb={2} pr={2}>
        <YupField name="vat_id"/>
      </Grid>
      <Grid item xs={12} sm={6} pb={2}>
        <YupField name="tax_id"/>
      </Grid>

      <Grid item xs={12} sm={6} pb={2} pr={2}>
        <YupField name="iban"/>
      </Grid>
      <Grid item xs={12} sm={6} pb={2}>
        <YupField name="bic"/>
      </Grid>
    </>
  );
}

export function PersonForm({
  databaseId,
  editPerson,
  onClose,
  ...props
}) {
  return (
    <AddressEntityFormDialog
      baseUrl="/api/addresses/people/"
      databaseId={databaseId}
      id='edit-person'
      entityId={editPerson}
      entityName="Person"
      entityType="addresses_person"
      onClose={onClose}
      schema={PERSON_SCHEMA}
      renderDefaultChildren
      excludeDefaultChildren={[
        'type',
        'name',
        'first_name',
        'title_prefix',
        'title_suffix',
        'birthday',
        'iban',
        'bic',
        'vat_id',
        'tax_id',
      ]}
      allowDelete
      {...props}
    >
      <Grid container>
        <PersonFormContent databaseId={databaseId}/>
      </Grid>
    </AddressEntityFormDialog>
  );
}
