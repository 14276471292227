import React, {useEffect, useState} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import PeopleIcon from '@mui/icons-material/People';
import ListIcon from '@mui/icons-material/List';
import {PeoplePanel} from "./PeoplePanel";
import {AppBar} from "@mui/material";
import {useAddressesDatabase} from "../../features/addresses/addresses-hooks";
import {useDispatch} from "react-redux";
import {displayed} from "../../features/entities/document";
import {ListsTab} from "./ListsTab";
import {PersonDialog} from "./PersonDialog";
import {PersonForm} from "./forms/PersonForm";

function a11yProps(index) {
  return {
    id: `addresses-tab-${index}`,
    'aria-controls': `addresses-tabpanel-${index}`,
  };
}

function CompactTab({icon, label, ...props}) {
  return (
    <Tab
      sx={{pt: 0, pb: 0}}
      label={
        <Stack direction="row" alignItems="center" gap={1}>
          {icon}
          {label}
        </Stack>
      }
      {...props}
    />
  );
}

function AddressesTabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`addresses-tabpanel-${index}`}
      aria-labelledby={`addresses-tab-${index}`}
      sx={{flex: 1, p: 0, display: 'flex', flexDirection: 'column'}}
      {...other}
    >
      {value === index && (
        // <Box sx={{p: 0, background: 'blue'}}>
          children
        // </Box>
      )}
    </Box>
  );
}

export default function AddressDatabaseContent({id}) {
  const [value, setValue] = useState(0);

  const {document, can_update: canUpdate} = useAddressesDatabase({id});
  const dispatch = useDispatch();

  useEffect(() => {
    if (document) {
      dispatch(displayed({documentId: document}));
    }
  }, [document])

  const [context, setContext] = useState(null);

  const [editPerson, setEditPerson] = useState(null);
  const [showPerson, setShowPerson] = useState(null);

  const [mostRecentlyAddedPerson, setMostRecentlyAddedPerson] = useState(null);

  const showLists = (personLinkId) => {
    setContext({personLinkId});
    setValue(1);
  }

  const handleChange = (event, newValue) => {
    setContext(null);
    setValue(newValue);
  };

  return (
    <>
      <AppBar sx={{position: 'relative', top: 0}} color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <CompactTab
            icon={<PeopleIcon/>}
            label="Personen"
            {...a11yProps(0)}
          />
          <CompactTab
            icon={<ListIcon/>}
            label="Verteiler und Events"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <Box sx={{position: 'absolute', top: 114, bottom: 0, left: 0, width: '100%', display: 'flex', flexDirection: 'row'}}>
        {value === 0 ? (
          <AddressesTabPanel
            value={value}
            index={0}
          >
            <PeoplePanel
              databaseId={id}
              onEditPerson={canUpdate ? setEditPerson : null}
              onShowPerson={setShowPerson}
            />
          </AddressesTabPanel>
        ) : value === 1 ? (
          <AddressesTabPanel
            value={value}
            index={1}
          >
            <ListsTab
              databaseId={id}
              onShowPerson={setShowPerson}
              context={context}
            />
          </AddressesTabPanel>
        ) : null}
        <PersonDialog
          id={showPerson}
          open={!!showPerson}
          onClose={() => setShowPerson(null)}
          onShowPerson={setShowPerson}
          onEdit={canUpdate ? () => setEditPerson(showPerson) : null}
          onAdd={canUpdate ? () => setEditPerson(-1) : null}
          mostRecentlyAddedPerson={mostRecentlyAddedPerson}
          readOnly={!canUpdate}
          onShowLists={showLists}
        />
        {editPerson !== null ? (
          <PersonForm
            databaseId={id}
            editPerson={editPerson}
            onClose={() => setEditPerson(null)}
            onCreate={(id) => {
              setMostRecentlyAddedPerson(id);
              if (!showPerson) {
                setShowPerson(id);
              }
            }}
            readOnly={!canUpdate}
          />
        ) : null}
      </Box>
    </>
  );
}
