import SwaggerClient from "swagger-client/dist/swagger-client.browser";
import Cookies from "js-cookie";

const spec = require('./schema.json');

const specUrl = '/api/schema.json';

const client = new SwaggerClient(specUrl, {
  requestInterceptor: req => {
    req.headers['X-CSRFToken'] = Cookies.get('csrftoken');
    return req;
  },
  spec,
});

export const api = client.then(swaggerClient =>
  swaggerClient.apis.default
);

export const telemetryApi = client.then(swaggerClient =>
  swaggerClient.apis.telemetry
);

export const apiURL = (operationId, parameters) =>
  SwaggerClient.buildRequest({spec, operationId, parameters}).url;

export function* callAPI({operationId, payload, data, url}) {
  const request = (yield api)[operationId](payload, data);

  // const request2 = SwaggerClient.buildRequest({
  //   spec,
  //   //operationId,
  //   parameters: payload,
  //   responseContentType: 'application/json',
  //   server: url,
  // });
  // console.log({url, operationId, payload});
  // const request2 = client.then(client => client.execute({
  //   spec,
  //   //operationId,
  //   pathName: url,
  //   method: 'list',
  //   parameters: payload,
  //   //responseContentType: 'application/json',
  //   //server: url,
  // }));
  // try {
  //   const response2 = yield request2; //SwaggerClient.http(request2);
  //   console.log({request2, response2});
  // } catch(e) {
  //   console.error({e});
  // }

  const response = yield request;

  // console.log({request, response});
  return response;
}
