import {useSelector} from "react-redux";
import {useDecryptedEntityObserver} from "../decryptedEntity/decrypted-entity-hooks";
import {
  getAddressesDatabaseGetter, getAddressesEntityGetter,
  getAddressesListEntryGetter,
  getAddressesListGetter,
  getAddressesLocationGetter,
  getAddressesPersonGetter,
  getAddressesPersonLinkGetter, getAddressesTemplateGetter
} from "./addresses-slice";
import _ from "lodash";
import unidecode from "unidecode";
import {ENTITY_TYPES} from "../../api/api-schemas";
import {produce} from "immer";
import React from "react";

function createHookFromGetter({type, getter, observeByDefault = false}) {
  return ({id, observe = observeByDefault}) => {
    const observeId = observe ? id : null;
    useDecryptedEntityObserver({type, id: observeId});

    const get = useSelector(getter);
    return get(id);
  };
}

export function useAddressesEntities({type, expandedFields, ids}) {
  const getGetter = useSelector(getAddressesEntityGetter);
  const getPlainEntity = getGetter(type);

  const entitySchema = ENTITY_TYPES[type];

  function getFieldGetters(schema, expandedFields) {
    const nestedSchemas = {...schema?.schema, ...schema?.additionalNestedFields};

    const fieldGetters = {};
    expandedFields?.forEach((qualifiedFieldName) => {
      const [fieldName, fieldNameRemainder] = qualifiedFieldName.split('.', 2);
      // console.log({qualifiedFieldName, fieldName, fieldNameRemainder});
      let fieldSchema = nestedSchemas?.[fieldName];
      if (_.isArray(fieldSchema) && fieldSchema?.length === 1) {
        fieldSchema = fieldSchema[0];
      }

      // Determine first-level field getter.
      if (!fieldGetters[fieldName]) {
        if (fieldSchema) {
          const fieldType = fieldSchema.key;
          fieldGetters[fieldName] = getGetter(fieldType);
        }
      }

      // Add nested field getters.
      if (fieldNameRemainder) {
        Object.entries(getFieldGetters(fieldSchema, [fieldNameRemainder])).forEach(
          ([nestedFieldName, getter]) => {
            fieldGetters[`${fieldName}.${nestedFieldName}`] = getter;
          }
        )
      }
    });

    return fieldGetters;
  }

  let getEntity;
  if (expandedFields) {
    const fieldGetters = getFieldGetters(entitySchema, expandedFields);

    getEntity = (id) => produce(getPlainEntity(id), draft => {
      Object.entries(fieldGetters).forEach(([fieldName, fieldGetter]) => {
        const fieldValue = _.get(draft, fieldName);
        if (_.isArray(fieldValue)) {
          _.set(draft, fieldName, fieldValue.map(fieldGetter));
        } else {
          _.set(draft, fieldName, {...fieldGetter(fieldValue)});
        }
      });
    });
  } else {
    getEntity = getPlainEntity;
  }

  return ids?.map(getEntity);
}

export function useAddressesEntity({type, id, expandedFields, observe=false}) {
  const observeId = observe ? id : null;
  useDecryptedEntityObserver({type, id: observeId});

  const [entity] = useAddressesEntities({type, expandedFields, ids: [id]});
  return entity;
  //
  // const get = useSelector(getAddressesEntityGetter)(type);
  // return get(id);
}

export const useAddressesPerson = createHookFromGetter({
  type: 'addresses_person',
  getter: getAddressesPersonGetter,
});

export const useAddressesLocation = createHookFromGetter({
  type: 'addresses_location',
  getter: getAddressesLocationGetter,
});

export const useAddressesPersonLink = createHookFromGetter({
  type: 'addresses_person_link',
  getter: getAddressesPersonLinkGetter,
});

export const useAddressesList = createHookFromGetter({
  type: 'addresses_list',
  getter: getAddressesListGetter,
});

export const useAddressesListEntry = createHookFromGetter({
  type: 'addresses_list_entry',
  getter: getAddressesListEntryGetter,
});

export const useAddressesTemplate = createHookFromGetter({
  type: 'addresses_template',
  getter: getAddressesTemplateGetter,
});

export const useAddressesDatabase = createHookFromGetter({
  type: 'addresses_database',
  getter: getAddressesDatabaseGetter,
});

function normalize(x) {
  if (_.isString(x)) {
    return unidecode(x).toLowerCase();
  } else {
    return x;
  }
}

function descendingComparator(a, b) {
  if (b < a) {
    return -1;
  } else if (b > a) {
    return 1;
  } else {
    return 0;
  }
}

const ascendingComparator = (a, b) => -descendingComparator(a, b);

export function useEntitiesSorter({entities, field, fieldGetters={}, order='asc'}) {
  const comparator = order === 'asc' ? ascendingComparator : descendingComparator;
  const fieldGetter = fieldGetters[field] || (data => data?.[field]);

  if (field) {
    return entities?.map(x => x).sort(({id: idA, ...dataA}, {id: idB, ...dataB}) => comparator(normalize(fieldGetter({id: idA, ...dataA})), normalize(fieldGetter({id: idB, ...dataB}))));
  } else {
    return entities;
  }
}

export function useEntitiesSearcher({entities, fields, fieldGetters={}, searchTerm}) {
  searchTerm = normalize(searchTerm);
  const searchTerms = searchTerm.split(/[\s,;]/);
  return entities?.map(({id, ...data}) => [normalize(fields?.map(field => (fieldGetters[field] || (data => _.get(data, field)))(data)).join(' ')), id]).filter(([value, _]) => searchTerms?.every(searchTerm => value?.includes(searchTerm))).map(([_, id]) => id).filter(id => id);
}

export function usePersonRelatedPersonLinks({id, type}) {
  const {database} = useAddressesPerson({id});
  const {person_links: allPersonLinkIds} = useAddressesDatabase({id: database});
  const getPersonLink = useSelector(getAddressesPersonLinkGetter);

  let filter;
  if (type === 'ownAddress') {
    filter = ({parent_person, child_person}) => parent_person === id && child_person === id;
  } else if (type === 'foreignAddress') {
    filter = ({parent_person, child_person}) => parent_person === id && child_person !== id;
  } else if (type === 'address') {
    filter = ({parent_person, child_person}) => parent_person === id;
  } else if (type === 'reverseRelation') {
    filter = ({parent_person, child_person}) => child_person === id && parent_person !== id;
  } else if (!type) {
    filter = ({parent_person, child_person}) => parent_person === id || child_person === id;
  } else {
    throw `unsupported type: ${type}`;
  }

  return allPersonLinkIds?.map(getPersonLink)?.filter(filter).map(({id}) => id);
}

export function useAddressesLists({ids}) {
  const getAddressesList = useSelector(getAddressesListGetter);
  return ids?.map(getAddressesList).reduce(({ids, databases, entries, names, is_event, isDeleted}, list) => ({
    ids: [...ids, list?.id],
    databases: [...databases, list?.database],
    entries: [...entries, ...(list?.entries || [])],
    names: [...names, list?.name],
    is_event: is_event && list?.is_event,
    isDeleted: isDeleted || list?.isDeleted,
  }), {
    ids: [],
    databases: [],
    entries: [],
    names: [],
    is_event: true,
    isDeleted: false,
  });
}

export function usePersonRelatedLocations({id}) {
  const personLinks = usePersonRelatedPersonLinks({id, type: 'address'});
  const getPersonLink = useSelector(getAddressesPersonLinkGetter);
  return [...new Set(personLinks?.map(getPersonLink)?.map(({location}) => location))];
}

export function useTemplatesByType({databaseId, type, is_global, excludeTemplatesWithTwoPartners}) {
  const {templates: templateIds} = useAddressesDatabase({id: databaseId});
  const getTemplate = useSelector(getAddressesTemplateGetter);
  let templates = templateIds
    ?.map(getTemplate)
    .filter(template => template?.type === type && (template?.is_global === is_global || is_global === undefined));

  if (excludeTemplatesWithTwoPartners) {
    templates = templates?.filter(
      ({template}) => !(template.includes('{child_person:name}') && template.includes('{parent_person:name}'))
    );
  }

  return templates
    ?.sort(
      (a, b) => ascendingComparator(a?.priority, b?.priority),
    ).map(({id}) => id);
}

export function useDefaultTemplateByType({databaseId, type, excludeTemplatesWithTwoPartners}) {
  const getTemplate = useSelector(getAddressesTemplateGetter);
  const templateIds = useTemplatesByType({databaseId, type, is_global: true, excludeTemplatesWithTwoPartners});
  return templateIds
    ?.map(getTemplate)
    .map(({id}) => id)[0];
}

function getAddressGender(gender) {
  switch (gender) {
    case "male":
      return "Herrn";
    case "female":
      return "Frau";
    case "neutral":
      return "";
    case "company":
      return "";
    default:
      return "";
  }
}

export const TEMPLATE_ABBREVIATIONS = {
  '{salutation!geehrt}': (<>formelle Anrede</>),
  '{salutation!lieb_sie}': (<>informelle Anrede &ndash; gesiezt</>),
  '{salutation!lieb_du}': (<>informelle Anrede &ndash; geduzt</>),
  '{salutation!kollege_geehrt}': (<>formelle Anrede &ndash; Kollege</>),
  '{salutation!kollege_lieb}': (<>informelle Anrede &ndash; Kollege</>),
}

export const TEMPLATE_REQUIRED_FIELDS = {
  '{salutation!lieb_du}': ['child_person.first_name'],
}

function renderTemplatePlaceholder(placeholder, {location, parent_person, child_person, type}) {
  switch (placeholder) {
    case '{salutation!geehrt}':
      switch (child_person?.type) {
        case "male":
          return `Sehr geehrter Herr ${child_person?.name || ''}`;
        case "female":
          return `Sehr geehrte Frau ${child_person?.name || ''}`;
        case "neutral":
          return `Guten Tag, ${child_person?.first_name || ''} ${child_person?.name || ''}`;
        case "company":
          return `Sehr geehrte Damen und Herren`;
        default:
          return `Sehr geehrte/r ${child_person?.first_name || ''} ${child_person?.name || ''}`;
      }
    case '{salutation!kollege_geehrt}':
      switch (child_person?.type) {
        case "male":
          return `Sehr geehrter Herr Kollege ${child_person?.name || ''}`;
        case "female":
          return `Sehr geehrte Frau Kollegin ${child_person?.name || ''}`;
        case "neutral":
          return `Sehr geehrt*r ${child_person?.first_name || ''} ${child_person?.name || ''}`;
        case "company":
          return `Sehr geehrte Damen und Herren Kolleginnen und Kollegen`;
        default:
          return `Sehr geehrte/r ${child_person?.first_name || ''} ${child_person?.name || ''}`;
      }
    case '{salutation!kollege_lieb}':
      switch (child_person?.type) {
        case "male":
          return `Lieber Herr Kollege ${child_person?.name || ''}`;
        case "female":
          return `Liebe Frau Kollegin ${child_person?.name || ''}`;
        case "neutral":
          return `Liebe*r ${child_person?.first_name || ''} ${child_person?.name || ''}`;
        case "company":
          return `Liebe Kollegen und Kolleginnen`;
        default:
          return `Liebe/r ${child_person?.first_name || ''} ${child_person?.name || ''}`;
      }
    case '{salutation!lieb_du}':
      switch (child_person?.type) {
        case "male":
          return `Lieber ${child_person?.first_name || ''}`;
        case "female":
          return `Liebe ${child_person?.first_name || ''}`;
        case "neutral":
          return `Liebe*r ${child_person?.first_name || ''}`;
        case "company":
          return `Liebe ${child_person?.first_name || ''}`;
        default:
          return `Liebe/r ${child_person?.first_name || ''}`;
      }
    case '{salutation!lieb_sie}':
      switch (child_person?.type) {
        case "male":
          return `Lieber Herr ${child_person?.name || ''}`;
        case "female":
          return `Liebe Frau ${child_person?.name || ''}`;
        case "neutral":
          return `Liebe*r ${child_person?.first_name || ''} ${child_person?.name || ''}`;
        case "company":
          return `Liebe ${child_person?.name || ''}`;
        default:
          return `Liebe/r ${child_person?.first_name || ''} ${child_person?.name || ''}`;
      }
    case '{location:address_line}':
      return location?.address_line;
    case '{location:zip}':
      return location?.zip;
    case '{location:city}':
      return location?.city;
    case '{location:notes}':
      return location?.notes;
    case '{child_person:gender}':
      return getAddressGender(child_person?.type);
    case '{child_person:name}':
      return child_person?.name;
    case '{child_person:first_name}':
      return child_person?.first_name;
    case '{child_person:title_prefix}':
      return child_person?.title_prefix;
    case '{child_person:title_suffix}':
      return child_person?.title_suffix;
    case '{parent_person:gender}':
      return getAddressGender(parent_person?.type);
    case '{parent_person:name}':
      return parent_person?.name;
    case '{parent_person:first_name}':
      return parent_person?.first_name;
    case '{parent_person:title_prefix}':
      return parent_person?.title_prefix;
    case '{parent_person:title_suffix}':
      return parent_person?.title_suffix;
    case '{relation_type}':
      return type;
    default:
      return placeholder;
  }
}

export const renderTemplate = (template, personLink) => {
  if (!template) {
    return template;
  }
  return template
    .replace(/(\s*\{[a-z!:._]+})/g, (match, token) => {
      const [_, leadingWhitespaces, placeholder] = token.match(/^(\s*)(.*)$/);
      const renderedPlaceholder = renderTemplatePlaceholder(placeholder, personLink);

      if (renderedPlaceholder) {
        return leadingWhitespaces + renderedPlaceholder;
      } else {
        return '';
      }
    })
    .replace(/^ +/gm, '')
    .replace(/ +$/gm, '')
    .trim();
}

export function useTemplateRendererGetter() {
  const getTemplate = useSelector(getAddressesTemplateGetter);

  return templateId => personLink => renderTemplate(getTemplate(templateId)?.template, personLink);
}

export function useTemplateRenderer({templateId}) {
  const {template} = useAddressesTemplate({id: templateId});

  return personLink => renderTemplate(template, personLink);
}

export function useRenderedPersonLinkData({personLinkId, salutation_template, address_template, parentPersonId, childPersonId, locationId, type}) {
  const {salutation_template: storedSalutationTemplate, address_template: storedAddressTemplate, parent_person: storedParentPersonId, child_person: storedChildPersonId, location: storedLocationId, type: storedType, ...personLinkData} = useAddressesPersonLink({id: personLinkId});

  salutation_template = salutation_template || storedSalutationTemplate;
  address_template = address_template || storedAddressTemplate;
  parentPersonId = parentPersonId || storedParentPersonId;
  childPersonId = childPersonId || storedChildPersonId;
  locationId = locationId || storedLocationId;
  type = type !== undefined ? type : storedType;

  const renderSalutation = useTemplateRenderer({templateId: salutation_template});
  const renderAddress = useTemplateRenderer({templateId: address_template});

  const parent_person = useAddressesPerson({id: parentPersonId});
  const child_person = useAddressesPerson({id: childPersonId});
  const location = useAddressesLocation({id: locationId});
  const personLink = {...personLinkData, parent_person, child_person, location, type};
  return {
    ...personLink,
    renderSalutation,
    renderAddress,
    renderedSalutation: renderSalutation(personLink),
    renderedAddress: renderAddress(personLink),
  }
}

export function usePersonLinkFieldSuggestions({databaseId, field}) {
  const {person_links} = useAddressesDatabase({id: databaseId});
  const getAddressesPersonLink = useSelector(getAddressesPersonLinkGetter);
  return [...new Set(person_links?.map(getAddressesPersonLink)?.map(person_link => person_link?.[field]))].filter(value => value).sort(ascendingComparator);
}

export function usePersonFieldSuggestions({databaseId, field}) {
  const {people} = useAddressesDatabase({id: databaseId});
  const getAddressesPerson = useSelector(getAddressesPersonGetter);
  return [...new Set(people?.map(getAddressesPerson)?.map(person => person?.[field]))].filter(value => value).sort(ascendingComparator);
}

export function useListFieldSuggestions({databaseId, field}) {
  const {lists} = useAddressesDatabase({id: databaseId});
  const getAddressesList = useSelector(getAddressesListGetter);
  return [...new Set(lists?.map(getAddressesList)?.map(list => list?.[field]))].filter(value => value).sort(ascendingComparator);
}
