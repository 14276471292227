import React from 'react';

import {forEachMatchingElement, uimanager} from 'ui-manager';
import DecryptedText from "../components/data/text/decrypted-text-redux";
import {preprocess} from "../features/e2ee/crypto-fragments";
import {store} from "../stores";
import {getOrCreateRoot} from "../utils/renderer-utils";
import {Providers} from "../ui/providers";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-js-security-encrypted-content]", node => {
    const encryptedContent = JSON.parse(node.dataset.jsSecurityEncryptedContent);
    const cryptoFragment = preprocess({encrypted_string: encryptedContent}, store.dispatch);

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <DecryptedText cryptoFragment={cryptoFragment}/>
      </Providers>
    );
  })
);
