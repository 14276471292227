import {createTheme} from '@mui/material/styles';

let theme = createTheme({

});

theme = createTheme(theme, {
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: '#428bca',
      }
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: '#f44336',
      }
    }),
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        backgroundColor: 'black',
      },
      arrow: {
        color: 'black',
      }
    },
  }
});

export {theme};
