import React, {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from 'lodash';
import {api, AuthenticationError} from "api/api";
// import {logout} from "features/session";
import {normalize} from "normalizr";
import {
  deleteDecryptedEntity,
  setDecryptedEntities,
  registerDecryptedEntityObserver,
  unregisterDecryptedEntityObserver,
} from "features/decryptedEntity/decrypted-entity-slice";
import {useCallback, useEffect, useState} from "react";
import {getVersion, setBackendVersion} from "features/session";
import {crypto_manager} from "../../components/mavo-crypto";
import {decryptSync, encrypt} from "../e2ee/crypto-fragments";

export function encryptDecryptedEntity(data, schema, keyId) {
  if (data === null || data === '') {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(v => encryptDecryptedEntity(v, schema[0], keyId));
  }

  if (typeof data === 'object') {
    return Object.assign(...Object.entries(data).map(([k, v]) => ({
      [k]: (schema?.unencryptedFields?.includes(k) && !Array.isArray(v) && typeof v !== 'object') ? v : encryptDecryptedEntity(v, schema?.schema?.[k], keyId)
    })));
  }

  return encrypt(data, keyId);
}

const wrapApiFunc = _.memoize(dispatch => _.memoize(version => _.memoize((schema) => _.memoize((apiFunc) => async (url, validatedData, entityOptions = {}, ...args) => {
  const {createEntities, keyId} = entityOptions;

  let encryptedValidatedData = undefined;
  if (validatedData) {
    encryptedValidatedData = encryptDecryptedEntity(validatedData, schema, keyId);
  }

  let response;
  try {
    response = await apiFunc(url, encryptedValidatedData, ...args);
  } catch (e) {
    if (e instanceof AuthenticationError) {
      // dispatch(logout());
      // TODO: Redirect to logged out page.
    }
    throw e;
  }

  const latestVersion = response?.headers?.['x-mavo-version'];
  if (latestVersion && (version === null || (version && version !== latestVersion))) {
    dispatch(setBackendVersion({version: latestVersion}));
  }

  let data = response.data;
  let normalizedData = undefined;

  const wrappedKeys = data?.wrappedKeys;
  delete data?.wrappedKeys;

  if (wrappedKeys) {
    crypto_manager.process_entity({wrapped_keys: wrappedKeys});
  }

  data = decryptSync(data);

  if (data?.next !== undefined && data?.previous !== undefined && data?.results !== undefined) {
    data = data.results;
  }

  if (schema) {
    normalizedData = normalize(data, schema);
    dispatch(setDecryptedEntities({...normalizedData, created: createEntities}));
  }

  return {
    response,
    data,
    normalizedData,
    wrappedKeys,
  };
}))));

export function getDecryptedEntityApi(schema, dispatch, version, keyId) {
  return Object.fromEntries(
    Object.entries(api).map(([name, apiFunc]) => {
      const wrappedApiFunc = wrapApiFunc(dispatch)(version)(schema)(apiFunc);

      if (!keyId) {
        return [name, wrappedApiFunc];
      } else {
        return [
          name,
          async (url, validatedData, entityOptions = {}, ...args) => {
            return await wrappedApiFunc(url, validatedData, {keyId, ...entityOptions}, ...args);
          },
        ];
      }
    }
  ));
}

export function useDecryptedEntityApi(schema) {
  const dispatch = useDispatch();
  const version = useSelector(getVersion);
  const keyId = useKeyId();

  return getDecryptedEntityApi(schema, dispatch, version, keyId);
}

export function useDecryptedEntityDeleter({entityType, baseUrl}) {
  const dispatch = useDispatch();
  const entityApi = useDecryptedEntityApi();

  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }

  const [deletingUuids, setDeletingUuids] = useState(new Set());
  const [deletedUuids, setDeletedUuids] = useState(new Set());
  const [deletionFailedUuids, setDeletionFailedUuids] = useState(new Set());

  const entityDeleter = useCallback(async (id) => {
    setDeletingUuids(new Set(deletingUuids).add(id));

    try {
      try {
        await entityApi.delete(`${baseUrl}${id}/`);
      } catch (e) {
        if (e.response?.status === 404) {
          // This is fine.
        } else {
          // noinspection ExceptionCaughtLocallyJS
          throw e;
        }
      }
      dispatch(deleteDecryptedEntity(entityType, id));
      setDeletedUuids(new Set(deletedUuids).add(id));
    } catch (e) {
      setDeletionFailedUuids(new Set(deletionFailedUuids).add(id));
      throw e;
    } finally {
      let newDeletingUuids = new Set(deletingUuids);
      newDeletingUuids.delete(id);
      setDeletingUuids(newDeletingUuids);
    }

    return true;
  }, [deletingUuids, setDeletingUuids, deletedUuids, setDeletedUuids, deletionFailedUuids, setDeletionFailedUuids, dispatch, entityType, baseUrl]);

  return {
    deletingUuids,
    deletedUuids,
    deletionFailedUuids,
    deleteDecryptedEntity: entityDeleter,
  };
}

export function useDecryptedEntityObserver(props) {
  const {type, id} = props || {};
  const dispatch = useDispatch();
  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(registerDecryptedEntityObserver({type, id}));
    return () => {
      dispatch(unregisterDecryptedEntityObserver({type, id}));
    };
  }, [dispatch, type, id]);
}

const KeyIdContext = React.createContext(undefined);

export const KeyIdProvider = ({
  children,
  keyId,
}) => {
  return (
    <KeyIdContext.Provider value={keyId}>
      {children}
    </KeyIdContext.Provider>
  );
};

export function useKeyId() {
  return useContext(KeyIdContext);
}
