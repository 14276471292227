import React from "react";
import Button from "@mui/material/Button";

export default ({view}) => (
  <Button
    onClick={view}
    variant="contained"
    color="primary"
    style={{display: 'inline'}}
  >
    Anzeigen
  </Button>
);
