import React from "react";
import {Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Popper} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Paper} from "@mui/material";

export function SplitButton({selectedOption, onSelectOption, options, children, disabled, choiceAriaLabel, menuListId="split-button-menu", ...props}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, type) => {
    onSelectOption(type);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup {...props} ref={anchorRef}>
        {children}
        <Button
          size="small"
          aria-controls={open ? menuListId : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select copy to clipboard strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={disabled}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id={menuListId} autoFocusItem>
                  {options.map(({id, name, disabled}, index) => (
                    <MenuItem
                      key={id || index}
                      selected={id === selectedOption}
                      disabled={disabled}
                      onClick={(event) => handleMenuItemClick(event, id)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
