import {PERSON_LINK_SCHEMA} from "../validation";
import React from "react";
import AddressEntityFormDialog from "./AddressEntityFormDialog";
import {LocationSelectorYupField} from "./LocationSelectorYupField";
import {PersonSelectorYupField} from "./PersonSelectorYupField";
import {TemplateChoiceYupField} from "./TemplateChoiceYupField";
import {Grid} from "@mui/material";
import {DEFAULT_PERSON_LINK_FORM_FIELDS, DefaultPersonLinkFormFields} from "./PersonLinkFormFields";
import {useFormContext} from "react-hook-form";

function PersonLinkFormDialogContent({databaseId, editPersonLink, onAddLocation, mostRecentlyAddedLocation}) {
  const {formState: {errors, isSubmitting}, watch, setValue} = useFormContext();

  const address_template = watch('address_template');
  const parentPersonId = watch('parent_person');

  const relation_type = watch('type');

  const childPersonId = watch('child_person');
  const hasChildPerson = editPersonLink !== -1 || childPersonId;

  return (
    <>
      {hasChildPerson ? (
        <>
          <DefaultPersonLinkFormFields databaseId={databaseId}>
            <Grid item xs={12}>
              <TemplateChoiceYupField
                name="salutation_template"
                databaseId={databaseId}
                type="salutation"
              />
            </Grid>
            <Grid item xs={12}>
              <LocationSelectorYupField
                name="location"
                contextPersonLinkId={editPersonLink}
                onAdd={onAddLocation}
                mostRecentlyAddedLocation={mostRecentlyAddedLocation}
                expandedSelectorBoxProps={{
                  sx: {mx: -3},
                }}
                address_template={address_template}
                parentPersonId={parentPersonId}
                childPersonId={childPersonId}
                type={relation_type}
                addressTemplateFieldName="address_template"
                databaseId={databaseId}
              />
            </Grid>
          </DefaultPersonLinkFormFields>
        </>
      ) : null}
    </>
  );
}

export function PersonLinkForm({
  databaseId,
  editPersonLink,
  onClose,
  onAddLocation,
  onAddPerson,
  mostRecentlyAddedLocation,
  mostRecentlyAddedPerson,
  ...props
}) {
  return (
    <AddressEntityFormDialog
      baseUrl="/api/addresses/person_links/"
      databaseId={databaseId}
      id='edit-person-link'
      entityId={editPersonLink}
      entityName="Kontakt"
      entityType="addresses_person_link"
      onClose={onClose}
      schema={PERSON_LINK_SCHEMA}
      renderDefaultChildren
      excludeDefaultChildren={[
        ...DEFAULT_PERSON_LINK_FORM_FIELDS,
        'location',
        'child_person',
        'salutation_template',
        'address_template',
      ]}
      allowDelete
      {...props}
    >
      <Grid container spacing={2}>
        {editPersonLink === -1 && (
          <Grid item xs={12}>
            <PersonSelectorYupField
              name="child_person"
              databaseId={databaseId}
              entityName="Person"
              onAdd={onAddPerson}
              mostRecentlyAddedPerson={mostRecentlyAddedPerson}
              expandedSelectorBoxProps={{
                sx: {mx: -3},
              }}
              allowInlineSelector
            />
          </Grid>
        )}
        <PersonLinkFormDialogContent
          databaseId={databaseId}
          editPersonLink={editPersonLink}
          mostRecentlyAddedLocation={mostRecentlyAddedLocation}
          mostRecentlyAddedPerson={mostRecentlyAddedPerson}
          onAddLocation={onAddLocation}
        />
      </Grid>
    </AddressEntityFormDialog>
  );
}
