import React from 'react';
import {useAddressesList} from "../../features/addresses/addresses-hooks";
import {DeletedCells, ViewableTableCell, ViewActionCell} from "./layout";
import {SortableAddressesEntitiesTable} from "./tables";
import {Chip} from "@mui/material";
import {NaturalTime} from "../data/time";

function ListsRowContent({id, onClick, hideActionColumn, showUpdatedColumn, selected}) {
  const {name, is_event, when, where, url, updated_at, isDeleted} = useAddressesList({id, observe: true});

  if (isDeleted) {
    return (
      <DeletedCells colSpan={2 - (hideActionColumn && 1) + (showUpdatedColumn && 1)}>
        Dieser Verteiler wurde gelöscht.
      </DeletedCells>
    );
  }

  return (
    <>
      <ViewableTableCell
        view={onClick}
        sx={selected && {
          background: '#ddd',
        }}
      >
        {name}
        {is_event && (
          <>
            {' '}
            <Chip label="Event" size="small"/>
            {(when || where || url) && (
              <>
                <br/>
                {when}
                {(when && where) && (<> &ndash; </>)}
                {where}
                {((when || where) && url) && (<> &ndash; </>)}
                {url}
              </>
            )}
          </>
        )}
      </ViewableTableCell>
      {showUpdatedColumn && (
        <ViewableTableCell view={onClick}>
          <NaturalTime date={updated_at}/>
        </ViewableTableCell>
      )}
      {!hideActionColumn && (
        <ViewActionCell view={onClick}/>
      )}
    </>
  );
}

export function ListsTable({ids, onSelectList, appliedFilters, hideActionColumn, showUpdatedColumn, selectedLists, showFooter, expandedEntityFields=[], ...props}) {
  const columns = [
    {field: 'name', name: "Name"},
  ];

  if (showUpdatedColumn) {
    columns.push({field: 'updated_at', name: "Letzte Änderung"});
  }

  return (
    <SortableAddressesEntitiesTable
      entityType="addresses_list"
      entityNameSingular="Verteiler"
      entityNamePlural="Verteiler"
      expandedEntityFields={expandedEntityFields}
      ids={ids}
      itemContent={(index, id) => (
        <ListsRowContent
          id={id}
          onClick={(evt) => onSelectList(id, evt)}
          hideActionColumn={hideActionColumn}
          showUpdatedColumn={showUpdatedColumn}
          selected={selectedLists?.includes(id)}
        />
      )}
      columns={columns}
      defaultSortField='name'
      defaultSortOrder='asc'
      appliedFilters={appliedFilters}
      hideActionColumn={hideActionColumn}
      showFooter={showFooter}
      additionalFooterText={selectedLists.length > 1 ? (
        <>
          {' '}({selectedLists.length} ausgewählt)
        </>
      ) : null}
      {...props}
    />
  );
}
