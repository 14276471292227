import React from 'react';
import Button from "@mui/material/Button";

export default ({isUploadComplete, isUploadIncomplete, isUploadInProgress, show}) => {
  const showButton = show ? (
    <div style={{position: 'absolute', right: 5, top: 5}}>
      <Button
        onClick={show}
        color="black"
      >
        Uploads anzeigen
      </Button>
    </div>
  ) : null;
  return (
    <>
      {isUploadComplete ? (
        <div className="modal-body alert-success success" style={{textAlign: 'center'}}>
          <h2>Upload vollständig!</h2>
          {showButton}
        </div>
      ) : isUploadIncomplete ? (
        <div className="modal-body alert-danger danger" style={{textAlign: 'center'}}>
          <h2>Upload unvollständig!</h2>
          {showButton}
        </div>
      ) : isUploadInProgress ? (
        <div className="modal-body alert-warning warning" style={{textAlign: 'center'}}>
          <h2>Upload läuft...</h2>
          {showButton}
        </div>
      ) : null}
    </>
  );
};
