import TableCell from "@mui/material/TableCell";
import React, {memo} from "react";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import {
  Add as AddIcon,
  Search as SearchIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import Toolbar from "@mui/material/Toolbar";
import {Box} from "@mui/material";
import Alert from "@mui/material/Alert";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import FancyFilterField from "../../packages/FancyFilterField";
import SimpleMoreMenu from "../../packages/SimpleMoreMenu";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";

export function TableHeaderCell({children, sx, ...props}) {
  return (
    <TableCell
      sx={{fontWeight: 'bold', ...sx}}
      {...props}
    >
      {children}
    </TableCell>
  );
}

export const HighlightableTableRow = memo(function HighlightableTableRow({children, ...props}) {
  return (
    <TableRow
      sx={{'&:hover': {background: '#fafafa'}}}
      {...props}
    >
      {children}
    </TableRow>
  );
});

export function ViewableTableCell({children, view, sx, ...props}) {
  return (
    <TableCell onClick={view} sx={{cursor: 'pointer', ...sx}} {...props}>
      {children}
    </TableCell>
  );
}

export function ViewActionCell({view, children, ...props}) {
  return (
    <ViewableTableCell align="right" sx={{py: 0, px: 1, mr: 1}} view={view}>
      {view && (
        <IconButton
          aria-label="edit"
          size="medium"
          onClick={view}
        >
          <SearchIcon/>
        </IconButton>
      )}
      {children}
    </ViewableTableCell>
  );
}

export function DeletedCells({children, ...props}) {
  return (
    <TableCell sx={{p: 0}} {...props}>
      <Alert variant="standard" color="error" severity="success">
        {children || "Dieser Eintrag wurde gelöscht."}
      </Alert>
    </TableCell>
  );
}

export function AddressTabPanel({searchField, searchFieldPlaceholder="Suchen...", options=[], onAdd, onEdit, onClose, onFilter, filterValue, children, sx, header, footer, menuItems, toolbarContent, ...props}) {
  const hasMenuAction = !!onEdit || (menuItems && menuItems?.length > 0);
  const hasAction = hasMenuAction || !!(onAdd || onClose) || toolbarContent;

  return (
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'column', ...sx}} {...props}>
      {header}
      <Toolbar
        sx={{
          pt: 0.5,
          pb: 0.5,
          background: '#fafafa',
          height: 64,
          flex: 0,
          ml: 0,
        }}
      >
        <Box sx={{
          flex: 1,
          ml: -1.5,
          pl: 0,
        }}>
          <FancyFilterField
            value={filterValue}
            onChange={onFilter && ((e, value) => onFilter(value))}
            options={options}
            fullWidth
            variant="outlined"
            placeholder={searchFieldPlaceholder}
            allowSearch
          />
        </Box>
        {hasAction ? (
          <Box sx={{
            flex: 0,
            display: 'flex',
            flexDirection: 'row',
            ml: 1,
            mr: -2,
          }}>
            {hasMenuAction && (
              <SimpleMoreMenu
                id={`addresses-menu`}
                size="large"
                title=""
                icon={<MenuIcon color="action"/>}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {onEdit && (
                  <MenuItem
                    onClick={onEdit}
                  >
                    <ListItemIcon>
                      <EditIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>
                      Bearbeiten...
                    </ListItemText>
                  </MenuItem>
                )}
                {menuItems}
              </SimpleMoreMenu>
            )}
            {toolbarContent}
            {onAdd && (
              <IconButton
                color="default"
                onClick={onAdd}
              >
                <AddIcon/>
              </IconButton>
            )}
            {onClose && (
              <IconButton
                color="default"
                onClick={onClose}
              >
                <CloseIcon/>
              </IconButton>
            )}
          </Box>
        ) : null}
      </Toolbar>
      <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
        {children}
      </Box>
      {footer}
    </Box>
  );
}

export const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Box} {...props} ref={ref}/>
  )),
  Table: (props) => (
    <Table {...props} sx={{borderCollapse: 'separate', tableLayout: 'fixed'}}/>
  ),
  TableHead,
  TableRow: HighlightableTableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref}/>),
};

export function SimpleRows({Component=Box, name, children, sx, ...props}) {
  if (!children) {
    return null;
  }

  return (
    <Component sx={{pt: 2, ...sx}} {...props}>
      {name && (
        <Typography variant="h6" gutterBottom>
          {name}
        </Typography>
      )}
      {children}
    </Component>
  );
}

export function SimpleRow({Component, name, value, ...props}) {
  if (!value) {
    return null;
  }

  const result = (
    <>
      <Typography variant="caption">
        {name}
      </Typography>
      <Typography gutterBottom {...(Component ? {} : props)}>
        {value}
      </Typography>
    </>
  );

  if (Component) {
    return (
      <Component {...props}>
        {result}
      </Component>
    );
  } else {
    return result;
  }
}
