import {FOREIGN_PERSON_LINK_SCHEMA} from "../validation";
import React from "react";
import AddressEntityFormDialog from "./AddressEntityFormDialog";
import {DEFAULT_PERSON_LINK_FORM_FIELDS, DefaultPersonLinkFormFields} from "./PersonLinkFormFields";
import {Grid} from "@mui/material";

export function ForeignPersonLinkForm({
  databaseId,
  editPersonLink,
  onClose,
  ...props
}) {
  return (
    <AddressEntityFormDialog
      baseUrl="/api/addresses/person_links/"
      databaseId={databaseId}
      id='edit-person-link'
      entityId={editPersonLink}
      entityName="Kontakt"
      entityType="addresses_person_link"
      onClose={onClose}
      schema={FOREIGN_PERSON_LINK_SCHEMA}
      renderDefaultChildren
      excludeDefaultChildren={[
        ...DEFAULT_PERSON_LINK_FORM_FIELDS,
      ]}
      allowDelete
      {...props}
    >
      <Grid container spacing={2}>
        <DefaultPersonLinkFormFields databaseId={databaseId}/>
      </Grid>
    </AddressEntityFormDialog>
  );
}
