import React, {useRef} from 'react';
import Button from '@mui/material/Button';
import {UserAvatarContainer} from "../../user/Avatar";
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {NaturalTime} from "../../data/time";
import ReactToPrint from "react-to-print";
import {Box, Tooltip} from "@mui/material";
import {Unshrinkable} from "../../layout/helpers/Unshrinkable";
import {DecryptedText} from "../../data/text";
import {Dialog} from "../../layout/modal/Dialog";
import {DialogTitle} from "../../layout/modal/DialogTitle";
import DocumentContent from "../document-content-redux";
import {DocumentError} from "../index";
import DescriptionIcon from '@mui/icons-material/Description';

export default props => {
  const {
    documentId,
    onClose, reply, onSave,
    documentUploaderId, documentError, documentContent,
    name, description, uploaded_at,
    open,
  } = props;

  const ref = useRef(null);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="text-document-dialog-title"
      ref={ref}
    >
      <DialogTitle disableTypography onClose={() => onClose()} color="primary">
        <Box sx={{overflow: 'hidden', mr: 6}}>
          <Typography variant="h5">
            <DescriptionIcon sx={{fontSize: '2.8em', float: 'left', m: -1, mr: 1}}/>
            <Box sx={{display: 'inline-block'}}>
              <DecryptedText text={name}/>
            </Box>
          </Typography>
          <Typography variant="subtitle1" sx={{whiteSpace: 'nowrap', float: 'left'}}>
            <Unshrinkable>
              hochgeladen von <span className="user-badge-outer-sm"><UserAvatarContainer userId={documentUploaderId}/></span>
              {uploaded_at ? (<NaturalTime date={uploaded_at} inSentence={true}/>) : null}
            </Unshrinkable>
          </Typography>
        </Box>
      </DialogTitle>

      {description ? (
        <DialogTitle disableTypography sx={{pt: 0, pb: 2, m: 0, mt: -1}}>
          <Typography variant="h6" data-document-element="description">
            <DecryptedText text={description}/>
          </Typography>
        </DialogTitle>
      ) : null}

      <DialogContent dividers sx={{m: 0, p: 2, minWidth: 400}}>
        {documentError ? (
          <DocumentError documentId={documentId}/>
        ) : (
          <DocumentContent documentId={documentId}/>
        )}
      </DialogContent>

      <DialogActions>
        <ReactToPrint
          trigger={() => (
            <Tooltip title={"Drucken"} arrow>
              <IconButton sx={{position: 'absolute', left: 8}} disabled={!documentContent}>
                <FontAwesomeIcon icon={['fas', 'print']}/>
              </IconButton>
            </Tooltip>
          )}
          content={() => ref.current}
        />
        {reply ? (
          <Button
            onClick={reply}
            color="primary"
            startIcon={<FontAwesomeIcon icon={['fas', 'reply']}/>}
            disabled={!documentContent}
          >
            Antworten
          </Button>
        ) : null}
        {onSave ? (
          <Button
            variant="contained"
            onClick={onSave}
            color="primary"
            startIcon={<FontAwesomeIcon icon={['fas', 'download']}/>}
          >
            Herunterladen
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
