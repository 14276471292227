/*
 * Mavo UI manager.
 *
 * Unifies instantiation of widgets for DOM elements across the static DOM and dynamically created elements.
 *
 * Usage:
 *
 * 1.) Instantiation of DOM elements
 *
 * Specify your instantiation code as shown below.
 *
 * import uimanager from 'uimanager';
 * uimanager.add(element => {
 *     // called once with `element` === `document` and then for each dynamically created DOM element
 *     // instantiate widget(s) or do anything else with the DOM element
 * });
 *
 * 2.) Dynamic creation of DOM elements.
 *
 * uimanager must be notified about newly created DOM elements, e.g., directly before they are attached to the DOM.
 *
 * import uimanager from 'uimanager';
 * uimanager.process(element);  // element is the new DOM element
 *
 */

import log from 'loglevel';
import $ from 'jquery';
import 'imports-loader?imports=default|jquery|jQuery!jquery-ui';

export const uimanager = (function ($) {

  'use strict';

  let PROCESS_EVENT_NAME = 'mavo:uimanager:process';

  $.widget("solesoftware.uimanager", {

    _create: function () {
      const $document = this.element;

      this.process($document.get(0));
    },

    process: function (node) {
      // A DOM element must not be processed more than once.
      if (node._processedByUiManager)
        return;
      node._processedByUiManager = true;

      // Trigger event to force processing.
      const $document = this.element;
      $document.trigger(PROCESS_EVENT_NAME, [node]);
    },

    add: function (callback) {
      const $document = this.element;

      let asyncCallback = element => setTimeout(
        () => callback(element),
        0
      );

      // Process callback asynchronously as soon as we receive a corresponding event, i.e., process() is called.
      $document.on(PROCESS_EVENT_NAME, (event, element) =>
        asyncCallback(element)
      );

      // Make sure that callback is processed on document node when added after first process() call.
      asyncCallback($document.get(0));
    }

  });

  return {
    process: element => {
      $(document).ready(function () {
        $(this).uimanager().uimanager('process', element);
      });
    },
    add: callback => {
      $(document).ready(function () {
        $(this).uimanager().uimanager('add', callback);
      });
    },
  };

})($);

export const forEachMatchingElement = (el, selector, callback) => {
  if (el.matches && el.matches(selector)) {
    try {
      callback(el);
    } catch (e) {
      log.error({msg: "failed to process callback for element", selector, callback, errorType: typeof e, error: e});
    }
  }

  el.querySelectorAll(selector).forEach(args => {
    try {
      return callback(args);
    } catch (e) {
      log.error({msg: "failed to process callback for element", selector, callback, errorType: typeof e, error: e});
    }
  });
};
