import {CREATE_EVENT_ENTRY_SCHEMA, CREATE_LIST_ENTRY_SCHEMA} from "../validation";
import React from "react";
import AddressEntityFormDialog from "./AddressEntityFormDialog";
import {useAddressesList} from "../../../features/addresses/addresses-hooks";
import {PersonLinkSelectorYupField} from "./PersonLinkSelectorYupField";

export function CreateListEntryForm({
  listId,
  listEntryId,
  onClose,
  ...props
}) {
  const {is_event: isEvent, database: databaseId} = useAddressesList({id: listId, observe: true});

  const schema = isEvent ? CREATE_EVENT_ENTRY_SCHEMA : CREATE_LIST_ENTRY_SCHEMA;

  return (
    <AddressEntityFormDialog
      baseUrl="/api/addresses/list_entries/"
      databaseId={databaseId}
      id='create-list-entry'
      entityId={listEntryId}
      title={isEvent ? "Event-Teilnehmer hinzufügen" : "Verteiler-Kontakt hinzufügen"}
      entityName={isEvent ? "Event-Teilnehmer" : "Verteiler-Kontakt"}
      entityType="addresses_list_entry"
      onClose={onClose}
      schema={schema}
      cancelCaption="Schließen"
      allowDelete
      renderDefaultChildren
      excludeDefaultChildren={['person_link']}
      initialData={{list: listId}}
      {...props}
    >
      <PersonLinkSelectorYupField name="person_link" databaseId={databaseId}/>
    </AddressEntityFormDialog>
  );
}
