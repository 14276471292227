import {EVENT_ENTRY_SCHEMA, LIST_ENTRY_SCHEMA} from "../validation";
import React from "react";
import AddressEntityFormDialog from "./AddressEntityFormDialog";
import {
  useAddressesList,
  useAddressesListEntry
} from "../../../features/addresses/addresses-hooks";
import {PersonLinkPanel} from "../PersonLinkPanel";

export function ListEntryForm({
  databaseId,
  listEntryId,
  onClose,
  onShowPerson,
  readOnly,
  ...props
}) {
  const {list, person_link} = useAddressesListEntry({id: listEntryId, observe: true});
  const {is_event} = useAddressesList({id: list, observe: true});

  const schema = is_event ? EVENT_ENTRY_SCHEMA : LIST_ENTRY_SCHEMA;

  return (
    <AddressEntityFormDialog
      baseUrl="/api/addresses/list_entries/"
      databaseId={databaseId}
      id='edit-list-entry'
      entityId={listEntryId}
      title={is_event ? "Event-Teilnehmer" : "Verteiler-Kontakt"}
      entityName={is_event ? "Event-Teilnehmer" : "Verteiler-Kontakt"}
      entityType="addresses_list_entry"
      onClose={onClose}
      schema={schema}
      cancelCaption="Schließen"
      hideSave={!is_event}
      allowDelete
      renderDefaultChildren={!readOnly}
      readOnly={readOnly}
      {...props}
    >
      <PersonLinkPanel
        id={person_link}
        onShowPerson={onShowPerson}
        showButtons
        topButtonsSx={{flex: 1}}
      />
    </AddressEntityFormDialog>
  );
}
