import {createAction, createSlice} from "@reduxjs/toolkit";

export const initialState = {};

const entitySlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setEntities(state, action) {
      const {entities, created} = action.payload;
      Object.entries(entities).forEach(([entityType, items]) => {
        // Merge in entities into potentially existing map.
        if (state[entityType] === undefined) {
          state[entityType] = {
            byId: {},
            createdIds: [],
            deletedIds: [],
          };
        }

        const entityState = state[entityType].byId;
        Object.entries(items).forEach(([key, value]) => {
          if (created) {
            state[entityType].createdIds.push(key);
          }

          if (entityState[key] === undefined || !value) {
            entityState[key] = value;
            if (value === null && !state[entityType].deletedIds.includes(key)) {
              state[entityType].deletedIds.push(key);
            }
            return;
          }

          if (!_.isEqual(entityState[key], value)) {
            entityState[key] = {
              ...entityState[key],
              ...value,
            };
          }
        });
      });
    },
  },
});

export const {
  setEntities,
} = entitySlice.actions;

export const fetchPage = createAction('entities/fetchPage');

const reducers = entitySlice.reducer;

export default reducers;
