import React from "react";
import {connect} from "react-redux";
import {viewDocument} from "../../features/entities/document";
import DocumentViewButton from "./document-view-button-view";

const mapDispatchToProps = (dispatch, {documentId}) => ({
  view: () => dispatch(viewDocument({documentId})),
});

export default connect(
  null,
  mapDispatchToProps,
)(DocumentViewButton);
