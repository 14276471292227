import React, {useEffect, useState} from "react";
import {saveAs} from "file-saver/dist/FileSaver";
import {SplitButton} from "../../packages/SplitButton";
import {LoadingButton} from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export function DownloadButton({buildBlob, options, defaultOption, ...props}) {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);

  const selectedOptionData = options?.filter(({id}) => id === selectedOption)[0];

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadResult, setDownloadResult] = useState(undefined);

  const performDownload = async () => {
    setIsDownloading(true);

    try {
      const blob = await buildBlob(selectedOption);
      saveAs(blob, blob.name);
      setDownloadResult(true);
    } catch (e) {
      setDownloadResult(false);
      alert("Die Daten konnten nicht exportiert werden.");
    }
    setIsDownloading(false);

    setTimeout(() => {
      setDownloadResult(undefined);
    }, 1000);
  };

  const loading = isDownloading || downloadResult !== undefined;

  const button = (
    <LoadingButton
      onClick={performDownload}
      loading={loading}
      loadingIndicator={isDownloading ? undefined : downloadResult ? <DoneIcon/> : <ReportProblemIcon/>}
      {...props}
    >
      {selectedOptionData?.caption}
    </LoadingButton>
  );

  return options?.length === 1 ? button : (
    <SplitButton
      options={options}
      selectedOption={selectedOption}
      onSelectOption={setSelectedOption}
      disabled={loading}
      menuListId="download-split-button-menu"
      aria-label="download split button"
      choiceAriaLabel="select download format"
    >
      {button}
    </SplitButton>
  );
}
