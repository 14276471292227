import {createSelector, createSlice} from "@reduxjs/toolkit";
import {VERSION} from "../../version";

const sessionSlice = createSlice({
  name: 'session',
  initialState: {},
  reducers: {
    setSession(state, action) {
      return action.payload;
    },
    setBackendVersion(state, action) {
      const {version} = action.payload;
      state.backendVersion = version;
    },
  },
});

export const {setSession, setBackendVersion} = sessionSlice.actions;

export default {
  session: sessionSlice.reducer,
};

export const getSession = state => state.session;

export const getSessionUser = createSelector(
  getSession,
  session => session.user,
);

export const getVersion = createSelector(
  getSession,
  (session) => session.backendVersion || VERSION,
);
