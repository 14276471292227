import React from "react";
import {
  useAddressesLocation,
} from "../../features/addresses/addresses-hooks";
import {Box} from "@mui/material";
import {DeletedCells, ViewableTableCell} from "./layout";
import {SortableAddressesEntitiesTable} from "./tables";


function LocationRowContent({id, onView, selected}) {
  const {address_line, zip, city, po_box, po_box_zip, notes, isDeleted} = useAddressesLocation({id, observe: true});

  if (isDeleted) {
    return (
      <DeletedCells colSpan="3">
        Diese Adresse wurde gelöscht.
      </DeletedCells>
    );
  }

  return (
    <>
      <ViewableTableCell
        view={onView}
        sx={selected && {
          background: '#ddd',
        }}
      >
        <Box>
          {address_line}
        </Box>
        <Box>
          <Box>
            {zip} {city}
          </Box>

          {(po_box || po_box_zip) && (
            (po_box && po_box_zip) ? (
              <Box>
                Postfach {po_box}, PLZ {po_box_zip}
              </Box>
            ) : po_box ? (
              <Box>
                Postfach {po_box}
              </Box>
            ) : null
          )}
        </Box>
      </ViewableTableCell>
    </>
  );
}

export function LocationsTable({ids, contextPersonId, onViewLocation, appliedFilters, selectedLocation, ...props}) {
  return (
    <SortableAddressesEntitiesTable
      entityType="addresses_location"
      entityNameSingular="Adresse"
      entityNamePlural="Adressen"
      ids={ids}
      itemContent={(index, id) => (
        <LocationRowContent
          id={id}
          onView={() => onViewLocation(id)}
          selected={id === selectedLocation}
        />
      )}
      columns={[
        {field: 'address', name: "Adresse"},
      ]}
      searchFields={[
        'address_line',
        'zip',
        'city',
      ]}
      sortFieldGetters={{
        'address': ({id, address_line, zip, city, ...props}) => `${address_line}, ${zip}, ${city}`,
      }}
      defaultSortField='address'
      defaultSortOrder='asc'
      appliedFilters={appliedFilters}
      hideActionColumn
      showFooter
      {...props}
    />
  );
}
