import {channel} from 'redux-saga';
import {actionChannel, all, call, delay, cancel, fork, put, race, select, spawn, take} from 'redux-saga/effects';
import {closeDatabase, getAddressesDatabaseGetter, openDatabase, updateDatabase} from "./addresses-slice";
import {endProgress, startProgress} from "../ui/progress";
import {getDecryptedEntityApi} from "../decryptedEntity/decrypted-entity-hooks";
import {ADDRESSES_DATABASE, COMPACT_ADDRESSES_DATABASE} from "../../api/api-schemas";
import {getVersion} from "../session";
import {getDecryptedEntitiesState, setDecryptedEntities} from "../decryptedEntity";
import {produce} from "immer";
import {getDocument, saveBlobOfDocument, saveDocument} from "../entities/document";
import {denormalize} from "normalizr";

function* doUpdateDatabase({id, documentId}) {
  const progress = {type: 'loadAddressDatabase', id};

  yield put(startProgress({...progress, total: 1}));

  const downloadProgress = {type: 'downloadDocument', id: documentId};
  yield put(startProgress({...downloadProgress, total: 1, blockUnload: true}));
  const decryptProgress = {type: 'decryptDocument', id: documentId};
  yield put(startProgress({...decryptProgress, total: 1, blockUnload: true}));

  let actions = [];

  function dispatch(action) {
    actions.push(action);
  }

  const version = yield select(getVersion);
  const entityApi = getDecryptedEntityApi(ADDRESSES_DATABASE, dispatch, version);
  try {
    yield call(entityApi.get, `/api/addresses/databases/${id}/`);
  } catch(error) {
    yield put(endProgress({...downloadProgress, error, errorStatus: error?.status}));
    yield put(endProgress({...decryptProgress, abort: true}));

    yield put(endProgress({...progress, error: error?.message || `${error}`}));
    return;
  }

  for (const action of actions) {
    yield put(action);
  }

  yield put(endProgress({...downloadProgress, success: true}));
  yield put(endProgress({...decryptProgress, success: true}));

  yield put(endProgress({...progress, progress: 1, success: true}));
}

function* serializeDatabase(database) {
  const decryptedEntities = yield select(getDecryptedEntitiesState);
  const denormalizedData = denormalize(database, COMPACT_ADDRESSES_DATABASE, {
    addresses_list: decryptedEntities?.addresses_list?.byId,
    addresses_list_entry: decryptedEntities?.addresses_list_entry?.byId,
    addresses_location: decryptedEntities?.addresses_location?.byId,
    addresses_person: decryptedEntities?.addresses_person?.byId,
    addresses_person_link: decryptedEntities?.addresses_person_link?.byId,
    addresses_template: decryptedEntities?.addresses_template?.byId,
  });

  const compactedData = produce(denormalizedData, draft => {
    delete draft.document;
    delete draft.id;
    delete draft.can_update;

    const deleteDatabaseRef = obj => {
      delete obj.database;
    };

    Object.values(draft).forEach(entities => Object.values(entities).forEach(deleteDatabaseRef));
    Object.values(draft?.lists).forEach(list => Object.values(list?.entries).forEach(deleteDatabaseRef));
    // Object.values(draft?.lists).forEach(list => Object.values(list?.entries).forEach(entry => {
    //   deleteDatabaseRef(entry);
    //   delete entry.list;
    // }));
  });

  return JSON.stringify(compactedData);
}

function* saveDatabase({id, documentId}, retryAction) {
  yield put(openDatabase({id}));

  let success = false;

  while (true) {
    const {type, payload} = yield take([endProgress]);

    if (type === endProgress.type && payload.type === 'loadAddressDatabase' && payload.id === id) {
      success = payload.success;
      break;
    }
  }

  if (success) {
    const database = (yield select(getAddressesDatabaseGetter))(id);
    const serializedDatabase = yield serializeDatabase(database);

    const blob = new Blob([serializedDatabase], {type: 'application/json'});
    yield saveBlobOfDocument(blob)(saveDocument({documentId}));
  }

  yield put(closeDatabase({id}));
}

const createDatabaseSaga = ({id, documentId}) => (function* (chan) {
  if (!documentId) {
    const databaseEntity = (yield select(getAddressesDatabaseGetter))(id);
    documentId = databaseEntity?.document;
  }

  if (!documentId) {
    throw Error(`missing document id of address database ${id}`);
  }

  let lastUpdateDatabaseTask;
  let observers = 0;
  let hasUpdated = false;

  while (true) {
    const {databaseAction} = yield race({
      databaseAction: take(chan),
    });

    if (databaseAction) {
      const {type, payload} = databaseAction;

      if (type === updateDatabase.type && !payload.error) {
        // Only the latest updateDatabase task should run.
        if (lastUpdateDatabaseTask) {
          yield cancel(lastUpdateDatabaseTask);
        }
        lastUpdateDatabaseTask = yield fork(doUpdateDatabase, {id, documentId});
      } else if (type === setDecryptedEntities.type) {
        const {entities, created} = payload;
        if (!created) {
          continue;
        }

        const databaseChangesById = {};
        Object.entries(entities).forEach(([entityType, items]) => {
          Object.entries(items).forEach(([key, value]) => {
            const {database} = value;

            if (!databaseChangesById[database]) {
              databaseChangesById[database] = {};
            }
            const databaseChanges = databaseChangesById[database];

            if (!databaseChanges[entityType]) {
              databaseChanges[entityType] = [];
            }
            databaseChanges[entityType].push(key);
          });
        });

        for (const [databaseId, changes] of Object.entries(databaseChangesById)) {
          if (databaseId !== id) {
            continue;
          }

          const databaseEntity = (yield select(getAddressesDatabaseGetter))(databaseId);

          const modifiedDatabaseEntity = produce(databaseEntity, draft => {
            Object.entries(changes).forEach(([key, value]) => {
              switch (key) {
                case "addresses_list":
                  draft.lists.push(...value);
                  break;
                case "addresses_person":
                  draft.people.push(...value);
                  break;
                case "addresses_person_link":
                  draft.person_links.push(...value);
                  break;
                case "addresses_location":
                  draft.locations.push(...value);
                  break;
                case "addresses_template":
                  draft.templates.push(...value);
                  break;
              }
            });
          });

          yield put(setDecryptedEntities({entities: {addresses_database: {[databaseId]: modifiedDatabaseEntity}}}));
        }
      } else if (type === saveDocument.type) {
        yield put(updateDatabase({id}));
        hasUpdated = true;
        yield fork(saveDatabase, {id, documentId});
      }

      if (type === openDatabase.type) {
        observers++;

        if (observers > 0 && !hasUpdated) {
          yield put(updateDatabase({id}));
          hasUpdated = true;
        }
      } else if (type === closeDatabase.type) {
        observers--;

        if (observers === 0) {
          // Cleanup.
        }
      }
    }
  }
});

function* databasesManager() {
  const databasesActionsChan = yield actionChannel([openDatabase, closeDatabase, updateDatabase, setDecryptedEntities, saveDocument]);

  const databaseSagas = {};
  while (true) {
    const action = yield take(databasesActionsChan);

    let id = undefined;
    let documentId = undefined;

    if (action.type === setDecryptedEntities.type) {
      // Propagate to all existing sagas.
      for (const databaseSaga of Object.values(databaseSagas)) {
        yield put(databaseSaga.chan, action);
      }
      continue;
    } else if (action.type === saveDocument.type) {
      documentId = action.payload.documentId;
      const {has_file, related_object} = (yield select(getDocument(documentId)));

      if (has_file || related_object?.type !== 'addressdatabase') {
        continue;
      }

      id = related_object?.id;
    } else {
      id = action.payload?.id;
      documentId = action.payload?.documentId;
    }

    let databaseSaga = databaseSagas[id];

    // Start database-specific saga if necessary.
    if (!databaseSaga) {
      const chan = yield call(channel);
      databaseSagas[id] = databaseSaga = {
        chan,
        task: yield fork(createDatabaseSaga({id, documentId}), chan),
      };
    }

    // Forward action to listing's individual saga.
    yield put(databaseSaga.chan, action);
  }
}

export default function* addressesSaga() {
  yield spawn(databasesManager);
  yield all([]);
}
