import React from "react";
import {connect} from "react-redux";
import CaseUploadSummary from "./case-upload-summary-view";
import {getDraftDocumentIdsForCase, isUploadSuccessful} from "../../features/entities/document";
import {getProgress} from "../../features/ui/progress";
import {getListing, updateListing} from "../../features/ui/listing";

export default connect(
  (state, {caseId, listingId}) => {
    const isUploadComplete = isUploadSuccessful(caseId)(state);

    const pendingDocumentIds = getDraftDocumentIdsForCase(caseId)(state);
    let isUploadInProgress = false, isUploadIncomplete = false;
    if (pendingDocumentIds.length > 0) {
      for (const pendingDocumentId of pendingDocumentIds) {
        const uploadProgress = getProgress('uploadDocument', pendingDocumentId)(state),
          encryptProgress = getProgress('encryptDocument', pendingDocumentId)(state);
        if (uploadProgress.error || uploadProgress.abort || encryptProgress.error || encryptProgress.abort) {
          isUploadIncomplete = true;
        }
      }
      isUploadInProgress = !isUploadIncomplete;
    }

    const {currentPage} = getListing(state)(listingId);

    return {
      isUploadComplete,
      isUploadInProgress,
      isUploadIncomplete,
      uploadsVisible: !(currentPage > 1),
    };
  },
  (dispatch, {listingId}) => {
    return {
      show: () => dispatch(updateListing({id: listingId, currentPage: 1})),
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    const {uploadsVisible, ...otherStateProps} = stateProps;
    const {show, ...otherDispatchProps} = dispatchProps;
    return {
      ...ownProps,
      ...otherStateProps,
      ...otherDispatchProps,
      show: uploadsVisible ? undefined : show,
    };
  },
)(CaseUploadSummary);
