import {Box} from "@mui/material";
import {TableVirtuoso} from "react-virtuoso";
import {TableComponents, TableHeaderCell} from "./layout";
import TableRow from "@mui/material/TableRow";
import React, {useEffect, useState} from "react";
import {useAddressesEntities, useEntitiesSearcher, useEntitiesSorter} from "../../features/addresses/addresses-hooks";
import {TableSortLabel} from "@mui/material";
import {visuallyHidden} from '@mui/utils';
import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";

function NonTableVirtuoso({data, components, fixedHeaderContent, itemContent, ...props}) {
  const Table = components.Table;
  const TableHead = components.TableHead;
  const TableBody = components.TableBody;
  const TableRow = components.TableRow;

  return (
    <Box sx={{height: '100%', outline: 'none', overflowY: 'auto', position: 'relative'}}>
      <Box sx={{width: '100%', height: '100%', position: 'absolute', top: 0}}>
        <Table {...props}>
          <TableHead>
            {fixedHeaderContent()}
          </TableHead>
          <TableBody>
            {data?.map((id, idx) => (
              <TableRow key={id || idx}>
                {itemContent(idx, id)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

export function SortableTable({entities, itemContent, columns, defaultSortField, defaultSortOrder='asc', appliedFilters, searchFields, sortFieldGetters, searchFieldGetters, sx, hideActionColumn, entityNameSingular, entityNamePlural, showFooter, additionalFooterText, onChangeSortOrder, nonVirtual, onChangeIdsList, options, ...props}) {
  // Apply sorting.
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(defaultSortOrder);
  const sortedEntities = useEntitiesSorter({entities, field: sortField, order: sortOrder, fieldGetters: sortFieldGetters});

  // Apply filtering.
  let filteredSortedEntities = sortedEntities?.filter(entity => {
    for (const filter of appliedFilters) {
      if (filter?.id === 'q') {
        continue;
      }

      const field = filter?.id;
      const choice = filter?.choice;

      const option = options?.filter(({id}) => id === filter?.id)[0];

      const filterFunction = option?.filterFunction;
      if (filterFunction) {
        if (!filterFunction({field, choice, entity})) {
          return false;
        }
      } else {
        const currentValue = _.get(entity, field);
        if (_.isArray(currentValue)) {
          if (!currentValue.includes(choice)) {
            return false;
          }
        } else if (currentValue !== choice) {
          return false;
        }
      }
    }

    return true;
  });

  // Consider search terms.
  if (!searchFields) {
    searchFields = columns?.map(({field}) => field);
  }
  const searchTerm = appliedFilters?.filter(({id}) => id === 'q')?.[0]?.choice || "";
  const filteredSortedIds = useEntitiesSearcher({entities: filteredSortedEntities, fields: searchFields, fieldGetters: searchFieldGetters, searchTerm})

  useEffect(() => {
    if (onChangeIdsList) {
      onChangeIdsList(filteredSortedIds);
    }
  }, [filteredSortedIds?.join(',')]);

  const otherSortOrder = defaultSortOrder === 'asc' ? 'desc' : 'asc';

  const columnClick = (field) => {
    if (field === sortField) {
      if (sortOrder === defaultSortOrder) {
        setSortOrder(otherSortOrder);
      } else if (sortOrder === otherSortOrder) {
        setSortField(null);
      }
    } else {
      setSortField(field);
      setSortOrder(defaultSortOrder);
    }
  };

  useEffect(() => {
    if (onChangeSortOrder) {
      onChangeSortOrder({field: sortField, order: sortOrder});
    }
  }, [sortField, sortOrder]);

  const TableRenderer = nonVirtual ? NonTableVirtuoso : TableVirtuoso;

  return (
    <>
      <Box sx={{flex: 1, width: '100%', ...sx}}>
        <TableRenderer
          data={filteredSortedIds}
          components={TableComponents}
          fixedHeaderContent={() => (
            <TableRow sx={{background: 'white'}}>
              {columns?.map(({field, name, sx}) => (
                <TableHeaderCell
                  key={field}
                  sx={sx}
                >
                  <TableSortLabel
                    active={sortField === field}
                    direction={sortField === field ? sortOrder : 'asc'}
                    onClick={() => columnClick(field)}
                  >
                    {name}
                    {sortField === field ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sortOrder === 'desc' ? 'absteigend sortiert' : 'aufsteigend sortiert'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableHeaderCell>
              ))}
              {!hideActionColumn && (
                <TableHeaderCell sx={{width: 64}}></TableHeaderCell>
              )}
            </TableRow>
          )}
          itemContent={(index, id) => itemContent(index, id, filteredSortedIds?.[index - 1], filteredSortedIds?.[index + 1])}
          {...props}
        />
      </Box>
      {showFooter && (
        <Box sx={{flex: 0, display: 'flex', flexDirection: 'column', background: '#fafafa', color: '#777', minHeight: '2.5rem', py: 1, px: 2}}>
          <Typography>
            {appliedFilters?.length > 0 ? (
              <>
                {filteredSortedIds?.length || 0} Suchtreffer
              </>
            ) : (
              <>
                {filteredSortedIds?.length || 0} {filteredSortedIds?.length === 1 ? (entityNameSingular || "Eintrag") : (entityNamePlural || "Einträge")}
                {additionalFooterText}
              </>
            )}
          </Typography>
        </Box>
      )}
    </>
  );
}

export function SortableAddressesEntitiesTable({entityType, expandedEntityFields, ids, ...props}) {
  const entities = useAddressesEntities({type: entityType, expandedFields: expandedEntityFields, ids});

  return (
    <SortableTable
      entities={entities}
      {...props}
    />
  );
}
