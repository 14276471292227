import React, {useRef} from 'react';
import {withStyles} from '@mui/styles';
import DialogContent from '@mui/material/DialogContent';
import {Dialog} from "../../layout/modal/Dialog";
import {DialogTitle} from "../../layout/modal/DialogTitle";
import {getView} from "../../../features/ui/view";
import {useSelector, useDispatch} from 'react-redux';
import {hideDocument} from "../../../features/entities/document";
import Typography from '@mui/material/Typography';

const styles = theme => ({
  title: {
    color: theme.palette.primary.main,
  },
  content: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: 400,
  },
});

export default withStyles(styles)(props => {
  const {
    id,
    classes,
  } = props;

  const open = useSelector(getView('viewDocument', id)).visible;

  const dispatch = useDispatch();
  const onClose = () => dispatch(hideDocument({documentId: id}));

  const ref = useRef(null);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="unsupported-document-dialog-title"
      ref={ref}
    >
      <DialogTitle disableTypography onClose={() => onClose()} className={classes.title}>
        <Typography variant="h5">
          Nicht unterstütztes Dokument
        </Typography>
      </DialogTitle>

      <DialogContent dividers className={classes.content}>
        <Typography variant="body1">
          Die Anzeige dieses Dokumenttyps wird gegenwärtig nicht unterstützt.
        </Typography>
      </DialogContent>
    </Dialog>
  );
});
