import * as yup from "yup";
import yupLocaleDe from 'packages/yup-locale-de';

yup.setLocale(yupLocaleDe);

export const LIST_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  name: yup.string()
    .label("Name")
    .trim()
    .max(255)
    .nullable(),
});

export const EVENT_SCHEMA = yup.object().shape({
  ...LIST_SCHEMA.fields,
  when: yup.string()
    .label("Wann?")
    .trim()
    .max(255)
    .nullable(),
  where: yup.string()
    .label("Wo?")
    .meta({
      suggestions: true,
    })
    .trim()
    .max(255)
    .nullable(),
  url: yup.string()
    .label("URL")
    .trim()
    .max(255)
    .nullable(),
});

export const PERSON_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  type: yup.string()
    .label("Typ")
    .meta({
      options: {
        male: "Person, männlich",
        female: "Person, weiblich",
        neutral: "Person, neutral",
        company: "Firma",
        '': "-",
      },
    })
    .transform((value, originalValue) => (
      originalValue === 'null' ? (
        undefined
      ) : !originalValue ? (
        null
      ) : originalValue
    ))
    .nullable(),
  name: yup.string()
    .label("Name")
    .trim()
    .max(255)
    .nullable(),
  first_name: yup.string()
    .label("Vorname")
    .trim()
    .max(255)
    .nullable(),
  title_prefix: yup.string()
    .label("Titel (Präfix)")
    .meta({
      suggestions: true,
    })
    .trim()
    .max(255)
    .nullable(),
  title_suffix: yup.string()
    .label("Titel (Suffix)")
    .meta({
      suggestions: true,
    })
    .trim()
    .max(255)
    .nullable(),
  birthday: yup.string()
    .label("Geburtstag")
    .trim()
    .max(255)
    .nullable(),
  iban: yup.string()
    .label("IBAN")
    .trim()
    .max(255)
    .nullable(),
  bic: yup.string()
    .label("BIC")
    .trim()
    .max(255)
    .nullable(),
  vat_id: yup.string()
    .label("Umsatzsteuer-ID")
    .trim()
    .max(255)
    .nullable(),
  tax_id: yup.string()
    .label("Steuernummer")
    .trim()
    .max(255)
    .nullable(),
  web: yup.string()
    .label("Webseite")
    .trim()
    .max(255)
    .nullable(),
  mavo_username: yup.string()
    .label("MavoBIZ-Benutzername")
    .trim()
    .max(255)
    .nullable(),
  notes: yup.string()
    .label("Notizen")
    .trim()
    .max(65535)
    .meta({
      rows: 5,
    })
    .nullable(),
});

export const LOCATION_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  address_line: yup.string()
    .label("Straße / Hausnummer")
    .trim()
    .max(255)
    .nullable(),
  zip: yup.string()
    .label("PLZ")
    .trim()
    .max(255)
    .nullable(),
  city: yup.string()
    .label("Ort")
    .trim()
    .max(255)
    .nullable(),
  po_box: yup.string()
    .label("Postfach")
    .trim()
    .max(255)
    .nullable(),
  po_box_zip: yup.string()
    .label("Postfach-PLZ")
    .trim()
    .max(255)
    .nullable(),
  notes: yup.string()
    .label("Notiz")
    .trim()
    .max(65535)
    .nullable(),
});

export const FOREIGN_PERSON_LINK_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  type: yup.string()
    .label("Funktion")
    .meta({
      suggestions: true,
    })
    .trim()
    .max(255)
    .nullable(),
  phone: yup.string()
    .label("Telefon")
    .trim()
    .max(255)
    .nullable(),
  phone2: yup.string()
    .label("Telefon 2")
    .trim()
    .max(255)
    .nullable(),
  mobile: yup.string()
    .label("Mobil")
    .trim()
    .max(255)
    .nullable(),
  fax: yup.string()
    .label("Fax")
    .trim()
    .max(255)
    .nullable(),
  email: yup.string()
    .label("E-Mail")
    .email()
    .trim()
    .max(255)
    .nullable(),
});

export const OWN_ADDRESS_PERSON_LINK_SCHEMA = yup.object().shape({
  ...FOREIGN_PERSON_LINK_SCHEMA.fields,
  type: yup.string()
    .label("Art")
    .meta({
      suggestions: true,
    })
    .trim()
    .max(255)
    .nullable(),
  location: LOCATION_SCHEMA,
  salutation_template: yup.string()
    .label("Anrede")
    .trim()
    .max(255)
    .meta({options: []}),
  address_template: yup.string()
    .label("Anschriftformat")
    .trim()
    .max(255)
    .meta({options: []}),
});

export const PERSON_LINK_SCHEMA = yup.object().shape({
  ...OWN_ADDRESS_PERSON_LINK_SCHEMA.fields,
  type: yup.string()
    .label("Funktion")
    .meta({
      suggestions: true,
    })
    .trim()
    .max(255)
    .nullable(),
  location: yup.string()
    .label("Adresse")
    .trim()
    .max(255)
    .nullable(),
  child_person: yup.string()
    .label("Person")
    .trim()
    .max(255)
    .nullable(),
});

export const LIST_ENTRY_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
});

export const EVENT_ENTRY_SCHEMA = yup.object().shape({
  ...LIST_ENTRY_SCHEMA.fields,
  confirmed: yup.boolean()
    .label("Zusage"),
});

export const CREATE_LIST_ENTRY_SCHEMA = yup.object().shape({
  ...LIST_ENTRY_SCHEMA.fields,
  person_link: yup.string().nullable(),
});

export const CREATE_EVENT_ENTRY_SCHEMA = yup.object().shape({
  ...EVENT_ENTRY_SCHEMA.fields,
  person_link: yup.string().nullable(),
});

export const TEMPLATE_SCHEMA = yup.object().shape({
  id: yup.string().nullable(),
  type: yup.string()
    .label("Typ")
    .meta({
      options: {
        address: "Adresse",
        salutation: "Anrede",
      },
    })
    .required(),
  template: yup.string()
    .label("Vorlage")
    .trim()
    .max(65535)
    .meta({
      rows: 5,
      helperText: "Verfügbare Platzhalter: {relation_type}, {child_person:gender}, {child_person:title_prefix}, {child_person:title_suffix}, {child_person:first_name}, {child_person:name}, {parent_person:gender}, {parent_person:title_prefix}, {parent_person:title_suffix}, {parent_person:first_name}, {parent_person:name}, {location:address_line}, {location:zip}, {location: city}, {salutation!geehrt}, {salutation!lieb_sie}, {salutation!lieb_du}",
    })
    .required(),
  is_global: yup.boolean()
    .label("Vorlage in Auswahllisten anzeigen"),
});
