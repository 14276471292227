import React from 'react';
import {useAddressesPerson} from "../../features/addresses/addresses-hooks";
import {DeletedCells, ViewableTableCell, ViewActionCell} from "./layout";
import {SortableAddressesEntitiesTable} from "./tables";
import {AlphabeticalPersonName} from "./person";
import {NaturalTime} from "../data/time";


function PersonRowContent({id, onView, selected, hideActionColumn}) {
  const {name, first_name, updated_at, isDeleted} = useAddressesPerson({id, observe: true});

  if (isDeleted) {
    return (
      <DeletedCells colSpan="3">
        Diese Person wurde gelöscht.
      </DeletedCells>
    );
  }

  return (
    <>
      <ViewableTableCell
        view={onView}
        sx={selected && {
          background: '#ddd',
        }}
      >
        <AlphabeticalPersonName id={id}/>
      </ViewableTableCell>
      <ViewableTableCell
        view={onView}
        sx={selected && {
          background: '#ddd',
        }}
      >
        <NaturalTime date={updated_at}/>
      </ViewableTableCell>
      {!hideActionColumn && (
        <ViewActionCell view={onView}/>
      )}
    </>
  );
}

export function PeopleTable({ids, onViewPerson, appliedFilters, selectedPerson, hideActionColumn, showFooter=true, ...props}) {
  return (
    <SortableAddressesEntitiesTable
      entityType="addresses_person"
      entityNameSingular="Person"
      entityNamePlural="Personen"
      ids={ids}
      itemContent={(index, id) => (
        <PersonRowContent
          id={id}
          onView={() => onViewPerson(id)}
          selected={id === selectedPerson}
          hideActionColumn={hideActionColumn}
        />
      )}
      columns={[
        {field: 'name', name: "Name"},
        {field: 'updated_at', name: "Letzte Änderung"},
      ]}
      searchFields={['name', 'first_name', 'title_prefix', 'title_suffix']}
      sortFieldGetters={{
        'name': ({name, first_name, title_prefix, title_suffix}) => `${name}, ${first_name}, ${title_prefix}, ${title_suffix}`,
      }}
      defaultSortField='name'
      defaultSortOrder='asc'
      appliedFilters={appliedFilters}
      showFooter={showFooter}
      hideActionColumn={hideActionColumn}
      {...props}
    />
  );
}
