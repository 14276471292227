import {all} from 'redux-saga/effects';
import {saga as addressesSaga} from './addresses';
import {saga as extrasSaga} from './extras';
import {saga as e2eeSaga} from './e2ee';
import {saga as entitiesSaga} from './entities';
import {saga as decryptedEntitySaga} from './decryptedEntity';
import {saga as telemetrySaga} from './telemetry';
import {saga as uiSaga} from './ui';

export default function* rootSaga() {
  yield all([
    telemetrySaga(),  // Use as first saga.
    addressesSaga(),
    e2eeSaga(),
    entitiesSaga(),
    decryptedEntitySaga(),
    extrasSaga(),
    uiSaga(),
  ]);
}
