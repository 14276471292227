import React from 'react';

import {forEachMatchingElement, uimanager} from 'ui-manager';
import {UserAvatarContainer} from "../components/user/Avatar";
import {getOrCreateRoot} from "../utils/renderer-utils";
import {Providers} from "../ui/providers";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-user-avatar]", node => {
    const data = JSON.parse(node.dataset.userAvatar);

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <UserAvatarContainer {...data}/>
      </Providers>
    );
  })
);
