import React from 'react';

import {connect} from "react-redux";
import {
  getAllDraftDocumentIds,
  getDocumentIdsForListing,
  getDraftDocumentIdsForCase,
  getRecentlyUploadedIdsByCaseId,
  getSelectedDocumentIdsForListing,
  getVisibleSelectedDocumentIdsForListing
} from "../../features/entities/document";
import {
  bulkListingAction,
  bulkSelect,
  bulkUnselect,
  getListing,
  toggleResult,
  unselectAll,
  updateListing
} from "../../features/ui/listing";
import {canUpload, isCaseEmpty} from "../../features/entities/case";
import {hideUploadBox, isUploadBoxVisibleForCase, showUploadBox} from "../../features/ui/dropzone";
import {isWaitingForDrop} from "../../features/ui/drag-and-drop";
import {createDocumentDraftsFromFiles} from "../../events/document";
import DocumentsTable from "./documents-table-view";
import {getProgress} from "../../features/ui/progress";

export default connect(
  (state, {listingId, ...props}) => {
    const {currentPage, ordering, count, meta, error} = getListing(state)(listingId);
    const caseId = {...meta}.caseId || props.caseId;

    const userCanUpload = canUpload(caseId)(state);
    const isPermanentlyVisible = isUploadBoxVisibleForCase(caseId)(state);
    const isTemporarilyVisible = isWaitingForDrop(state);
    const isVisible = isPermanentlyVisible || isTemporarilyVisible;

    let draftDocumentIds = [];
    if (!(currentPage > 1)) {
      draftDocumentIds = caseId !== undefined ? getDraftDocumentIdsForCase(caseId)(state) : getAllDraftDocumentIds(state);
    }
    const recentlyUploadedIds = !(currentPage > 1) ? getRecentlyUploadedIdsByCaseId(caseId)(state) : [];
    const documentIds = listingId ? getDocumentIdsForListing(listingId)(state) : [];

    const selectedIds = getSelectedDocumentIdsForListing(listingId)(state);
    const visibleSelectedIds = getVisibleSelectedDocumentIdsForListing(listingId)(state);

    const visibleSelectedIdsCount = (
      visibleSelectedIds.length +
      [...draftDocumentIds, ...recentlyUploadedIds].filter(id => selectedIds.includes(id)).length
    );

    const allVisibleDocumentsSelected = visibleSelectedIds.length === documentIds.length;
    const allDraftsSelected = !draftDocumentIds.some(id => !selectedIds.includes(id));
    const allRecentlyUploadedIdsSelected = !recentlyUploadedIds.some(id => !selectedIds.includes(id));

    const anyDraftSelected = draftDocumentIds.some(id => selectedIds.includes(id));
    const anyRecentlyUploadedIdSelected = recentlyUploadedIds.some(id => selectedIds.includes(id));

    const isEverythingSelected = (selectedIds.length === count + recentlyUploadedIds.length);
    const isEverythingVisibleSelected = allVisibleDocumentsSelected && allDraftsSelected && allRecentlyUploadedIdsSelected;
    const isAnythingVisibleSelected = visibleSelectedIds.length > 0 || anyDraftSelected || anyRecentlyUploadedIdSelected;

    const bulkProgress = getProgress(bulkListingAction.type, listingId)(state);

    const bulkActionActiveOrRecent = bulkProgress.inProgress || bulkProgress.recent;
    const bulkSuccessful = bulkProgress.success;
    const bulkError = bulkProgress.error;
    const bulkProgressPercentage = bulkProgress.progress / bulkProgress.total;
    const bulkProgressSuccessful = bulkProgress.successful;
    const bulkProgressTotal = bulkProgress.total;
    const bulkActionProgressMessageTemplate = bulkProgress.progressMessageTemplate;
    const bulkActionTotalFailureMessageTemplate = bulkProgress.totalFailureMessageTemplate;

    const isEverythingDisplayed = (count === undefined || documentIds.length === count);

    return {
      ...meta,
      draftDocumentIds,
      recentlyUploadedIds,
      documentIds,
      caseIsEmpty: isCaseEmpty(caseId)(state),
      canUpload: canUpload(caseId)(state),
      dropzoneIsVisible: isVisible && userCanUpload,
      dropzoneIsPermanentlyVisible: isPermanentlyVisible && userCanUpload,
      ordering: ordering || [],
      currentPage,
      selectedIds,
      visibleSelectedIdsCount,
      isEverythingSelected,
      isEverythingVisibleSelected,
      isAnythingVisibleSelected,
      isEverythingDisplayed,
      bulkActionActiveOrRecent,
      bulkSuccessful,
      bulkError,
      bulkProgressPercentage,
      bulkProgressSuccessful,
      bulkProgressTotal,
      bulkActionProgressMessageTemplate,
      bulkActionTotalFailureMessageTemplate,
      loadingFailed: error,
    };
  },
  (dispatch, {caseId, listingId, disableOrdering}) => {
    const uploadFiles = files => {
      createDocumentDraftsFromFiles({files, caseId, startUpload: true}, dispatch).then(() => {
        dispatch(updateListing({id: listingId, currentPage: 1}));
        dispatch(hideUploadBox({caseId}));
      });
    };

    const changeOrdering = disableOrdering ? undefined : (ordering, column) => {
      const newOrdering = [];
      if (ordering.includes(column)) {
        newOrdering.push(`-${column}`);
      } else {
        newOrdering.push(column);
      }
      dispatch(updateListing({id: listingId, ordering: newOrdering, keepCount: true}));
    };

    const createSelectAllAction = ids => () => dispatch(bulkSelect({listingId, ids}));
    const createUnselectAllAction = ids => () => dispatch(bulkUnselect({listingId, ids}));

    return {
      showDropzone: () => dispatch(showUploadBox({caseId})),
      hideDropzone: () => dispatch(hideUploadBox({caseId})),

      uploadFiles,
      changeOrdering,

      update: () => dispatch(updateListing({id: listingId})),

      unselectAll: () => dispatch(unselectAll({listingId})),
      toggleItem: id => dispatch(toggleResult({listingId, id})),

      createSelectAllAction,
      createUnselectAllAction,
    };
  },
  (stateProps, dispatchProps, ownProps) => {
    const {documentIds, draftDocumentIds, recentlyUploadedIds} = stateProps;
    const {createSelectAllAction, createUnselectAllAction} = dispatchProps;
    return {
      ...stateProps,
      ...dispatchProps,
      selectPage: createSelectAllAction([...(draftDocumentIds || []), ...(documentIds || []), ...(recentlyUploadedIds || [])]),
      unselectPage: createUnselectAllAction([...(draftDocumentIds || []), ...(documentIds || []), ...(recentlyUploadedIds || [])]),
      ...ownProps,
    };
  }
)(DocumentsTable);
