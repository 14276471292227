import combineSlices from "../../packages/combineSlices";
import entitiesReducer, {initialState} from "./entities-slice";
import caseReducer from "./case";
import documentReducer from "./document";
import mailReducer from "./mail";
import userReducer from "./user";

const reducers = {
  entities: combineSlices(entitiesReducer, initialState, {
    ...caseReducer,
    ...documentReducer,
    ...mailReducer,
    ...userReducer,
  }),
};

export default reducers;
