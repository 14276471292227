import entityReducers from './decrypted-entity-slice';

const reducers = {
  ...entityReducers,
};
export default reducers;

export {default as saga} from './decrypted-entity-sagas';

export * from './decrypted-entity-slice';
