import {createRoot} from "react-dom/client";

export function getOrCreateRoot(node) {
  let root = node.__createdReactRootNode;
  if (!root) {
    root = createRoot(node);
    node.__createdReactRootNode = root;
  }
  return root;
}
