import React from 'react';
import {useSelector} from "react-redux";
import {Pagination} from "../paginator";
import {getDocumentIdsForListing, getDocumentOrDraft, isDocumentRead} from "../../features/entities/document";
import {getDecrypted} from "../../features/e2ee";
import DocumentUploaderAvatar from "./document-uploader-avatar-redux";
import {Box, Card} from "@mui/material";
import DocumentDownloadLink from "./document-download-link-redux";
import DocumentDescription from "./document-description-redux";
import DocumentError from "./document-error-redux";
import {getProgress} from "../../features/ui/progress";
import CircularProgress from "@mui/material/CircularProgress";
import DocumentViewer from "./viewer/DocumentViewer";

function DocumentBox({documentId}) {
  const document = useSelector(getDecrypted(getDocumentOrDraft(documentId)));
  const downloadProgress = useSelector(getProgress('downloadDocument', documentId));

  const inProgress = downloadProgress.inProgress;

  const uploaderId = document?.uploader;

  const isRead = useSelector(isDocumentRead(documentId));

  return (
    <Card style={{marginRight: 4, marginBottom: 4}} variant="outlined" component="div">
      <Box m={1}>
        <Box>
          <div className="show-avatar-labels-on-hover user-badge-outer user-badge-outer-sm">
            <div style={{display: 'flex'}}>
              <div style={{flexBasis: 0, paddingRight: 10}}>
                {inProgress ? (
                  <CircularProgress className="user-badge" style={{
                    display: 'inline-flex',
                    verticalAlign: 'middle'
                  }} size="20px"/>
                ) : (
                  <DocumentUploaderAvatar userId={uploaderId} showText={!isRead} light={isRead}/>
                )}
              </div>
              <div style={{flexBasis: "auto"}}>
                <DocumentDownloadLink documentId={documentId} hideBadges/>
                <DocumentDescription description={document.description} documentId={documentId}/>
                <DocumentError documentId={documentId} onClick={evt => evt.stopPropagation()}/>
              </div>
            </div>
          </div>
        </Box>
      </Box>
      <DocumentViewer id={documentId} caseId={document.caseId}/>
      {/* TODO: We might have duplicate text document viewers if the document appears in the document list as well.
                However, this does not seem to cause any harm for now. */}
    </Card>
  );
}

export default function PinnedDocumentsTable({listingId, ...props}) {
  const documentIds = listingId ? useSelector(getDocumentIdsForListing(listingId)) : [];

  if (!documentIds?.length) {
    return null;
  }

  return (
    <>
      <div className="panel panel-default" style={{marginTop: -25, paddingTop: 3, marginBottom: 22}}>
        <div className="panel-info-row modal-body alert alert-default">
          <div style={{display: 'flex'}}>
            <div style={{flexBasis: 0, paddingRight: 10}}>
              <h4 className="glyphicon glyphicon-pushpin"/>
            </div>
            <div style={{flexBasis: "auto", display: "flex", flexWrap: "wrap"}}>
              {documentIds.map((documentId, index) =>
                <DocumentBox
                  key={documentId || index}
                  documentId={documentId}
                />
              )}
            </div>
          </div>
          <div style={{textAlign: 'right'}}>
            <Pagination listingId={listingId}/>
          </div>
        </div>
      </div>
    </>
  );
}
