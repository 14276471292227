import React, {useState} from "react";
import {AddressTabPanel} from "./layout";
import {PersonLinksTable} from "./PersonLinksTable";
import {usePersonRelatedPersonLinks} from "../../features/addresses/addresses-hooks";
import SimpleMoreMenu from "../../packages/SimpleMoreMenu";
import {Add as AddIcon, AddLocation as AddLocationIcon, PersonAdd as PersonAddIcon} from "@mui/icons-material";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";


export function PersonRelatedPersonLinksPanel({id, onViewContact, onAddLocation, onAddPartner, selectedPersonLink, sx}) {
  const personLinkIds = usePersonRelatedPersonLinks({id, type: null});
  const [appliedFilters, setAppliedFilters] = useState([]);

  return (
    <AddressTabPanel
      filterValue={appliedFilters}
      onFilter={setAppliedFilters}
      toolbarContent={(onAddLocation || onAddPartner) ? (
        <>
          <SimpleMoreMenu
            id={`person-links-add-${id}`}
            size="large"
            title=""
            icon={<AddIcon color="action"/>}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {onAddLocation ? (
              <MenuItem
                onClick={onAddLocation}
              >
                <ListItemIcon>
                  <AddLocationIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>
                  Neue eigene Adresse...
                </ListItemText>
              </MenuItem>
            ) : null}
            {onAddPartner ? (
              <MenuItem
                onClick={onAddPartner}
              >
                <ListItemIcon>
                  <PersonAddIcon fontSize="small"/>
                </ListItemIcon>
                <ListItemText>
                  Partner hinzufügen...
                </ListItemText>
              </MenuItem>
            ) : null}
          </SimpleMoreMenu>
        </>
      ) : null}
      searchFieldPlaceholder="Kontakt suchen..."
      sx={{minHeight: 'max(50vh, 350px)', ...sx}}
    >
      <PersonLinksTable
        ids={personLinkIds}
        contextPersonId={id}
        appliedFilters={appliedFilters}
        selectedPersonLink={selectedPersonLink}
        onViewContact={onViewContact}
      />
    </AddressTabPanel>
  );
}
