import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {
  useAddressesDatabase,
} from "../../features/addresses/addresses-hooks";
import {Box} from "@mui/material";
import {AddressTabPanel} from "./layout";
import {TemplatesTable} from "./TemplatesTable";
import {TemplateForm} from "./forms/TemplateForm";


export const TemplatesDialog = function TemplatesDialog({databaseId, open, onClose}) {
  const {templates, isDeleted} = useAddressesDatabase({id: databaseId, observe: true});

  useEffect(() => {
    if (isDeleted) {
      onClose();
    }
  }, [isDeleted])

  // const addTemplate = () => setEditTemplate(-1);

  const id = `templates-dialog-${databaseId}`;

  const [appliedFilters, setAppliedFilters] = useState([]);

  const [editTemplate, setEditTemplate] = useState(null);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={id}
      maxWidth='lg'
      fullWidth
    >
      <DialogTitle id={id}>
        Vorlagen verwalten
      </DialogTitle>

      <DialogContent>
        <Box sx={{flex: 1, display: 'flex', flexDirection: 'row', mx: -3}}>
          <AddressTabPanel
            filterValue={appliedFilters}
            onFilter={setAppliedFilters}
            searchFieldPlaceholder="Vorlage suchen..."
            sx={{minHeight: 'max(350px, 70vh)', flex: 1}}
            onAdd={() => setEditTemplate(-1)}
            options={[
              {
                category: null,
                id: 'type',
                label: 'Typ',
                choice: 'address',
                chipLabel: ({
                  id,
                  choice
                }) => ({
                  'address': "nur Adressen",
                  'salutation': "nur Anreden",
                }[choice]),
                choiceLabel: ({
                  id,
                  choice
                }) => ({
                  'address': "Adresse",
                  'salutation': "Anrede",
                }[choice]),
                choices: ['address', 'salutation'],
              },
              {
                category: null,
                id: 'is_global',
                label: 'Status',
                choice: true,
                chipLabel: ({
                  id,
                  choice
                }) => ({
                  [true]: "aktive Vorlagen",
                  [false]: "ausgeblendete Vorlagen",
                }[choice]),
                choiceLabel: ({
                  id,
                  choice
                }) => ({
                  [true]: "aktiv",
                  [false]: "ausgeblendet",
                }[choice]),
                choices: [true, false],
              },
            ]}
            // onAdd={onAdd && ((...args) => {
            //   setShowSelector(false);
            //   return onAdd(...args);
            // })}
          >
            <TemplatesTable
              ids={templates}
              appliedFilters={appliedFilters}
              onViewTemplate={setEditTemplate}
            />
            {editTemplate !== null ? (
              <TemplateForm
                databaseId={databaseId}
                editTemplate={editTemplate}
                onClose={() => setEditTemplate(null)}
                initialData={{is_global: true}}
              />
            ) : null}
          </AddressTabPanel>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          color="inherit"
          onClick={onClose}
        >
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};
