import {isEntityVisible, setVisible} from "features/ui/visibility-tracker/visibility-tracker-slice";

export function addEventHandlers(store, options = {}) {
  let {target} = options;

  if (typeof window === "undefined") {
    return store;
  }

  if (target === undefined) {
    target = window;
  }

  if (target === undefined) {
    console.warning("Could not add visibilityTracker event listeners.");
    return store;
  }

  target.addEventListener('visibilitychange', event => {
    const state = store.getState();

    const currentVisible = isEntityVisible(state);
    let newVisible = currentVisible;

    if (target?.document?.visibilityState !== undefined) {
      newVisible = target?.document?.visibilityState !== 'hidden';
    }

    if (newVisible !== currentVisible) {
      store.dispatch(setVisible(newVisible));
    }
  });

  return store;
}
