import {schema} from 'normalizr';

const createEntity = (name, schemaDefinition, options) => (new schema.Entity(name, schemaDefinition, options));

const DOCUMENT = createEntity('document');

export const ADDRESSES_PERSON = createEntity('addresses_person', {
  // database: ADDRESSES_DATABASE,
});
ADDRESSES_PERSON.unencryptedFields = [
  'id',
  'created_at',
  'updated_at',
  'database',
  'default_person_link',
];
const ADDRESSES_PEOPLE = [ADDRESSES_PERSON];

export const ADDRESSES_TEMPLATE = createEntity('addresses_template', {
  // database: ADDRESSES_DATABASE,
});
ADDRESSES_TEMPLATE.unencryptedFields = [
  'id',
  'database',
  'created_at',
  'updated_at',
  'type',
  'is_global',
  'priority',
];
const ADDRESSES_TEMPLATES = [ADDRESSES_TEMPLATE];

const ADDRESSES_LOCATION = createEntity('addresses_location', {
  // database: ADDRESSES_DATABASE,
});
ADDRESSES_LOCATION.unencryptedFields = [
  'id',
  'created_at',
  'updated_at',
  'database',
];
const ADDRESSES_LOCATIONS = [ADDRESSES_LOCATION];

const ADDRESSES_PERSON_LINK = createEntity('addresses_person_link', {
  // database: ADDRESSES_DATABASE,
  parent_person: ADDRESSES_PERSON,
  child_person: ADDRESSES_PERSON,
  location: ADDRESSES_LOCATION,
});
ADDRESSES_PERSON_LINK.unencryptedFields = [
  'id',
  'created_at',
  'updated_at',
  'database',
  'parent_person',
  'child_person',
  'location',
  'salutation_template',
  'address_template',
];
const ADDRESSES_PERSON_LINKS = [ADDRESSES_PERSON_LINK];

export const ADDRESSES_LIST_ENTRY = createEntity('addresses_list_entry', {
  // database: ADDRESSES_DATABASE,
  // list: ADDRESSES_LIST,
});
ADDRESSES_LIST_ENTRY.additionalNestedFields = {
  person_link: ADDRESSES_PERSON_LINK,
};
ADDRESSES_LIST_ENTRY.unencryptedFields = [
  'id',
  'created_at',
  'updated_at',
  'database',
  'list',
  'person_link',
  'confirmed',
];
const ADDRESSES_LIST_ENTRIES = [ADDRESSES_LIST_ENTRY];

export const ADDRESSES_LIST = createEntity('addresses_list', {
  // database: ADDRESSES_DATABASE,
  entries: ADDRESSES_LIST_ENTRIES,
});
ADDRESSES_LIST.unencryptedFields = [
  'id',
  'created_at',
  'updated_at',
  'database',
  'is_event',
  'parent',
];

const ADDRESSES_LISTS = [ADDRESSES_LIST];

export const ADDRESSES_DATABASE = createEntity('addresses_database', {
  people: ADDRESSES_PEOPLE,
  locations: ADDRESSES_LOCATIONS,
  person_links: ADDRESSES_PERSON_LINKS,
  lists: ADDRESSES_LISTS,
  templates: ADDRESSES_TEMPLATES,
  // list_entries: ADDRESSES_LIST_ENTRIES,
});
ADDRESSES_DATABASE.unencryptedFields = [
  'id',
  'created_at',
  'updated_at',
  'document',
];

const COMPACT_ADDRESSES_PERSON_LINK = createEntity('addresses_person_link', {
  // database: ADDRESSES_DATABASE,
  // parent_person: ADDRESSES_PERSON,
  // child_person: ADDRESSES_PERSON,
  // location: ADDRESSES_LOCATION,
});
COMPACT_ADDRESSES_PERSON_LINK.unencryptedFields = [
  'id',
  'created_at',
  'updated_at',
  'database',
  'parent_person',
  'child_person',
  'location',
  'salutation_template',
  'address_template',
];
const COMPACT_ADDRESSES_PERSON_LINKS = [COMPACT_ADDRESSES_PERSON_LINK];

export const COMPACT_ADDRESSES_DATABASE = createEntity('addresses_database', {
  people: ADDRESSES_PEOPLE,
  locations: ADDRESSES_LOCATIONS,
  person_links: COMPACT_ADDRESSES_PERSON_LINKS,
  lists: ADDRESSES_LISTS,
  templates: ADDRESSES_TEMPLATES,
  // list_entries: ADDRESSES_LIST_ENTRIES,
});
COMPACT_ADDRESSES_DATABASE.unencryptedFields = [
  'id',
  'created_at',
  'updated_at',
  'document',
  'lists',
  'templates',
];

export const ENTITY_TYPES = {
  document: DOCUMENT,
  addresses_database: ADDRESSES_DATABASE,
  addresses_person: ADDRESSES_PERSON,
  addresses_people: ADDRESSES_PEOPLE,
  addresses_location: ADDRESSES_LOCATION,
  addresses_locations: ADDRESSES_LOCATIONS,
  addresses_person_link: ADDRESSES_PERSON_LINK,
  addresses_person_links: ADDRESSES_PERSON_LINKS,
  addresses_list: ADDRESSES_LIST,
  addresses_lists: ADDRESSES_LISTS,
  addresses_list_entry: ADDRESSES_LIST_ENTRY,
  addresses_list_entries: ADDRESSES_LIST_ENTRIES,
  addresses_template: ADDRESSES_TEMPLATE,
  addresses_templates: ADDRESSES_TEMPLATES,
};

export const ENTITY_LIST_URLS = {
  addresses_person: '/api/addresses/people/',
  addresses_location: '/api/addresses/locations/',
  addresses_person_link: '/api/addresses/person_links/',
  addresses_list: '/api/addresses/lists/',
  addresses_list_entry: '/api/addresses/list_entries/',
  addresses_template: '/api/addresses/templates/',
};
