import {createSlice, createAction, createSelector} from "@reduxjs/toolkit";
import {getDecryptedEntity} from "../decryptedEntity";

import _ from 'lodash';

const addressesSlice = createSlice({
  name: 'addresses',
  initialState: {},
  reducers: {
  },
});

export const {} = addressesSlice.actions;

export const openDatabase = createAction('addresses/openDatabase');
export const closeDatabase = createAction('addresses/closeDatabase');

export const updateDatabase = createAction('addresses/updateDatabase');

export default {
  addresses: addressesSlice.reducer,
};

export const getAddresses = state => state.addresses;

export const getAddressesEntityGetter = createSelector(
  getDecryptedEntity,
  (entityGetter) => _.memoize(entityType => entityGetter(entityType)),
);

export const getAddressesPersonGetter = createSelector(
  getDecryptedEntity,
  (entityGetter) => entityGetter('addresses_person'),
);

export const getAddressesLocationGetter = createSelector(
  getDecryptedEntity,
  (entityGetter) => entityGetter('addresses_location'),
);

export const getAddressesPersonLinkGetter = createSelector(
  getDecryptedEntity,
  (entityGetter) => entityGetter('addresses_person_link'),
);

export const getAddressesListGetter = createSelector(
  getDecryptedEntity,
  (entityGetter) => entityGetter('addresses_list'),
);

export const getAddressesListEntryGetter = createSelector(
  getDecryptedEntity,
  (entityGetter) => entityGetter('addresses_list_entry'),
);

export const getAddressesTemplateGetter = createSelector(
  getDecryptedEntity,
  (entityGetter) => entityGetter('addresses_template'),
);

export const getAddressesDatabaseGetter = createSelector(
  getDecryptedEntity,
  (entityGetter) => entityGetter('addresses_database'),
);

export const isAddressesDatabaseEmpty = createSelector(
  getAddressesDatabaseGetter,
  (databaseGetter) => id => {
    const {people, locations, person_links, lists} = databaseGetter(id);
    return people?.length === 0 && locations?.length === 0 && person_links?.length === 0 && lists?.length === 0;
  },
);
