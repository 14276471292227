import React, {useState} from "react";
import {
  renderTemplate,
  useAddressesTemplate,
} from "../../features/addresses/addresses-hooks";
import {Box} from "@mui/material";
import {DeletedCells, ViewableTableCell, ViewActionCell} from "./layout";
import {SortableAddressesEntitiesTable} from "./tables";
import {Chip} from "@mui/material";
import {EXAMPLE_PERSON_LINK, useTemplateExample} from "./template";
import IconButton from "@mui/material/IconButton";
import {ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon} from "@mui/icons-material";
import {useDecryptedEntityApi} from "../../features/decryptedEntity/decrypted-entity-hooks";
import {ENTITY_LIST_URLS, ENTITY_TYPES} from "../../api/api-schemas";

function TemplateRowContent({id, onView, selected, predecessorId, successorId, showActionColumn}) {
  const {type, template, is_global, isDeleted} = useAddressesTemplate({id, observe: true});
  const {type: successorType, priority: successorPriority, is_global: successorIsGlobal, isDeleted: successorIsDeleted} = useAddressesTemplate({id: successorId, observe: true});
  const {type: predecessorType, priority: predecessorPriority, is_global: predecessorIsGlobal, isDeleted: predecessorIsDeleted} = useAddressesTemplate({id: predecessorId, observe: true});

  const example = useTemplateExample({template});

  const entityApi = useDecryptedEntityApi(ENTITY_TYPES['addresses_template']);
  const baseUrl = ENTITY_LIST_URLS['addresses_template'];

  const [updating, setUpdating] = useState(false);

  const update = async (validatedData) => {
    const {id} = validatedData;

    setUpdating(true);

    try {
      await entityApi.patch(
        `${baseUrl}${id}/`,
        validatedData,
      );
    } finally {
      setUpdating(false);
    }
  };

  if (isDeleted) {
    return (
      <DeletedCells colSpan="3">
        Diese Vorlage wurde gelöscht.
      </DeletedCells>
    );
  }

  const higherPriority = (!predecessorIsDeleted && type === predecessorType && is_global && predecessorIsGlobal) ? predecessorPriority : undefined;
  const lowerPriority = (!successorIsDeleted && type === successorType && is_global && successorIsGlobal) ? successorPriority : undefined;

  return (
    <>
      <ViewableTableCell
        view={onView}
        sx={{
          color: is_global ? '#000' : '#ccc',
        }}
      >
        <Box>
          {type === "address" ? (
            "Adresse"
          ) : type === "salutation" ? (
            "Anrede"
          ) : type}
          {' '}
          {!is_global ? (
            <Chip label="ausgeblendet" size="small"/>
          ) : (
            null
          )}
        </Box>
      </ViewableTableCell>
      <ViewableTableCell
        view={onView}
        sx={{
          color: is_global ? '#000' : '#ccc',
        }}
      >
        <Box sx={{whiteSpace: 'pre'}}>
          {example || template}
        </Box>
      </ViewableTableCell>
      {showActionColumn && (
        <ViewActionCell>
          {higherPriority !== undefined ? (
            <IconButton
              aria-label="edit"
              size="medium"
              disabled={updating}
              onClick={() => update({id, priority: higherPriority})}
            >
              <ArrowUpwardIcon/>
            </IconButton>
          ) : null}
          {lowerPriority !== undefined ? (
            <IconButton
              aria-label="edit"
              size="medium"
              disabled={updating}
              onClick={() => update({id, priority: lowerPriority})}
            >
              <ArrowDownwardIcon/>
            </IconButton>
          ) : null}
        </ViewActionCell>
      )}
    </>
  );
}

const zeroPad = (num, places) => String(num).padStart(places, '0');

export function TemplatesTable({ids, onViewTemplate, appliedFilters, ...props}) {
  const [isOrderedByPriority, setIsOrderedByPriority] = useState(false);

  return (
    <SortableAddressesEntitiesTable
      entityType="addresses_template"
      entityNameSingular="Vorlage"
      entityNamePlural="Vorlagen"
      ids={ids}
      itemContent={(index, id, predecessorId, successorId) => (
        <TemplateRowContent
          id={id}
          onView={() => onViewTemplate(id)}
          predecessorId={isOrderedByPriority ? predecessorId : undefined}
          successorId={isOrderedByPriority ? successorId : undefined}
          showActionColumn={isOrderedByPriority}
        />
      )}
      columns={[
        {field: 'type', name: "Typ", sx: {width: '33%'}},
        {field: 'template', name: "Vorlage", sx: {width: '67%'}},
      ]}
      searchFields={[
        'type',
        'template',
      ]}
      sortFieldGetters={{
        'type': ({type, priority, is_global}) => `${type} ${is_global ? "0" : "1"} ${zeroPad(priority, 10)}`,
        'template': ({template}) => renderTemplate(template, EXAMPLE_PERSON_LINK) || template,
      }}
      searchFieldGetters={{
        'type': ({type}) => type === 'address' ? "Adresse" : type === 'salutation' ? "Anrede" : type,
        'template': ({template}) => renderTemplate(template, EXAMPLE_PERSON_LINK) + ' ' + template || template,
      }}
      defaultSortField='type'
      defaultSortOrder='asc'
      appliedFilters={appliedFilters}
      showFooter
      hideActionColumn={!isOrderedByPriority}
      onChangeSortOrder={({field, order}) => setIsOrderedByPriority(field === 'type' && order === 'asc')}
      {...props}
    />
  );
}
