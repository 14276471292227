import React, {useEffect, useState} from 'react';
import {AddressTabPanel} from "./layout";
import {ListsTable} from "./ListsTable";
import {useAddressesDatabase} from "../../features/addresses/addresses-hooks";
import {usePersonLinkName} from "./person";

export function ListsPanel({databaseId, onEditList, onSelectList, showAdd, sx, selectedLists, listsTableProps, context}) {
  const {lists} = useAddressesDatabase({id: databaseId});
  const [appliedFilters, setAppliedFilters] = useState([]);

  const searchFieldPlaceholder = (
    appliedFilters?.some(({id, choice}) => id === 'is_event' && choice) ? (
      "Event suchen..."
    ) : appliedFilters?.some(({id, choice}) => id === 'is_event' && !choice) ? (
      "Verteiler suchen..."
    ) : "Verteiler oder Event suchen..."
  );

  const options = [
    {
      category: null,
      id: 'is_event',
      label: 'Typ',
      choice: true,
      chipLabel: ({
        id,
        choice
      }) => ({
        [true]: "nur Events",
        [false]: "nur Verteiler",
      }[choice]),
      choiceLabel: ({
        id,
        choice
      }) => ({
        [true]: "Event",
        [false]: "Verteiler",
      }[choice]),
      choices: [true, false],
    },
  ];

  const contextPersonLinkId = context?.personLinkId;
  const contextPersonName = usePersonLinkName({id: contextPersonLinkId, wrap: true});
  if (contextPersonLinkId) {
    options.push({
      category: null,
      id: 'entities.person_link',
      label: 'enthält',
      choice: contextPersonLinkId,
      chipLabel: ({
        id,
        choice
      }) => ({
        [contextPersonLinkId]: contextPersonName || "enthält ausgewählten Kontakt",
      }[choice]),
      choiceLabel: ({
        id,
        choice
      }) => ({
        [contextPersonLinkId]: contextPersonName || "ausgewählten Kontakt",
      }[choice]),
      choices: [contextPersonLinkId],
      filterFunction: ({field, choice, entity}) => entity?.entries?.some(({person_link}) => person_link === choice),
    });
  }

  useEffect(() => {
    const contextPersonLinkId = context?.personLinkId;
    if (contextPersonLinkId) {
      setAppliedFilters([
        {id: 'is_event', choice: false},
        {id: 'entities.person_link', choice: contextPersonLinkId},
      ]);
    }
  }, [context]);

  return (
    <AddressTabPanel
      filterValue={appliedFilters}
      onFilter={setAppliedFilters}
      onAdd={!showAdd ? undefined : () => onEditList(-1)}
      sx={{flex: 1, ...sx}}
      searchFieldPlaceholder={searchFieldPlaceholder}
      options={options}
    >
      <ListsTable
        ids={lists}
        onSelectList={onSelectList}
        appliedFilters={appliedFilters}
        selectedLists={selectedLists}
        expandedEntityFields={contextPersonLinkId ? ['entries'] : []}
        options={options}
        {...listsTableProps}
      />
    </AddressTabPanel>
  );
}
