import './core';

import './utils/log-client-errors';
import {uimanager} from 'ui-manager';

// Initialize tab areas.
// Right now, these areas MUST be initialized first as to avoid double-processing by uimanager.
import 'ui-tabs';

import 'ui-user';


import $ from 'jquery';
import 'imports-loader?imports=default|jquery|jQuery!jquery-ui';

import 'bootstrap-loader';
import 'imports-loader?imports=default|jquery|jQuery!bootstrap-confirmation2';
import 'packages/jquery-prevent-double-submission';
import './utils/is-browser-compatible';
// transfer session key across tabs
import './utils/transfer-sessionkey';

import 'mavo-statemachines';

import 'ui-tablesorter';

import Cookies from 'js-cookie';

import 'packages/jquery-insert-at-caret';
import 'packages/jquery-set-cursor-position';

import 'ui/style.css';

import {crypto_manager} from 'mavo-crypto';

import 'ui/materialdesign';
import 'renderers/session';
import 'renderers/preload';
import 'renderers/encryptedStrings';
import 'renderers/confirmation';
import 'renderers/relativeTimes';
import 'renderers/helpButtons';
import 'renderers/user';
import 'renderers/case';
import 'renderers/document';
import 'renderers/tooltips';
import 'renderers/icons';

uimanager.add(function (el) {
  const $dom_element = $(el);

  $dom_element.find('[data-toggle="confirmation"]').addBack('[data-toggle="confirmation"]').confirmation();

  // Prevent double submission of forms.
  $dom_element.find('form:not(.js-allow-double-submission)').addBack('form:not(.js-allow-double-submission)').preventDoubleSubmission();

  $dom_element.find('a[data-click-submit]').addBack('a[data-click-submit]').click(function () {
    let data = $(this).data('click-submit');
    let form = $(this).closest('form');
    for (let name in data) if (data.hasOwnProperty(name)) {
      let value = data[name];
      let input = form.find('input[name="' + name + '"]');
      if (input.length > 0)
        input.val(value);
      else
        form.append($('<input type="hidden" />').attr('name', name).val(value));
    }
    form.submit();
  });

  // Make sure that dummy options of a select field are displayed like placeholders
  $dom_element.find("select").addBack("select").change(function () {
    if ($(this).val() === "")
      $(this).removeClass("no-placeholder");
    else
      $(this).addClass("no-placeholder")
  });
  $dom_element.find("select").addBack("select").change();

  // Allow options of select fields to change other input elements inside a form
  // TODO: move this to mavora-crypto
  $dom_element.find("select").addBack("select").change(function () {
    let option = $("option:selected", this);
    let value = this.value;
    let name = $(this).attr('name');

    // Change field values according to data attributes
    $.each(option.get(0).attributes, function (i, attr) {
      let field_name = attr.name;
      let field_value = attr.value;

      if (field_name.indexOf('data-field-') === 0) {
        field_name = field_name.replace(/^data-field-/, '').replace(/-/g, '_');

        // find field in form
        let field = option.closest('.js-security-form').find('#' + field_name);
        field.val(field_value);
        field.trigger('change');
        field.trigger('input');

        // disable field if we have a selection
        if (value)
          field.attr('disabled', 1);
        else
          field.removeAttr('disabled');
      }
    });

    // Show/hide elements that depend on the field's value
    option.closest('.js-security-form').find('[data-js-security-onlyif]').each(function () {
      let data = $.parseJSON($(this).attr('data-js-security-onlyif'));
      if (data.field_name === name) {
        if (data.allowed_values.indexOf(value) >= 0)
          $(this).removeClass("hidden");
        else
          $(this).addClass("hidden");
      }
    });

    // Process js-security-select-action if set
    let action = option.attr('data-js-security-select-action');
    if (action) {
      action = $.parseJSON(action);
      crypto_manager.process_entity(action);
    }
  });

  // Allow checkbox fields to change other input elements inside a form
  // FIXME: move this to mavora-crypto
  $dom_element.find(":checkbox").change(function () {
    let checked = $(this).is(':checked');
    let name = $(this).attr('name');

    // Show/hide elements that depend on the field's value
    $(this).closest('.js-security-form').find('[data-js-security-onlyif]').each(function () {
      let data = $.parseJSON($(this).attr('data-js-security-onlyif'));
      if (data.field_name === name) {
        if (checked)
          $(this).removeClass("hidden");
        else
          $(this).addClass("hidden");
      }
    });
  });

  // implement formset extend button
  $dom_element.find('.formset-extend-btn').addBack('.formset-extend-btn').click(function () {
    // determine template
    let $form = $(this).closest('.js-security-form');
    let $template = $form.find('[data-formset-template]').first();
    let template_html = $template.html();
    let formset_prefix = $template.attr('data-formset-template');

    // determine current total forms count
    let $total_forms = $form.find('#id_' + formset_prefix + '-TOTAL_FORMS');
    let total_forms = parseInt($total_forms.val());

    // instantiate template
    let row_html = template_html.replace(/__prefix__/g, total_forms);

    // add row
    let $row = $(row_html);
    uimanager.process($row.get(0));
    $row.insertBefore($template);

    // increase total forms counter
    $total_forms.val(total_forms + 1);
  });

  $dom_element.find('.print-btn').addBack('.print-btn').click(function () {
    let hidden_elements = [];

    // if we should only print a specific element, temporarily hide all siblings
    let print_target_selector = $(this).attr('data-print-target-selector');
    if (print_target_selector) {
      $(this).closest(print_target_selector).parents().addBack().each(function () {
        $(this).siblings().each(function () {
          if (!$(this).hasClass('hidden-print')) {
            $(this).addClass('hidden-print');
            hidden_elements.push($(this));
          }
        });
      });
    }

    // print window
    window.print();

    // hide elements that had been hidden temporarily
    setTimeout(function () {
      for (let hidden_element_index in hidden_elements)
        hidden_elements[hidden_element_index].removeClass('hidden-print');
    }, 1000);
  });

  $dom_element.find('.insert-placeholder').addBack('.insert-placeholder').each(function () {
    // remember focused element
    let focussed_element = $(this).find('.insert-placeholder-default-target input, .insert-placeholder-default-target textarea');
    $(this).find('input, textarea').on('focusin', function () {
      focussed_element = $(this);
    });

    // process click on insertion button
    $(this).find('[data-insert-string]').click(function () {
      let data = $(this).attr('data-insert-string');
      //console.log(this);
      $(focussed_element).insertAtCaret(data);
    });
  });

  // bind mavodb value boxes to inputs
  $dom_element.find('[data-mavodb-value-box]').addBack('[data-mavodb-value-box]').each(function () {
    let that = this;

    let data = $.parseJSON($(this).attr('data-mavodb-value-box'));

    let click_button_on_enter = function (evt) {
      if (evt.which === 13) {
        let button = $(that).find(data.enter_action_selector).get(0);
        if (button) {
          button.click();
          evt.preventDefault();
        }
      }
    };

    // Try to find input in form.
    let $input = $(this).closest('.js-security-form').find(data.input_selector);
    if($input.length === 0)
      $input = $(data.input_selector);
    $input.each(function () {
      $(this).on('input', function () {
        // determine value
        let value = $(this).val().trim().toLowerCase();

        // if we found a value, show box
        let mavodb_item_id = $('[data-mavodb=' + data.db + ']').mavodb('get_id_by_column_value', data.search_column, value);

        if (mavodb_item_id) {

          // set URL
          if (data.href_template) {
            let url = data.href_template.replace('MAVODBID', mavodb_item_id);
            $(that).find('[href]').attr('href', url);
          }

          // set action
          if (data.submit_action_template) {
            let submit_action = data.submit_action_template.replace('MAVODBID', mavodb_item_id);
            $(that).find('[data-submit-action]').each(function () {
              $(this).attr('data-submit-action', submit_action);
            });
          }

          // bind enter action
          $(this).on('keydown', click_button_on_enter);

          // show
          if ($(that).hasClass('hidden'))
            $(that).hide();
          $(that).removeClass('hidden');
          $(that).slideDown();
        } else {
          // unbind enter action
          $(this).off('keydown', click_button_on_enter);

          // hide
          $(that).slideUp();
        }
      }).trigger('input');
    });
  });

  $dom_element.find(".alert a.close").addBack(".alert a.close").click(function (e) {
    $(this).parent().slideUp('slow');
    e.stopPropagation();
  });

  // implement multiaction
  $dom_element.find('[data-multiaction]').on('click', function () {
    let data = $.parseJSON($(this).attr('data-multiaction'));
    if (data.hide_selector)
      $(this).closest(data.hide_selector).addClass('hidden');
    if (data.append)
      $(data.append).insertAfter($(this));
  });

  // focus first value-less input field in modal dialogues
  $dom_element.find('.modal').on('shown.bs.modal', function () {
    let $element = $(this).find('input[value=""],textarea').first();
    if ($element.length > 0)
      $element.focus();
    else
      $(this).find('input,textarea').first().focus();
  });

  // implement async post requests (e.g., for session-wide notification dismissal)
  $dom_element.find('[data-async-post-request]').click(function () {
    let api_url = $(this).attr('data-async-post-request');
    let csrftoken = Cookies.get('csrftoken') || $(this).attr('csrftoken');

    $.ajax({
      url: api_url,
      method: 'POST',
      headers: {
        'X-CSRFToken': csrftoken
      },
    });

    return true;
  });
});

$(document).ready(function () {

  // Dynamically add and remove css classes during drag and drop events to allow specific styling.
  let drag_objects = $();
  $(window).on({
    'dragover': function (event, data) {
      event.stopPropagation();
      event.preventDefault();
      event.originalEvent.dataTransfer.dropEffect = 'copy'; // we'd like to show the "no-drop" cursor, but this triggers jquery errors in firefox right now...
    },
    'dragenter': function (event, data) {
      drag_objects = drag_objects.add($(event.target));
      $('html').addClass('is-dragging');
      $(event.target).closest('.dropzone').addClass('is-hovered');
      event.preventDefault();
    },
    'dragstart': function (event, data) {
      // prevent dragging of DOM items as this is likely to cause confusion
      event.preventDefault();
    },
    'dragleave': function (event, data) {
      $(event.target).closest('.dropzone').removeClass('is-hovered');
      drag_objects = drag_objects.not($(event.target));
      drag_objects.each(function () {
        $(this).closest('.dropzone').addClass('is-hovered');
      });
      if (drag_objects.length === 0)
        $('html').removeClass('is-dragging');
    },
    'drop': function (event, data) {
      event.preventDefault();
      drag_objects.each(function () {
        $(this).closest('.dropzone').removeClass('is-hovered');
      });
      drag_objects = $();
      $('html').removeClass('is-dragging');
    }
  });

  // Make clickable elements clickable
  $(window).on('click', function (event) {
    if ($(event.target).closest('.clickable-target').length > 0)
      return true;

    let container = $(event.target).closest('.clickable');
    if (container.length === 0)
      return true;
    container = container.get(0);

    let target = $(container).find('.clickable-target');
    if (target.length > 0) {
      target.each(function () {
        if (this !== event.target && $(this).closest('.clickable').get(0) === container && $(event.target).closest('.clickable-exclude', container).length === 0)
          this.click();
      });
    }
  });

  // Ensure that values of autofocus inputs are selected
  $("[autofocus]").each(function () {
    let that = this;
    // for any reason, Chrome requires a timeout here...
    setTimeout(function () {
      $(that).select();
    }, 0);
  });

  // Allow history-back-links without violating CSP directives (i.e., without using javascript: URLs)
  $("a.history-back").on('click', function () {
    window.history.back();
    return false;
  });

  $("a[href='javascript:']").on('click', function () {
    return false;
  });

  // Remove clickable class from entities without clickable-target.
  $(".clickable").each(function () {
    if (!($(this).find('.clickable-target').length))
      $(this).removeClass('clickable');
  });
});

if (module.hot) {
  module.hot.accept()
}
