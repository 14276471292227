import React from "react";

import {
  SimpleYupFormDialog,
  SimpleYupFormDialogImplementation
} from "packages/react-hook-form-mui-yup-helpers/simple-yup-form-dialog";
import {useDecryptedEntityDeleter} from "./decrypted-entity-hooks";
import {useFormContext} from "react-hook-form";

export function DecryptedEntityFormDialogImplementation({
  deletingUuids,
  entityType,
  entityIdFieldName,
  onDelete,
  baseUrl,
  ...props
}) {
  const {watch} = useFormContext();
  const entityId = watch(entityIdFieldName);

  if (!entityId) {
    onDelete = undefined;
  }

  if (onDelete) {
    const baseOnDelete = onDelete;
    onDelete = () => baseOnDelete(entityId);
  }

  return (
    <SimpleYupFormDialogImplementation
      isDeleting={entityId ? deletingUuids?.has(entityId) : undefined}
      onDelete={onDelete}
      {...props}
    />
  );
}

export default function DecryptedEntityFormDialog({
  entityType,
  entityIdFieldName = 'id',
  baseUrl,
  DialogImplementationComponent=DecryptedEntityFormDialogImplementation,
  ...props
}) {
  const {
    deleteDecryptedEntity,
    deletingUuids
  } = useDecryptedEntityDeleter({
    entityType,
    baseUrl
  });

  return (
    <SimpleYupFormDialog
      onDelete={deleteDecryptedEntity}
      DialogImplementationComponent={DialogImplementationComponent}
      entityType={entityType}
      entityIdFieldName={entityIdFieldName}
      baseUrl={baseUrl}
      deletingUuids={deletingUuids}
      {...props}
    />
  );
}
