import React, {useRef} from 'react';
import Button from '@mui/material/Button';
import {withStyles} from '@mui/styles';
import {UserAvatarContainer} from "../../user/Avatar";
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TextField} from "@mui/material";
import {Dialog} from "../../layout/modal/Dialog";
import {DialogTitle} from "../../layout/modal/DialogTitle";

const styles = theme => ({
  icon: {
    marginRight: theme.spacing(2),
    float: 'left',
    fontSize: '2.4em',
  },
  title: {
    color: theme.palette.primary.main,
  },
  content: {
    margin: 0,
    padding: theme.spacing(2),
    minWidth: 400,
  },
  message: {
    height: '100%',
    "& .MuiInputBase-root, & .MuiInputBase-input": {
      height: '100%',
    },
  },
});

export default withStyles(styles)(props => {
  const {
    classes,
    onClose, onUpload,
    onSetMessage, onSetDescription,
    documentUploaderId,
    message, description,
    open
  } = props;

  const ref = useRef(null);

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="text-document-dialog-title"
      ref={ref}
    >
      <DialogTitle disableTypography onClose={() => onClose()} className={classes.title}>
        <Typography variant="h5">
          <FontAwesomeIcon icon={['fas', 'file-alt']} className={classes.icon}/>
          Nachricht verfassen...
        </Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          mit Absender <span className="user-badge-outer-sm"><UserAvatarContainer userId={documentUploaderId}/></span>
        </Typography>
      </DialogTitle>

      <DialogTitle disableTypography className={classes.description}>
        <TextField defaultValue={description} id="description" label="Betreff (optional)" variant="outlined" fullWidth onChange={evt => onSetDescription(evt.target.value)}/>
      </DialogTitle>

      <DialogContent dividers className={classes.content}>
        <TextField defaultValue={message} classes={{root: classes.message}} id="content" label="Nachricht" variant="outlined" multiline minRows={5} fullWidth autoFocus size='medium' onChange={evt => onSetMessage(evt.target.value)}/>
      </DialogContent>

      <DialogActions>
        {onClose ? (
          <Button
            onClick={onClose}
            color="primary"
          >
            Abbrechen
          </Button>
        ) : null}
        {onUpload ? (
          <Button
            variant="contained"
            onClick={onUpload}
            color="primary"
            startIcon={<FontAwesomeIcon icon={['fas', 'download']}/>}
          >
            Absenden
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
});
