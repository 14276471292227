import {Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormGroup, FormHelperText} from "@mui/material";
import {Controller} from "react-hook-form";
import React from "react";
import _ from "lodash";

export default function Checkbox({errors = {}, control, name, helperText, register, ...props}) {
  const error = _.get(errors, name);

  return (
    <FormControl error={!!error} component="fieldset">
      <FormGroup row>
        <FormControlLabel
          control={(
            <Controller
              name={name}
              control={control}
              defaultValue={false}
              render={({field: {onChange, value, ...otherProps}}) => (
                <MuiCheckbox
                  {...props}
                  {...otherProps}
                  checked={value === "true" || value === true}
                  // Weird workaround: We need to convert the value to string as otherwise, checkboxes seem to work
                  // only in forms with at least one other field that has a null or undefined values. Otherwise,
                  // checkbox values are degraded to undefined before validation. This might be an issue with
                  // react-hook-form, so we may check again later with a newer version.
                  onChange={(e) => onChange(`${e.target.checked}`)}
                  color="primary"
                />
              )}
            />
          )}
          {...props}
        />
        {error ? <FormHelperText>{error.message}</FormHelperText> : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormGroup>
    </FormControl>
  );
}
