import {CANCEL, channel} from 'redux-saga';
import {
  actionChannel,
  call,
  cancel,
  cancelled,
  delay,
  fork,
  put,
  race,
  select,
  spawn,
  take,
  takeEvery
} from 'redux-saga/effects';
import {apiURL} from "../../../api";
import {encrypt} from "../../e2ee/crypto-fragments";
import {endProgress, reportProgress, resetProgress, startProgress, withProgress} from "../../ui/progress";
import {
  canViewInline,
  changeDocumentId,
  clearContent,
  deletedDocument,
  deletedDocumentDraft,
  deleteDocument,
  deleteDocumentDraft,
  displayed,
  documentPlaintextSize,
  getDocument,
  getDocumentContent,
  getDocumentDraft,
  getDocumentOrDraft,
  hideDocument, isTextDocument,
  markDocumentRead,
  markDocumentUnread,
  modifyAndPersistDocument,
  modifyDocumentDraft,
  needParsedDocument, pinDocument,
  prefetchDocument,
  replyToDocument,
  saveDocument,
  setBlobUrl,
  setContent,
  setDocument,
  setDocumentBlob,
  shouldKeepDocumentContent, unpinDocument,
  updateDocument, updatePinDocument,
  uploadDocument,
  uploaded,
  viewDocument,
} from "./document-slice";
import {createSimpleAPICall} from "api";
import {crypto_manager} from "../../../components/mavo-crypto";
import {bytesToBase64, createProgressReadableStream, loadBlobString} from "../../../packages/binary-data-helpers";
import log from 'loglevel';
import {canDetermineMimeType, determineMimeType, hasFetchedMimeTypes, needMimeTypes} from "../../extras/mime-types";
import {getDecrypted} from "../../e2ee";
import {saveAs} from "file-saver/dist/FileSaver";
import {hideView, showView} from "../../ui/view";
import dateParser from "react-timeago/lib/dateParser";
import $ from "jquery";
import {fetchCase, getCase, updateCase} from "../case";
import Cookies from "js-cookie";
import React from "react";
import {getConfig} from "../../config";
import {ReadableFileSize} from "../../../components/data/number";
import {UPLOAD_SLOTS} from "./document-constants";
import {reverseRewriteDocumentName, rewriteDocumentName} from "./filename-saga-utils";
import {MissingKeyError} from "../../../components/mavo-crypto";
import {updateListing} from "../../ui/listing";

const watchMarkDocumentRead = createSimpleAPICall({
  apiName: 'readDocument',
  lookupField: 'documentId',
  createPayload: action => ({id: action.payload.documentId, case_id: action.payload.caseId}),
  successAction: ({result}) => updateDocument(result),
});

const watchMarkDocumentUnread = createSimpleAPICall({
  apiName: 'unreadDocument',
  lookupField: 'documentId',
  createPayload: action => ({id: action.payload.documentId, case_id: action.payload.caseId}),
  successAction: ({result}) => updateDocument(result),
});

const watchPinDocument = createSimpleAPICall({
  apiName: 'pinDocument',
  lookupField: 'documentId',
  createPayload: action => ({id: action.payload.documentId, case_id: action.payload.caseId}),
  successAction: ({result}) => updatePinDocument(result),
});

const watchUnpinDocument = createSimpleAPICall({
  apiName: 'unpinDocument',
  lookupField: 'documentId',
  createPayload: action => ({id: action.payload.documentId, case_id: action.payload.caseId}),
  successAction: ({result}) => updatePinDocument(result),
});

const watchModifyAndPersist = createSimpleAPICall({
  apiName: 'partialUpdateDocument',
  lookupField: 'documentId',
  createPayload: action => ({id: action.payload.documentId, case_id: action.payload.caseId}),
  createBody: function* (action) {
    const {documentId, caseId, ...data} = action.payload;
    const {key_id} = (yield select(getDocument(documentId)));
    return encrypt(data, key_id);
  },
  successAction: ({result}) => updateDocument(result),
});

function* autoMarkRead() {
  // Mark documents read when saved or displayed.
  const readDocuments = [];

  while (true) {
    const {type, payload} = yield take([endProgress, displayed]);

    let documentId;
    if (type === endProgress.type && payload.type === saveDocument.type && !payload.error) {
      // Document has successfully been saved.
      documentId = payload.id;
    } else if (type === displayed.type) {
      // Document has been displayed.
      documentId = payload.documentId;
    } else {
      continue;
    }

    // Mark read only on first display / save.
    if (readDocuments.includes(documentId)) {
      continue;
    }

    const caseId = (yield select(getDocument(documentId))).case;
    if (caseId) {
      // Mark document read.
      yield put(markDocumentRead({documentId, caseId}));

      // Keep track of documents recently marked read.
      readDocuments.push(documentId);
    }
  }
}

class DownloadError extends Error {
  name = 'DownloadError';

  constructor(message, data) {
    super(message);
    this.data = data || {};
  }
}

class UploadError extends Error {
  name = 'UploadError';

  constructor(message, data) {
    super(message);
    this.data = data || {};
  }
}

class DecryptionError extends Error {
  name = 'DecryptionError';

  constructor(message, data) {
    super(message);
    this.data = data || {};
  }
}

class EncryptionError extends Error {
  name = 'EncryptionError';

  constructor(message, data) {
    super(message);
    this.data = data || {};
  }
}

const DOWNLOAD_ERROR_MESSAGES = {
  //401: "Der Download wurde vom Server abgelehnt. Bitte laden Sie die Seite neu, um sicherzustellen, dass Sie eingeloggt sind.",
  //403: "Der Download wurde vom Server abgelehnt. Sie sind nicht (mehr) berechtigt, auf dieses Dokument zuzugreifen.",
  //404: "Das Dokument existiert nicht mehr.",
  500: "Ein unerwarteter Fehler ist aufgetreten.",
  503: "Der Download ist aufgrund von Wartungsarbeiten kurzzeitig nicht möglich. Bitte versuchen Sie es in wenigen Augenblicken erneut.",
  default: "Der Download ist fehlgeschlagen.",
};

function* streamDownloadAndDecrypt({encryptedResponse, type, keyId, downloadProgress, decryptProgress}) {
  // Ensure that ReadableStreams are supported.
  if (!encryptedResponse.body) {
    console.warn("ReadableStreams are not supported. Using slow compatibility implementation instead.");
    return yield* fallbackDownloadAndDecrypt({
      encryptedResponse,
      keyId,
      type,
      downloadProgress,
      decryptProgress
    });
  }

  // Download encrypted response body and track progress.
  let downloadedBytes = 0, downloadError, encryptedStream;
  try {
    encryptedStream = createProgressReadableStream(
      encryptedResponse.body,
      progress => {
        downloadedBytes = progress;
      },
      error => {
        downloadError = error;
      }
    );
  } catch (e) {
    console.exception(
      "ReadableStream support seems to be broken in this browser. Using slow compatibility implementation instead."
    );
    return yield* fallbackDownloadAndDecrypt({
      encryptedResponse,
      keyId,
      type,
      downloadProgress,
      decryptProgress
    });
  }

  // Decrypt document and track progress.
  let decryptedBytes = 0, decryptError, decryptedStream;
  try {
    decryptedStream = createProgressReadableStream(
      crypto_manager.decrypt_stream(keyId, encryptedStream),
      progress => {
        decryptedBytes = progress;
      },
      error => {
        decryptError = error;
      }
    );
  } catch (e) {
    throw new DecryptionError("Das Dokument konnte nicht entschlüsselt werden.", {reason: e});
  }

  // Wrap decrypted stream into appropriate Response object.
  const decryptedResponse = new Response(
    decryptedStream,
    {
      headers: {
        'Content-Type': type,
      },
    },
  );

  // Consume decrypted response and create a blob from it.
  // This is where the actual download and decryption happens, so we report progress here.
  try {
    const {decryptedBlob} = yield race({
      decryptedBlob: decryptedResponse.blob(),
      progress: call(function* () {
        let lastDownloadedBytes = null, lastDecryptedBytes = null;
        while (true) {
          if (downloadedBytes !== lastDownloadedBytes) {
            yield put(reportProgress({...downloadProgress, progress: downloadedBytes}));
            lastDownloadedBytes = downloadedBytes;
          }
          yield delay(333);
          if (decryptedBytes !== lastDecryptedBytes) {
            yield put(reportProgress({...decryptProgress, progress: decryptedBytes}));
            lastDecryptedBytes = decryptedBytes;
          }
          yield delay(333);
        }
      }),
    });

    return {decryptedBlob, downloadedBytes};
  } catch (e) {
    if (downloadError) {
      throw new DownloadError("Das Dokument konnte nicht vollständig heruntergeladen werden.", {
        reason: downloadError,
        downloadedBytes
      });
    } else if (decryptError) {
      throw new DecryptionError("Das Dokument konnte nicht korrekt entschlüsselt werden.", {
        reason: decryptError,
        downloadedBytes
      });
    } else {
      throw e;
    }
  }
}

/**
 * Fallback variant of streamDownloadAndDecrypt that does not rely on ReadableStreams support.
 */
function* fallbackDownloadAndDecrypt({encryptedResponse, type, keyId, downloadProgress, decryptProgress}) {
  // Download encrypted response body.
  let downloadedBytes = 0;
  let encryptedBlob;

  // Use ReadableStreamReader if available as to track progress...
  if (encryptedResponse.body) {
    const reader = encryptedResponse.body.getReader();
    let chunks = [];
    while (true) {
      const {done, value} = yield reader.read();
      if (done) {
        break;
      }

      chunks.push(value);

      // Merge chunks from time to time as some browsers seem to have performance issues when building blobs from many
      // chunks.
      if (chunks.length >= 10) {
        chunks = [new Blob(chunks)];
      }

      downloadedBytes += value.length;
      yield put(reportProgress({...downloadProgress, progress: downloadedBytes}));
    }
    yield put(endProgress({...downloadProgress, success: true}));
    yield delay(0);  // Give UI time to update.

    encryptedBlob = new Blob(chunks);
  } else {
    // ...otherwise, get blob without tracking progress.
    encryptedBlob = yield encryptedResponse.blob();
    yield put(endProgress({...downloadProgress, success: true}));
  }
  downloadedBytes = encryptedBlob.size;

  // Decrypt blob.
  let decryptedBytes = 0;
  const {decryptedBlob} = yield race({
    decryptedBlob: crypto_manager.decrypt_blob(
      keyId,
      encryptedBlob,
      type,
      progress => {
        decryptedBytes = progress;
      },
    ),
    progress: call(function* () {
      let lastDecryptedBytes = null;
      while (true) {
        if (decryptedBytes !== lastDecryptedBytes) {
          yield put(reportProgress({...decryptProgress, progress: decryptedBytes}));
          lastDecryptedBytes = decryptedBytes;
        }
        yield delay(250);
      }
    }),
  });

  return {decryptedBlob, downloadedBytes};
}

function* downloadDocument({documentId}, retryAction) {
  const {key_id: keyId, size: encryptedSize, name: fileName, uploaded_at, case: caseId} = (yield select(getDecrypted(getDocument(documentId))));
  const decryptedSize = yield select(documentPlaintextSize(documentId));

  // Track download and decryption progress.
  const downloadProgress = {type: 'downloadDocument', id: documentId};
  yield put(startProgress({...downloadProgress, total: encryptedSize, blockUnload: true}));
  const decryptProgress = {type: 'decryptDocument', id: documentId};
  yield put(startProgress({...decryptProgress, total: decryptedSize, blockUnload: true}));

  try {
    // Start to fetch the document.
    const encryptedResponse = yield fetch(apiURL('getDocumentContent', {id: documentId, case_id: caseId})).catch(e => {
      throw new DownloadError("Es konnte keine Verbindung zum Server aufgebaut werden. Bitte überprüfen Sie Ihre Internetverbindung.", {reason: e});
    });

    // Handle download errors.
    const status = encryptedResponse.status;
    if (!encryptedResponse.ok) {
      let obj = {};
      try {
        obj = yield encryptedResponse.json();
      } catch (e) {
        // Never mind, we can use a generic error message.
      }
      throw new DownloadError(
        obj.detail || DOWNLOAD_ERROR_MESSAGES[status in DOWNLOAD_ERROR_MESSAGES ? status : 'default'],
        {status},
      );
    }

    // Determine plaintext mime type.
    let type = encryptedResponse.headers.get('X-Plaintext-Content-Type');
    if (!(yield select(canDetermineMimeType({fileName, type})))) {
      yield put(needMimeTypes({}));

      // Wait up to 2 seconds for mime types to become available.
      const {timeout} = yield race({
        hasFetched: call(function* () {
          while (true) {
            if (yield select(hasFetchedMimeTypes)) {
              return;
            }
            yield delay(50);
          }
        }),
        timeout: delay(2000),
      });
      if (timeout) {
        console.warn("Could not fetch list of mime types to work around potential download issues.");
      }
    }
    type = yield select(determineMimeType({fileName, type}));

    // Download and decrypt.
    const {decryptedBlob, downloadedBytes} = yield* streamDownloadAndDecrypt({
      encryptedResponse,
      type,
      keyId,
      downloadProgress,
      decryptProgress,
    });

    // Verify lengths.
    if (downloadedBytes !== encryptedSize) {
      throw new DownloadError("Das Dokument konnte nicht vollständig heruntergeladen werden.", {status});
    }
    if (decryptedBlob.size !== decryptedSize) {
      throw new DecryptionError("Das Dokument konnte nicht vollständig entschlüsselt werden.");
    }

    // Convert blob to file object.
    let blobUrlSource;
    try {
      blobUrlSource = new File([decryptedBlob], fileName, {lastModified: dateParser(uploaded_at), type});
    } catch (e) {
      // Some browser do not support lastModified, so we fall back the the plain blob object.
      blobUrlSource = decryptedBlob;
    }

    // Download and decryption have been successful.
    yield put(endProgress({...downloadProgress, success: true}));
    yield put(endProgress({...decryptProgress, success: true}));

    return blobUrlSource;
  } catch (e) {
    const error = e.message;
    if (e instanceof DownloadError) {
      const {status} = e.data;
      yield put(endProgress({...downloadProgress, error, errorStatus: status, retryAction}));
      yield put(endProgress({...decryptProgress, abort: true}));
    } else if (e instanceof DecryptionError) {
      yield put(endProgress({...downloadProgress, abort: true}));
      yield put(endProgress({...decryptProgress, error, retryAction}));
    } else {
      yield put(endProgress({...downloadProgress, abort: true}));
      yield put(endProgress({
        ...decryptProgress,
        error: e.displayMessage || "Ein unerwarteter Fehler ist aufgetreten.",
        reason: e.message,
        retryAction,
      }));
      log.error({msg: "exception in document download saga", errorType: typeof e, error: e});
    }
  }
}

export const saveBlobOfDocument = blob => withProgress(
  action => ({type: action.type, id: action.payload.documentId}),
  true,
)(function* (action) {
  const {documentId} = action.payload;
  let {name} = yield select(getDecrypted(getDocumentOrDraft(documentId)));
  if (name === undefined || Number.isNaN(name)) {
    throw new Error("Das Dokument konnte nicht gespeichert werden.");
  }

  // Respect document name rewriting.
  const {documentNameRewriteDownload: rewrite} = yield select(getConfig);
  const rewrittenName = rewrite ? (yield rewriteDocumentName(name, documentId)) : name;

  try {
    saveAs(blob, rewrittenName);
    yield delay(333); // Give browser some time to process the download.
  } catch (e) {
    const error = new Error("Das Dokument konnte nicht gespeichert werden.");
    error.reason = e;
    throw error;
  }
});

function* performDocumentUpload({documentId, blob, documentUploadSlotsSemaphore}, retryAction) {
  const encryptProgress = {type: 'encryptDocument', id: documentId};
  const uploadProgress = {type: 'uploadDocument', id: documentId};

  // Wait for upload slot.
  yield race({
    uploadSlot: take(documentUploadSlotsSemaphore),
    resetProgress: function* () {
      // If we do not get an upload slot immediately, we use the time to reset any previous upload statuses.
      yield put(resetProgress(encryptProgress));
      yield put(resetProgress(uploadProgress));

      // The semaphore MUST win the race!
      while (true) {
        yield delay(10000);
      }
    }(),
  });

  const {name, type, charset, case: caseId, description} = (yield select(getDocumentDraft(documentId)));
  const {key_id: caseKeyId} = (yield select(getDecrypted(getCase(caseId))));

  // Respect document name rewriting.
  const {documentNameRewriteUpload: rewrite} = yield select(getConfig);
  const rewrittenName = rewrite ? (yield reverseRewriteDocumentName(name, documentId)) : name;

  try {
    // Track encryption and upload progress.
    yield put(startProgress({...encryptProgress, total: blob.size, blockUnload: true}));
    yield put(startProgress({...uploadProgress, total: 0, blockUnload: true}));

    try {
      const {maxDocumentSize} = yield select(getConfig);
      if (maxDocumentSize !== undefined && blob.size > maxDocumentSize) {
        throw new EncryptionError(`Die maximal erlaubte Dokumentgröße beträgt ${ReadableFileSize({value: maxDocumentSize})}.`);
      }

      // Generate key for document encryption...
      const tmpKeyId = crypto_manager.add_random_key();
      // ...and encrypt it with case key.
      const encryptedKey = crypto_manager.wrap_key(tmpKeyId, caseKeyId);

      // Encrypt meta data.
      const encryptedName = crypto_manager.encrypt_string(tmpKeyId, rewrittenName);
      const encryptedDescription = description !== undefined ? crypto_manager.encrypt_string(tmpKeyId, description) : undefined;

      // Encrypt blob.
      let encryptedBytes = 0,
        encryptedBlob;
      try {
        const result = yield race({
          encryptedBlob: crypto_manager.encrypt_blob(
            tmpKeyId,
            blob,
            loaded => {
              encryptedBytes = loaded;
            },
          ),
          progress: call(function* () {
            let lastEncryptedBytes = null;
            while (true) {
              if (encryptedBytes !== lastEncryptedBytes) {
                yield put(reportProgress({...encryptProgress, progress: encryptedBytes}));
                lastEncryptedBytes = encryptedBytes;
              }
              yield delay(250);
            }
          }),
        });
        encryptedBlob = result.encryptedBlob;
      } catch (e) {
        if (e instanceof DOMException && e.name === 'NotFoundError') {
          throw new EncryptionError(`Die hochzuladende Datei scheint nicht mehr zu existieren.`);
        } else if (e instanceof DOMException && e.name === 'NotReadableError') {
          throw new EncryptionError(`Auf die hochzuladende Datei konnte nicht zugegriffen werden. Möglicherweise ist sie in einer anderen Anwendung geöffnet oder es fehlen Zugriffsrechte. Bitte wählen Sie die Datei erneut von Ihrer Festplatte aus, falls der Fehler wiederholt auftritt.`);
        } else {
          throw e;
        }
      }

      if (encryptedBlob.size < blob.size) {
        throw new EncryptionError("Das Dokument konnte nicht verschlüsselt werden.");
      }

      // Encryption was successful, continue with upload.
      yield put(endProgress({...encryptProgress, success: true}));

      // Construct form data.
      const data = new FormData();
      data.append("encrypted_file", encryptedBlob);
      data.append("type", type || 'application/octet-stream');
      if (charset)
        data.append("charset", charset);
      data.append("encrypted_key", bytesToBase64(encryptedKey));
      data.append("encrypted_name", bytesToBase64(encryptedName));
      data.append("encrypted_description", bytesToBase64(encryptedDescription || ''));

      // Upload.
      let uploadedBytes = 0, totalUploadBytes = encryptedBlob.size;
      const {response} = yield race({
        response: function () {
          let ajax;
          const promise = new Promise((resolve, reject) => {
            ajax = $.ajax({
              url: `/akten/${caseId}/dokumente/`,
              type: 'POST',
              data: data,
              dataType: 'json',
              processData: false,
              contentType: false,
              headers: {
                'X-CSRFToken': Cookies.get('csrftoken'),
              },
              xhrFields: {
                responseType: 'text',
                onerror: e => {
                  reject(e);
                }
              },
              success: (data, textStatus, xhr) => {
                resolve(data);
              },
              xhr: () => {
                const xhr = $.ajaxSettings.xhr();
                xhr.upload.onprogress = e => {
                  uploadedBytes = e.loaded;
                  totalUploadBytes = e.total;
                };
                return xhr;
              },
              error: (jqXHR, textStatus, errorThrown) => {
                const data = jqXHR.responseJSON || {};
                if (data.errors && data.errors.__all__ && data.errors.__all__[0]) {
                  reject(new UploadError(data.errors.__all__[0], {status: jqXHR.status}));
                } else {
                  reject(new UploadError("Das Dokument konnte nicht hochgeladen werden.", {status: jqXHR.status}));
                }
              }
            });
          });
          promise[CANCEL] = () => {
            if (ajax) {
              ajax.abort();
            }
          }
          return promise;
        }(),
        progress: function* () {
          let lastUploadedBytes = null;
          while (true) {
            if (uploadedBytes !== lastUploadedBytes) {
              yield put(reportProgress({...uploadProgress, progress: uploadedBytes, total: totalUploadBytes}));
              lastUploadedBytes = uploadedBytes;
            }
            yield delay(250);
          }
        }(),
      });

      // Process response.
      try {
        if (response.status !== 'success') {
          throw "invalid response status";
        }

        crypto_manager.copy_key(response.key_id, tmpKeyId);

        const document = response.obj;

        yield put(modifyDocumentDraft({
          documentId,
          status: 'just_uploaded',
        }));
        yield put(changeDocumentId({
          documentId,
          newDocumentId: document.id,
        }));
        documentId = document.id;

        yield put(setDocument(document));

        if (document.type === 'text/plain' && document.size < 100 * 1024) {
          const content = yield loadBlobString(blob);

          yield put(setContent({
            id: document.id,
            content,
            keep: true,
          }));
        }
      } catch (e) {
        if (response.errors && response.errors.__all__ && response.errors.__all__[0]) {
          throw new UploadError(response.errors.__all__[0], {status: response.status})
        }
        throw new UploadError("Das Dokument konnte nicht hochgeladen werden.", {status: response.status, reason: e});
      }

      // Upload was successful.
      yield put(endProgress({...uploadProgress, success: true}));

      yield put(uploaded({documentId}));
    } catch (e) {
      const error = e.message;
      if (e instanceof UploadError) {
        const {status} = e.data;
        yield put(endProgress({...encryptProgress, abort: true}));
        yield put(endProgress({...uploadProgress, error, errorStatus: status, retryAction}));
      } else if (e instanceof EncryptionError) {
        yield put(endProgress({...encryptProgress, error, retryAction}));
        yield put(endProgress({...uploadProgress, abort: true}));
      } else {
        yield put(endProgress({
          ...encryptProgress,
          error: e.displayMessage || "Ein unerwarteter Fehler ist aufgetreten.",
          reason: e.message,
          retryAction,
        }));
        yield put(endProgress({...uploadProgress, abort: true}));
        if (!(e instanceof MissingKeyError)) {
          log.error({msg: "exception in document upload saga", errorType: typeof e, error: e});
        }
      }
      yield cancel();
    } finally {
      if (yield cancelled()) {
        yield put(endProgress({...encryptProgress, abort: true}));
        yield put(endProgress({...uploadProgress, abort: true}));
      }
    }
  } finally {
    yield put(documentUploadSlotsSemaphore, true);
  }
}

/**
 * Parse blob into text and provide the result in the redux store.
 */
const createParsedBlobProvider = ({documentId}) => function* (chan) {
  let blob, parsedBlobNeeded = false;
  while (true) {
    const action = yield take(chan);

    // Handle change of document id.
    if (action.type === changeDocumentId.type) {
      documentId = action.payload.documentId;
      continue;
    }

    // Handle other actions.
    const {needed, ...data} = action;
    if (data.blob) {
      blob = data.blob;
    } else {
      parsedBlobNeeded = needed;
    }

    if (blob) {
      if (parsedBlobNeeded) {
        yield put(setContent({id: documentId, content: yield loadBlobString(blob)}));
      } else if (!(yield select(shouldKeepDocumentContent(documentId)))) {
        yield put(clearContent({id: documentId}));
      }
    }
  }
};

const ACTION_TYPES_THAT_AFFECT_CONTENT = [
  saveDocument.type,
  viewDocument.type,
  hideDocument.type,
  prefetchDocument.type,
  needParsedDocument.type,
  setDocumentBlob.type,
  changeDocumentId.type,
  uploadDocument.type,
  deletedDocument.type,
  deleteDocumentDraft.type,
  deletedDocumentDraft.type,
];
const ACTION_TYPES_THAT_REQUIRE_CONTENT = [
  saveDocument.type,
  viewDocument.type,
  prefetchDocument.type,
  needParsedDocument.type,
];

/**
 * Creator for a saga that manages all actions that involve the content of a specific document.
 */
const createDocumentContentSaga = ({documentId, documentUploadSlotsSemaphore}) => function* (chan) {
  // In some cases, a copy of the document's content is required in the redux store.
  const parsedBlobProviderChan = yield call(channel);
  yield fork(createParsedBlobProvider({documentId}), parsedBlobProviderChan);

  const subSagaChannels = [parsedBlobProviderChan];

  let blob, blobUrl;
  let documentUploadTask;
  try {
    while (true) {
      const action = yield take(chan);

      // Handle change of document id.
      if (action.type === changeDocumentId.type) {
        documentId = action.payload.documentId;
        yield* subSagaChannels.map(subSagaChan => put(subSagaChan, action));
        continue;
      }

      // setDocumentBlob provides content.
      if (action.type === setDocumentBlob.type) {
        if (blob) {
          throw "Blob already set.";
        }

        blob = action.payload.blob;
        if (!blob) {
          throw "Missing blob.";
        }

        blobUrl = URL.createObjectURL(blob);
        yield put(setBlobUrl({documentId, blobUrl}));
      }

      // Actions processed by this saga require access to the document's content, so let's download it if necessary.
      const hasFile = (yield select(getDocument(documentId))).has_file !== false;
      if (!blob && hasFile && ACTION_TYPES_THAT_REQUIRE_CONTENT.includes(action.type)) {
        blob = yield downloadDocument({documentId}, action);
        if (blob) {
          blobUrl = URL.createObjectURL(blob);
          yield put(setBlobUrl({documentId, blobUrl}));

          yield* subSagaChannels.map(subSagaChan => put(subSagaChan, {blob}))
        }
      }

      if (action.type === saveDocument.type) {
        // Process save action, but only if the blob is available.
        if (blob) {
          yield saveBlobOfDocument(blob)(action);
        } else {
          // Ignore.
        }
      } else if (action.type === uploadDocument.type) {
        // Process upload action only if there is no upload task already running.
        if (!documentUploadTask || !documentUploadTask.isRunning()) {
          documentUploadTask = yield fork(performDocumentUpload, {
            documentId,
            blob,
            documentUploadSlotsSemaphore,
          }, action);
        }
      }

      // In case that the content shall be displayed, inform the parsed blob provider so that it can convert the blob
      // appropriately.
      if (action.type === viewDocument.type || action.type === hideDocument.type || action.type === needParsedDocument.type) {
        yield put(parsedBlobProviderChan, {needed: action.type !== hideDocument.type});
      }

      // Try to delete document draft.
      if (action.type === deleteDocumentDraft.type) {
        // A running upload needs to be cancelled.
        if (documentUploadTask) {
          // Cancel upload task.
          yield cancel(documentUploadTask);

          // Wait for upload task to stop.
          while (true) {
            if (!documentUploadTask.isRunning()) {
              break;
            }
            yield delay(100);
          }
          if (documentUploadTask.isCancelled()) {
            documentUploadTask = undefined;
          }
        }

        // Only if there is no upload task can we delete the document draft.
        if (!documentUploadTask) {
          yield put(deletedDocumentDraft(action.payload));
        }
      }
    }
  } finally {
    console.debug(`Forgetting blob of document ${documentId}...`);

    subSagaChannels.forEach(subSagaChan => subSagaChan.close());

    // Revoke blob URL.
    if (blobUrl) {
      URL.revokeObjectURL(blobUrl);
    }
  }
};

/**
 * Saga that manages all actions that involve contents of documents.
 *
 * For each document, an individual saga is started that organizes retrieval of the document's content.
 */
function* documentContentSagasManager() {
  // We do not want to miss any relevant event.
  const documentActionsChan = yield actionChannel(ACTION_TYPES_THAT_AFFECT_CONTENT);

  // Manage limited number of upload slots via a channel that serves as semaphore.
  const documentUploadSlotsSemaphore = yield call(channel);
  for (let i = 0; i < UPLOAD_SLOTS; i++) {
    yield put(documentUploadSlotsSemaphore, true);
  }

  const documentSagas = {};
  while (true) {
    const action = yield take(documentActionsChan);
    const {documentId} = action.payload;

    // Process document id change.
    if (action.type === changeDocumentId.type) {
      const {newDocumentId} = action.payload;
      if (documentSagas[documentId] !== undefined) {
        if (documentSagas[newDocumentId] === undefined) {
          documentSagas[newDocumentId] = documentSagas[documentId];
          delete documentSagas[documentId];
          yield put(documentSagas[newDocumentId].chan, action);
        } else {
          throw `Change of document id from ${documentId} to ${newDocumentId} leads to a conflict.`;
        }
      }
      continue;
    }

    // All remaining actions require a documentId.
    if (documentId === undefined) {
      throw `Cannot process action without documentId.`;
    }

    // If document has been deleted, cancel corresponding tasks.
    let documentSaga = documentSagas[documentId];
    if (action.type === deletedDocument.type && documentSaga) {
      yield cancel(documentSaga.task);
      delete documentSagas[documentId];
      continue;
    }

    // Start document-specific saga if necessary.
    if (!documentSaga) {
      const chan = yield call(channel);
      documentSagas[documentId] = documentSaga = {
        chan,
        task: yield fork(createDocumentContentSaga({documentId, documentUploadSlotsSemaphore}), chan),
      };
    }

    // Forward action to document's individual saga.
    yield put(documentSagas[documentId].chan, action);
  }
}

function* watchViewDocument(action) {
  const {documentId} = action.payload;
  yield put(showView({type: 'viewDocument', id: documentId}));
}

function* watchHideDocument(action) {
  const {documentId} = action.payload;
  yield put(hideView({type: 'viewDocument', id: documentId}));
}

function* watchReplyToDocument(action) {
  const {documentId} = action.payload;
  const {name} = yield select(getDecrypted(getDocument(documentId)));

  // Try to determine the message that we reply to, but only for a short period of time so that we do not annoy the user.
  let content;
  if ((yield select(canViewInline(documentId))) && (yield select(isTextDocument(documentId)))) {
    yield put(needParsedDocument({documentId}));

    content = (yield race({
      content: call(function* () {
        let content;
        while (true) {
          content = yield select(getDecrypted(getDocumentContent(documentId)));
          if (content !== undefined && !Number.isNaN(content)) {
            return content;
          }
          yield delay(50);
        }
      }),
      timeout: delay(1000),
    })).content;
  }

  // fill message writer with data and show it
  let $message_writer = $('#document-create-modal');
  $message_writer.find('form').trigger('reset');
  $message_writer.find('input,textarea').val();
  if (!Number.isNaN(name)) {
    $message_writer.find('input[name="description"]').val(`Re: ${name}`);
  }

  if (content) {
    const focusBeginning = function () {
      $(this).setCursorPosition(0);
      $(this).scrollTop(0);
      $(this).off('focus', focusBeginning);
    };

    $message_writer.find('textarea[name="content"]').val(
      '\n\nAntwort auf:\n' + content.replace(/(^|\n)/g, '$1> ')
    ).on('focus', focusBeginning);
  }
  $message_writer.modal('show');
  //yield put(showView({type: 'writeDocument', id: '', messageId: documentId}));

  yield put(hideView({type: 'viewDocument', id: documentId}));
}

const watchDeleteDocument = createSimpleAPICall({
  apiName: 'destroyDocument',
  lookupField: 'documentId',
  createPayload: action => ({id: action.payload.documentId, case_id: action.payload.caseId}),
  successAction: ({id, case_id}) => deletedDocument({documentId: id, caseId: case_id}),
});

function* watchDeletedDocumentDraft(action) {
  const {documentId, caseId} = action.payload;
  yield put(deletedDocument({documentId, caseId}));
}

function* autoUpdateCase() {
  while (true) {
    const action = yield take([deletedDocument, uploaded]);

    let caseId = action.payload.caseId;
    if (caseId === undefined) {
      caseId = (yield select(getDocument(action.payload.documentId))).case;
    }

    // We can only update the case when it is known.
    if (caseId === undefined) {
      continue;
    }

    // Try to update document counter immediately.
    const {documents_count} = yield select(getCase(caseId));
    if (documents_count !== undefined) {
      if (action.type === deletedDocument.type) {
        if (documents_count > 0) {
          yield put(updateCase({id: caseId, documents_count: documents_count - 1}));
        }
      } else if (action.type === uploaded.type) {
        yield put(updateCase({id: caseId, documents_count: documents_count + 1}));
      }
    }

    // Schedule case metadata update.
    yield put(fetchCase({id: caseId}));
  }
}

function* autoUpdatePinnedListings() {
  while (true) {
    const action = yield take([updatePinDocument, deletedDocument]);

    const documentId = action.payload.id;

    let caseId = action.payload.caseId;
    if (caseId === undefined) {
      caseId = (yield select(getDocument(documentId))).case;
    }

    // We can only update the case when it is known.
    if (caseId === undefined) {
      continue;
    }

    // Update pinned listing.
    // TODO: Consider caseId as soon as we support multiple pinned listings.
    yield put(updateListing({id: 'pinned', count: undefined}));
  }
}

export default function* documentSaga() {
  yield takeEvery(markDocumentRead, watchMarkDocumentRead);
  yield takeEvery(markDocumentUnread, watchMarkDocumentUnread);
  yield takeEvery(pinDocument, watchPinDocument);
  yield takeEvery(unpinDocument, watchUnpinDocument);
  yield takeEvery(modifyAndPersistDocument, watchModifyAndPersist);
  yield takeEvery(viewDocument, watchViewDocument);
  yield takeEvery(hideDocument, watchHideDocument);
  yield takeEvery(replyToDocument, watchReplyToDocument);
  yield takeEvery(deleteDocument, watchDeleteDocument);
  yield takeEvery(deletedDocumentDraft, watchDeletedDocumentDraft);

  yield spawn(autoMarkRead);
  yield spawn(documentContentSagasManager);
  yield spawn(autoUpdateCase);
  yield spawn(autoUpdatePinnedListings);
}
