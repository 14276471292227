import React from 'react';

import {forEachMatchingElement, uimanager} from 'ui-manager';
import {Tooltip} from "@mui/material";
import {WrapDOM} from "../components/ui-tabs";
import {getOrCreateRoot} from "../utils/renderer-utils";
import {Providers} from "../ui/providers";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-toggle='tooltip']", node => {
    node.addEventListener('mouseenter', () => {
      // For any reason, mouseenter fires more than once despite once flag set.
      if (node.processed) {
        return;
      }
      node.processed = true;

      const placement = node.dataset.placement;
      const title = node.getAttribute('title');
      node.removeAttribute('title');

      const wrapper = document.createElement('span');
      while (node.firstChild) {
        wrapper.appendChild(node.firstChild);
      }

      const root = getOrCreateRoot(node);
      root.render(
        <Providers>
          <Tooltip title={title} placement={placement} arrow>
            <span>
              <WrapDOM node={wrapper} span/>
            </span>
          </Tooltip>
        </Providers>
      );

      node.removeAttribute('data-toggle');
    }, {once: true});
  })
);
