import React, {useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {PersonName} from "./person";
import {
  useAddressesPerson,
  useAddressesPersonLink,
  useDefaultTemplateByType
} from "../../features/addresses/addresses-hooks";
import {Box} from "@mui/material";
import {SimpleRow, SimpleRows} from "./layout";
import {OwnPersonLinkForm} from "./forms/OwnPersonLinkForm";
import {PersonRelatedPersonLinksPanel} from "./PersonRelatedPersonLinksPanel";
import {PersonLinkPanel} from "./PersonLinkPanel";
import {PersonLinkForm} from "./forms/PersonLinkForm";


export function PersonDialog({id, open, onClose, onEdit, onAdd, onShowPerson, mostRecentlyAddedPerson, readOnly, onShowLists}) {
  const {birthday, notes, iban, bic, vat_id, tax_id, web, mavo_username, default_person_link, database, isDeleted} = useAddressesPerson({id, observe: true});
  const {location} = useAddressesPersonLink({id: default_person_link, observe: true});

  const defaultSalutationTemplate = useDefaultTemplateByType({databaseId: database, type: 'salutation'});
  const defaultOwnAddressTemplate = useDefaultTemplateByType({databaseId: database, type: 'address', excludeTemplatesWithTwoPartners: true});
  const defaultPersonLinkAddressTemplate = useDefaultTemplateByType({databaseId: database, type: 'address'});

  const [selectedPersonLink, setSelectedPersonLink] = useState(null);
  const [editOwnPersonLink, setEditOwnPersonLink] = useState(null);
  const [editPersonLink, setEditPersonLink] = useState(null);

  const [mostRecentlyAddedOwnPersonLink, setMostRecentlyAddedOwnPersonLink] = useState(null);
  const {location: mostRecentlyAddedLocation} = useAddressesPersonLink({id: mostRecentlyAddedOwnPersonLink, observe: true});

  useEffect(() => {
    setSelectedPersonLink(default_person_link);
  }, [default_person_link])

  useEffect(() => {
    if (isDeleted) {
      onClose();
    }
  }, [isDeleted])

  const addLocation = () => setEditOwnPersonLink(-1);

  const ref = useRef();

  return (
    <div ref={ref}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby={id}
        maxWidth='lg'
        container={ref.current}
        fullWidth
      >
        <DialogTitle id={id}>
          <PersonName id={id} showGender/>
        </DialogTitle>

        <DialogContent>
          <Box sx={{flex: 1, display: 'flex', flexDirection: 'row', mx: -3}}>
            <PersonRelatedPersonLinksPanel
              id={id}
              onViewContact={setSelectedPersonLink}
              selectedPersonLink={selectedPersonLink}
              sx={{flex: 1}}
              onAddLocation={!readOnly ? addLocation : null}
              onAddPartner={!readOnly ? () => setEditPersonLink(-1) : null}
              readOnly={readOnly}
            />
            {selectedPersonLink ? (
              <PersonLinkPanel
                id={selectedPersonLink}
                contextPersonId={id}
                sx={{flex: 2, background: '#ddd'}}
                topButtonsSx={{whiteSpace: 'nowrap'}}
                onShowPerson={onShowPerson}
                onHide={() => setSelectedPersonLink(null)}
                readOnly={readOnly}
                showButtons
                showDefault
                onShowLists={onShowLists ? (
                  () => {
                    onShowLists(selectedPersonLink);
                    onClose();
                  }
                ) : null}
              />
            ) : null}
            {editPersonLink !== null ? (
              <PersonLinkForm
                editPersonLink={editPersonLink}
                onClose={() => setEditPersonLink(null)}
                onAddLocation={!readOnly ? addLocation : null}
                onAddPerson={!readOnly ? onAdd : null}
                initialData={{database, location, parent_person: id, salutation_template: defaultSalutationTemplate, address_template: defaultPersonLinkAddressTemplate}}
                databaseId={database}
                mostRecentlyAddedLocation={mostRecentlyAddedLocation}
                mostRecentlyAddedPerson={mostRecentlyAddedPerson}
                onCreate={setSelectedPersonLink}
                title={editPersonLink === -1 ? "Partner hinzufügen" : undefined}
              />
            ) : null}
            {editOwnPersonLink !== null ? (
              <OwnPersonLinkForm
                editPersonLink={editOwnPersonLink}
                onClose={() => setEditOwnPersonLink(null)}
                initialData={{database, location: {database}, parent_person: id, child_person: id, salutation_template: defaultSalutationTemplate, address_template: defaultOwnAddressTemplate}}
                databaseId={database}
                onCreate={(id) => {
                  setMostRecentlyAddedOwnPersonLink(id);
                  setSelectedPersonLink(id);
                }}
              />
            ) : null}
          </Box>

          <Grid container spacing={2} sx={{mt: 1}}>
            {birthday && (
              <SimpleRows
                Component={Grid}
                item
                md={4}
                xs={12}
                name="Persönliche Daten"
              >
                <SimpleRow
                  name="Geburtstag"
                  value={birthday}
                />
              </SimpleRows>
            )}

            {(iban || bic) && (
              <SimpleRows
                Component={Grid}
                item
                md={4}
                xs={12}
                name="Bankverbindung"
              >
                <SimpleRow
                  name="IBAN"
                  value={iban}
                />
                <SimpleRow
                  name="BIC"
                  value={bic}
                />
              </SimpleRows>
            )}

            {(vat_id || tax_id) && (
              <SimpleRows
                Component={Grid}
                item
                md={4}
                xs={12}
                name="Steuer"
              >
                <SimpleRow
                  name="Umsatzsteuer-ID"
                  value={vat_id}
                />
                <SimpleRow
                  name="Steuernummer"
                  value={tax_id}
                />
              </SimpleRows>
            )}

            {(web || mavo_username) && (
              <SimpleRows
                Component={Grid}
                item
                md={4}
                xs={12}
                name="Internet"
              >
                <SimpleRow
                  name="Webseite"
                  value={web}
                />
                <SimpleRow
                  name="Mavo-Nutzername"
                  value={mavo_username}
                />
              </SimpleRows>
            )}

            {(notes) && (
              <SimpleRows
                Component={Grid}
                item
                xs={12}
                name="Notizen"
                sx={{whiteSpace: 'pre-line'}}
              >
                {notes}
              </SimpleRows>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          {onEdit && (
            <Button
              color="primary"
              onClick={onEdit}
            >
              Person bearbeiten
            </Button>
          )}
          <Button
            color="inherit"
            onClick={onClose}
          >
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
