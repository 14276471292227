import {Dialog} from "../layout/modal/Dialog";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";

export default ({show, title, message, confirmCaption, cancelCaption, color, onConfirm, onCancel}) => !show ? null : (
  <Dialog
    aria-labelledby='confirmation-dialog-title'
    open
    onClose={onCancel}
    fullScreen={false}
  >
    <DialogTitle id='confirmation-dialog-title'>{title}</DialogTitle>
    <DialogContent style={{whiteSpace: 'pre-wrap'}}>{message}</DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="inherit">{cancelCaption}</Button>
      <Button onClick={onConfirm} color={color || 'primary'} autoFocus>{confirmCaption}</Button>
    </DialogActions>
  </Dialog>
);
