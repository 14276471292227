import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {};

const decryptedEntitySlice = createSlice({
  name: 'decryptedEntities',
  initialState,
  reducers: {
    setDecryptedEntities(state, action) {
      const {entities, created} = action.payload;
      Object.entries(entities).forEach(([entityType, items]) => {
        // Merge in entities into potentially existing map.
        if (state[entityType] === undefined) {
          state[entityType] = {
            byId: {},
            createdIds: [],
            deletedIds: [],
          };
        }

        const entityState = state[entityType].byId;
        Object.entries(items).forEach(([key, value]) => {
          if (created) {
            state[entityType].createdIds.push(key);
          }

          if (entityState[key] === undefined || !value) {
            entityState[key] = value;
            if (value === null && !state[entityType].deletedIds.includes(key)) {
              state[entityType].deletedIds.push(key);
            }
            return;
          }

          if (!_.isEqual(entityState[key], value)) {
            entityState[key] = {
              ...entityState[key],
              ...value,
            };
          }
        });
      });
    },
  },
});

export const {
  setDecryptedEntities,
} = decryptedEntitySlice.actions;

export const registerDecryptedEntityObserver = createAction('decryptedEntity/registerObserver');
export const unregisterDecryptedEntityObserver = createAction('decryptedEntity/unregisterObserver');

export const deleteDecryptedEntity = (entityType, id) =>
  setDecryptedEntities({entities: {[entityType]: {[id]: null}}});

const reducers = {
  decryptedEntities: decryptedEntitySlice.reducer,
};
export default reducers;

export const getDecryptedEntitiesState = (state) => state.decryptedEntities;

const emptyCreatedIds = [];
export const getCreatedEntityIds = createSelector(
  getDecryptedEntitiesState,
  (entities) => _.memoize((entityType) => (
    entities?.[entityType]?.createdIds || emptyCreatedIds
  )),
);

const emptyDecryptedEntity = {};
const deletedDecryptedEntity = {isDeleted: true};

export const getDecryptedEntity = createSelector(
  getDecryptedEntitiesState,
  (entities) => _.memoize((entityType) => _.memoize((id) => {
    if (entities?.[entityType]?.deletedIds?.includes(id)) {
      return deletedDecryptedEntity;
    }
    return entities?.[entityType]?.byId?.[id] || emptyDecryptedEntity;
  })),
);
