import {
  TEMPLATE_ABBREVIATIONS, TEMPLATE_REQUIRED_FIELDS,
  useAddressesLocation,
  useAddressesPerson, useAddressesPersonLink, useAddressesTemplate, useTemplateRendererGetter,
  useTemplatesByType
} from "../../../features/addresses/addresses-hooks";
import React from "react";
import YupField from "../../../packages/react-hook-form-mui-yup-helpers/yup-field";
import {FormChoicesField} from "../../../packages/react-hook-form-mui-form-fields";
import {useFormContext} from "react-hook-form";
import Box from "@mui/material/Box";
import _ from "lodash";
import {getAddressesTemplateGetter} from "../../../features/addresses";
import {useSelector} from "react-redux";
import {Card, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Chip} from "@mui/material";

export function TemplateChoiceYupField({name, databaseId, initialData, type, templateAbbreviations=TEMPLATE_ABBREVIATIONS, ...props}) {
  const {formState: {errors, isSubmitting}, watch, setValue} = useFormContext();

  const currentPersonLinkData = useAddressesPersonLink({id: watch('id')});

  const parentPersonId = watch('parent_person') || currentPersonLinkData?.parent_person || initialData?.parent_person;
  const childPersonId = watch('child_person') || currentPersonLinkData?.child_person || initialData?.child_person;
  const locationId = watch('location') || currentPersonLinkData?.location || initialData?.location;

  let templateIds = useTemplatesByType({databaseId, type, is_global: true, excludeTemplatesWithTwoPartners: parentPersonId === childPersonId});
  const getTemplate = useSelector(getAddressesTemplateGetter);

  const parent_person = useAddressesPerson({id: parentPersonId});
  const child_person = useAddressesPerson({id: childPersonId});
  const location = useAddressesLocation({id: locationId});
  const personLink = {
    parent_person: _.isObject(parentPersonId) ? parentPersonId : parent_person,
    child_person: _.isObject(childPersonId) ? childPersonId : child_person,
    location: _.isObject(locationId) ? locationId : location,
    type: watch('type'),
  };

  // Exclude templates that depend on required fields.
  templateIds = templateIds?.filter((templateId) => {
    const template = getTemplate(templateId)?.template;
    const requiredFields = TEMPLATE_REQUIRED_FIELDS[template];
    if (!requiredFields) {
      return true;
    }
    for (const fieldName of requiredFields) {
      if (!_.get(personLink, fieldName)) {
        return false;
      }
    }
    return true;
  });

  const getTemplateRenderer = useTemplateRendererGetter();

  // Make sure that the currently assigned template is displayed even if it is a non-global one.
  const currentTemplateId = currentPersonLinkData?.[name];
  if (currentTemplateId && !templateIds?.includes(currentTemplateId)) {
    templateIds = [...templateIds, currentTemplateId];
  }

  const renderItem = (id, isSelectedValue) => {
    const template = getTemplate(id)?.template;
    const templateAbbreviation = TEMPLATE_ABBREVIATIONS[template];
    return (
      <>
        <Box component="span" sx={{whiteSpace: 'pre', pr: 1}}>
          {getTemplateRenderer(id)(personLink)}
        </Box>
        {templateAbbreviation ? (
          <>
            <Chip label={templateAbbreviation} size="small"/>
          </>
        ) : !isSelectedValue && (
          <Box component="span">
            <Card>
              <CardContent style={{whiteSpace: 'pre', color: '#adadad'}}>
                <Typography variant="subtitle1">
                  Vorlage
                </Typography>
                <small>
                  {template}
                </small>
              </CardContent>
            </Card>
          </Box>
        )}
      </>
    );
  };

  const items = templateIds?.length > 0 ? Object.assign(...templateIds?.map((id) =>
    ({[id]: renderItem(id)})
  )) : {};

  return (
    <YupField
      name={name}
      Component={FormChoicesField}
      items={items}
      renderValue={(id) => renderItem(id, true)}
      {...props}
    />
  );
}
