import React from "react";
import {connect} from "react-redux";
import {
  deleteDocument,
  getFilteredSelectedDocumentIdsForListing,
  markDocumentRead,
  markDocumentUnread,
  saveDocument
} from "../../../features/entities/document";
import {requireConfirmation} from "../../../features/ui/confirmation";
import {bulkListingAction} from "../../../features/ui/listing";
import DocumentRowMenu from "./document-row-menu-view";

const filterRead = document => document.viewer_read;
const filterUnread = document => !document.viewer_read;
const filterCanDelete = document => document.can_delete;
const filterCanSave = document => document.has_file || true;  // All special documents support save as well.

const filterEverything = _ => true;

const mapStateToProps = (state, {listingId}) => {
  return {
    menuType: 'row',
    readDocumentIds: getFilteredSelectedDocumentIdsForListing(filterRead)(listingId)(state),
    unreadDocumentIds: getFilteredSelectedDocumentIdsForListing(filterUnread)(listingId)(state),
    deletableDocumentIds: getFilteredSelectedDocumentIdsForListing(filterCanDelete)(listingId)(state),
    savableDocumentIds: getFilteredSelectedDocumentIdsForListing(filterCanSave)(listingId)(state),
    allDocumentIds: getFilteredSelectedDocumentIdsForListing(filterEverything)(listingId)(state),
  };
};

const mapDispatchToProps = (dispatch, {listingId, caseId}) => {
  return {
    createMarkRead: documentIds => () => {
      dispatch(bulkListingAction({
        listingId,
        ids: documentIds,
        actionTemplate: markDocumentRead({caseId}),
        actionIdField: 'documentId',
        progressType: markDocumentRead.type,
        progressMessageTemplate: "{{SUCCESSFUL}} von {{TOTAL}} Dokumenten als gelesen markiert",
        totalFailureMessageTemplate: "{{TOTAL}} Dokumente nicht als gelesen markiert",
      }));
    },
    createMarkUnread: documentIds => () => {
      dispatch(bulkListingAction({
        listingId,
        ids: documentIds,
        actionTemplate: markDocumentUnread({caseId}),
        actionIdField: 'documentId',
        progressType: markDocumentUnread.type,
        progressMessageTemplate: "{{SUCCESSFUL}} von {{TOTAL}} Dokumenten als ungelesen markiert",
        totalFailureMessageTemplate: "{{TOTAL}} Dokumente nicht als ungelesen markiert",
      }));
    },

    createSave: documentIds => () => {
      dispatch(requireConfirmation({
        title: "Alle ausgewählten Dokumente herunterladen?",
        message: `Mit diesem Vorgang werden nacheinander ${documentIds.length} einzelne Downloads gestartet.\n\nBitte beachten Sie, dass einige Webbrowser Mehrfach-Downloads unterdrücken und Sie diese Aktion ggf. separat freigeben müssen. Vergewissern Sie sich nach Abschluss der Downloads, dass alle ausgewählten Dokumente erfolgreich auf Ihrem Computer gespeichert wurden.`,
        color: 'primary',
        confirmCaption: `${documentIds.length} Dokumente herunterladen`,
        confirmAction: bulkListingAction({
          listingId,
          ids: documentIds,
          actionTemplate: saveDocument({}),
          actionIdField: 'documentId',
          progressType: saveDocument.type,
          progressMessageTemplate: "{{SUCCESSFUL}} von {{TOTAL}} Dokumenten heruntergeladen",
          totalFailureMessageTemplate: "{{TOTAL}} Dokumente nicht heruntergeladen",
        }),
      }));
    },

    createPerformDeletion: documentIds => () => {
      dispatch(requireConfirmation({
        title: "Dokumente wirklich löschen?",
        message: "Mit diesem Vorgang werden alle ausgewählten Dokumente unwiderruflich gelöscht.",
        color: 'secondary',
        confirmCaption: `${documentIds.length} Dokumente löschen`,
        confirmAction: bulkListingAction({
          listingId,
          ids: documentIds,
          actionTemplate: deleteDocument({caseId}),
          actionIdField: 'documentId',
          progressType: deleteDocument.type,
          progressMessageTemplate: "{{SUCCESSFUL}} von {{TOTAL}} Dokumenten gelöscht",
          totalFailureMessageTemplate: "{{TOTAL}} Dokumente nicht gelöscht",
        }),
      }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  (stateProps, dispatchProps, ownProps) => {
    const {readDocumentIds, unreadDocumentIds, deletableDocumentIds, savableDocumentIds, allDocumentIds, ...otherStateProps} = stateProps;
    const {createMarkRead, createMarkUnread, createPerformDeletion, createSave, ...otherDispatchProps} = dispatchProps;
    return {
      ...ownProps,
      ...otherStateProps,

      markRead: unreadDocumentIds.length > 0 ? createMarkRead(unreadDocumentIds) : undefined,
      markUnread: readDocumentIds.length > 0 ? createMarkUnread(readDocumentIds) : undefined,

      performDeletion: deletableDocumentIds.length > 0 ? createPerformDeletion(deletableDocumentIds) : undefined,

      save: (savableDocumentIds.length > 0 && savableDocumentIds.length === allDocumentIds.length) ? createSave(savableDocumentIds) : undefined,

      ...otherDispatchProps,
    };
  },
)(DocumentRowMenu);
