import React from "react";
import {useFormContext} from "react-hook-form";
import {ChoicesField} from "packages/react-hook-form-mui";

export default function FormChoicesField({name, ...props}) {
  const {control, formState: {errors, isSubmitting}} = useFormContext();

  return (
    <ChoicesField
      name={name}
      control={control}
      errors={errors}
      disabled={isSubmitting}
      {...props}
    />
  );
}
