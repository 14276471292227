import React from 'react';
import {createUploadFilesClickEvent} from "./upload-events";

export default (
  {
    isVisible,
    isPermanentlyVisible,
    uploadFiles,
    hideDropzone,
    caption="Dokumente hier hineinziehen",
    buttonCaption="Dateien vom Computer auswählen",
    style,
    ...props
  }
) => {
  return (isVisible ? (
    <tr onDrop={evt => uploadFiles(evt.dataTransfer.files)} style={{borderTopWidth: 0}} {...props}>
      <td colSpan="9" style={{padding: '0px'}} data-sorter="false">
        <div className="dropzone" style={{padding: '10px'}}>
          <div className="dropzone-box" style={{position: 'relative', ...style}}>
            {(isPermanentlyVisible && hideDropzone) ? (
              <a className="close" style={{
                position: 'absolute',
                right: '15px',
                top: '10px'
              }} onClick={hideDropzone}>&times;</a>
            ) : null}
            <div className="dropzone-inner">
              <p className="lead"><span>{caption}</span></p>
              <div className="">
                <p><span>&ndash; oder &ndash;</span></p>
                <button className="btn btn-primary btn-sm documentupload-fileupload-btn" onClick={createUploadFilesClickEvent(uploadFiles)}>
                  {buttonCaption}
                </button>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  ) : null);
};
