import Checkbox from "@mui/material/Checkbox";
import {Box, Chip, createFilterOptions, InputAdornment} from "@mui/material";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {FilterList, Search} from "@mui/icons-material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

function FancyFilterItem({
  selected,
  option,
  active,
  activeChoice,
  onChoose,
  onHover,
  onClick,
  allowSearch,
  searchParam,
  ...props
}) {
  const choose = (choice) => evt => {
    if (onChoose) {
      onChoose(choice);
    }
    if (selected) {
      evt.stopPropagation();
    }
  };

  const {
    label,
    choices,
    choiceLabel,
    id
  } = option;

  let implicitlySelected = false;
  if (allowSearch && id === searchParam) {
    implicitlySelected = true;
  }

  return (
    <li onClick={onClick} {...props} {...{'aria-selected': props['aria-selected'] || (selected && active)}}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        sx={{mr: 1}}
        checked={selected || implicitlySelected}
      />
      {label}
      {(selected || active) ? (
        choices.map((choice) => (
          <Box key={choice} ml={1}>
            <Chip
              label={choiceLabel({
                id,
                choice
              })}
              variant={choice === activeChoice ? "filled" : "outlined"}
              color={selected ? "primary" : "default"}
              clickable
              onClick={choose(choice)}
            />
          </Box>
        ))
      ) : null}
    </li>
  );
}

const defaultFilterOptions = createFilterOptions({
  stringify: ({
    label,
    choices,
    chipLabel,
    choiceLabel,
    id
  }) =>
    `${label} ${Object.values(choices)
      .map((choice) =>
          chipLabel({
            id,
            choice
          }) + ' ' + choiceLabel({
            id,
            choice
          })
      )
      .join(' ')}`,
});

export default React.memo(function FancyFilterField(
  {
    options,
    variant = "standard",
    value,
    filterOptions: propFilterOptions,
    onChange,
    open,
    onOpen,
    onClose,
    placeholder = "Filtern...",
    allowSearch = false,
    searchParam = 'q',
    TextFieldProps,
    ...props
  },
) {
  const [stateValue, setStateValue] = useState([]);
  if (value === undefined) {
    value = stateValue;
  }

  const originalOnChange = onChange;
  onChange = useCallback((event, newValue, reason, details) => {
    if (reason === 'createOption') {
      // Prevent option creation as we use the search query as-is.
      return false;
    } else if (reason === 'removeOption') {
      newValue = value.filter(({id}) => id !== details?.option?.id);
    }

    setStateValue(newValue);

    if (originalOnChange !== undefined) {
      return originalOnChange(event, newValue, reason, details);
    }
  }, [setStateValue, originalOnChange, value]);

  const [stateOpen, setStateOpen] = useState(false);
  if (open === undefined) {
    open = stateOpen;
  }
  const originalOnOpen = onOpen;
  onOpen = useCallback((...args) => {
    setStateOpen(true);
    if (originalOnOpen !== undefined) {
      return originalOnOpen(...args);
    }
  }, [setStateOpen, originalOnOpen]);

  const originalOnClose = onClose;
  onClose = useCallback((...args) => {
    setStateOpen(false);
    if (originalOnClose !== undefined) {
      return originalOnClose(...args);
    }
  }, [setStateOpen, originalOnClose]);

  const [focus, setFocus] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  let currentSearchQuery = '';
  value.filter(({id}) => id === searchParam)
    .forEach(({
      id,
      choice
    }) => {
      currentSearchQuery = choice;
    });

  const filterOptions = propFilterOptions || defaultFilterOptions;

  const [stateOptions, setStateOptions] = React.useState(options);

  useEffect(() => {
    setStateOptions(options);
  }, [options])

  const internalOptions = useMemo(() => {
    return stateOptions.map((option) => {
      const suitableValues = value.filter(({id}) => id === option.id);
      if (suitableValues.length === 1) {
        return {
          ...option,
          choice: suitableValues[0].choice
        };
      } else {
        return option;
      }
    });
  }, [stateOptions, value, currentSearchQuery]);

  const internalValue = useMemo(() => {
    return (
      internalOptions.filter((option) => value.filter((value) => value.id === option.id).length > 0)
    );
  }, [internalOptions, value]);

  const updateChoice = useCallback((option, choice) => {
    option.choice = choice;
    onChange(null, value.map((value) => value.id === option.id ? {
      ...value,
      choice
    } : value));
  }, [onChange, value]);

  useEffect(() => {
    const allowedOptionIds = new Set([searchParam, ...internalOptions.map(({id}) => id)]);
    if (stateValue?.some(({id}) => !allowedOptionIds.has(id))) {
      onChange(null, stateValue.filter(({id}) => allowedOptionIds.has(id)));
    }
  }, [internalOptions, stateValue]);

  const searchProps = useMemo(() => {
    if (!allowSearch) {
      return {};
    }

    return {
      inputValue: currentSearchQuery,
      onInputChange: (event, newInputValue) => {
        if (!newInputValue && (event === null || event?.type === 'blur' || event?.type === 'keydown')) {
          return;
        }
        onChange(event, [
          ...value.filter(({id}) => id !== searchParam),
          ...(
            newInputValue ? (
              [{
                id: searchParam,
                choice: newInputValue
              }]
            ) : []
          ),
        ]);
      },
      handleHomeEndKeys: !currentSearchQuery,
      freeSolo: !!currentSearchQuery || options?.length === 0,
      selectOnFocus: !!currentSearchQuery,
      disableCloseOnSelect: !currentSearchQuery,
      filterOptions: (options, params) => {
        let filtered = [...filterOptions(options, params)];

        const searchQuery = currentSearchQuery;
        if (searchQuery !== '' && !filtered.some(({id}) => id === searchParam)) {
          filtered.push({
            category: "Suche",
            chipLabel: () => searchQuery,
            choice: searchQuery,
            choices: [],
            id: searchParam,
            label: `Suche nach "${searchQuery}"`,
          });
        }

        // if (searchQuery !== '') {
        //   console.log({
        //     filtered,
        //     options,
        //     params
        //   });
        //
        //   if (filtered.length <= 1) {
        //     filtered = [...filtered, ...filterOptions(options, {
        //       ...params,
        //       inputValue: ''
        //     })
        //       .filter(({id}) => id !== searchParam)];
        //     console.log({
        //       options,
        //       params
        //     });
        //   }
        // }

        return filtered;
      },
    };
  }, [allowSearch, currentSearchQuery, onChange, filterOptions, value, searchParam]);

  return (
    <Autocomplete
      multiple

      options={internalOptions}
      filterOptions={filterOptions}

      disableCloseOnSelect
      openOnFocus
      autoHighlight

      noOptionsText="Keine Auswahloptionen"

      getOptionLabel={({
        chipLabel,
        id,
        choice
      }) => chipLabel({
        id,
        choice
      })}
      renderOption={(props, option, {selected}) => (
        <FancyFilterItem
          selected={selected}
          option={option}
          active={activeOption === option}
          activeChoice={option.choice}
          onChoose={(choice) => updateChoice(option, choice)}
          allowSearch={allowSearch}
          searchParam={searchParam}
          {...props}
          onClick={option.id === searchParam ? (event) => {
            onChange(event, [
              ...value.filter(({id}) => id !== searchParam),
            ]);
          } : props.onClick}
        />
      )}

      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...TextFieldProps}
            size="small"
            variant={variant}
            InputProps={{
              ...params?.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  {params?.InputProps?.startAdornment ? (
                    <>
                      <FilterList/>
                      {params?.InputProps?.startAdornment}
                      {allowSearch ? (
                        <Search
                          sx={{
                            ml: 2,
                            mr: -1
                          }}
                        />
                      ) : null}
                    </>
                  ) : allowSearch ? (
                    <Search/>
                  ) : (
                    <FilterList/>
                  )}
                </InputAdornment>
              ),
            }}
            placeholder={placeholder}
          />
        );
      }}

      value={internalValue}
      onChange={(event, value, ...args) =>
        onChange(event, value.map(({
          id,
          choice
        }) => ({
          id,
          choice
        })), ...args)}


      open={open}
      onOpen={onOpen}
      onClose={onClose}

      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}

      onHighlightChange={(evt, option) => setActiveOption(option)}

      {...searchProps}
      {...props}
    />
  );
});

export function serializeFilterValue(value) {
  const params = new URLSearchParams();
  value.forEach(({
    id,
    choice
  }) => params.append(id, choice));
  return params.toString();
}

export function deserializeFilterValue(serializedValue) {
  const params = new URLSearchParams(serializedValue);
  const value = [];
  params.forEach((choice, id) => value.push({
    id,
    choice
  }));
  return value;
}
