import {useFormContext} from "react-hook-form";
import {useAddressesDatabase} from "../../../features/addresses/addresses-hooks";
import React, {useState} from "react";
import Box from "@mui/material/Box";
import {AddressTabPanel} from "../layout";
import {PersonLinksTable} from "../PersonLinksTable";
import {PersonLinkPanel} from "../PersonLinkPanel";

export function PersonLinkSelectorYupField({name, databaseId}) {
  const {formState: {errors, isSubmitting}, watch, setValue} = useFormContext();

  const person_link = watch(name);

  const {person_links} = useAddressesDatabase({id: databaseId});
  const [appliedFilters, setAppliedFilters] = useState([]);

  return (
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'row', minWidth: 500, mx: -3}}>
      <AddressTabPanel
        filterValue={appliedFilters}
        onFilter={setAppliedFilters}
        searchFieldPlaceholder="Kontakt suchen..."
        sx={{minHeight: 'max(50vh, 350px)', flex: 1}}
      >
        <PersonLinksTable
          ids={person_links}
          appliedFilters={appliedFilters}
          selectedPersonLink={person_link}
          onViewContact={(id) => setValue(name, id)}
        />
      </AddressTabPanel>
      {person_link ? (
        <PersonLinkPanel
          id={person_link}
          sx={{flex: 1, background: '#ddd'}}
        />
      ) : null}
    </Box>
  );
}
