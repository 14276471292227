import React from "react";

import DecryptedEntityFormDialog from "../../../features/decryptedEntity/DecryptedEntityFormDialog";
import {useAddressesEntity} from "../../../features/addresses/addresses-hooks";
import {ENTITY_TYPES} from "../../../api/api-schemas";
import {useDecryptedEntityApi} from "../../../features/decryptedEntity/decrypted-entity-hooks";
import {DialogContent, Grid} from "@mui/material";
import {YupField} from "../../../packages/react-hook-form-mui-yup-helpers";

import _ from "lodash";

export default function AddressEntityFormDialog(
  {
    entityName = "Element",
    entityType,
    entityIdFieldName = 'id',
    initialData,
    expandedFields,
    fields,
    entityId = null,
    baseUrl,
    databaseId,
    title,
    deleteCaption,
    deleteConfirmation,
    schema,
    children,
    postChildren,
    renderDefaultChildren,
    excludeDefaultChildren,
    onSubmitSuccessful,
    onCreate,
    onUpdate,
    hideSave,
    readOnly,
    allowDelete,
    ...props
  }
) {
  const open = entityId !== null;

  const {isDeleted, ...currentData} = useAddressesEntity({type: entityType, id: entityId, expandedFields});

  if (fields === undefined) {
    const collectFieldsFromSchema = (schema, prefix='') => {
      let fields = [];

      Object.entries(schema.fields).forEach(([fieldName, field]) => {
        if (fieldName === 'id') {
          return;
        }

        const qualifiedFieldName = prefix + fieldName;

        if (field?.type === 'object' && field?.fields && expandedFields?.includes(qualifiedFieldName)) {
          fields.push(...collectFieldsFromSchema(field, qualifiedFieldName + '.'));
        } else {
          fields.push(qualifiedFieldName);
        }
      });

      return fields;
    };

    fields = collectFieldsFromSchema(schema);
  }

  const idFields = ['id'];
  fields?.forEach(fieldName => {
    const re = /\.([^.]+)$/;
    if (fieldName.match(re)) {
      const idFieldName = fieldName.replace(re, '.id');
      if (!idFields.includes(idFieldName)) {
        idFields.push(idFieldName);
      }
    }
  });

  let data = null;
  if (entityId === -1) {
    data = {database: databaseId, ...initialData};
  } else if (entityId !== null) {
    data = {};

    const addFields = (fields, prefix='') => {
      fields?.forEach(field => {
        const qualifiedFieldName = `${prefix}${field}`;
        data[qualifiedFieldName] = _.get(currentData, qualifiedFieldName);
      });
    };

    addFields(fields);

    idFields?.forEach(field => {
      _.set(data, field, _.get(currentData, field));
    });
  }

  if (!title) {
    title = entityId === -1 ? `${entityName} anlegen` : `${entityName} bearbeiten`;
  }
  if (!deleteCaption) {
    deleteCaption = `${entityName} löschen`;
  }
  if (!deleteConfirmation) {
    deleteConfirmation = `${entityName} wirklich löschen?`;
  }

  const entity = ENTITY_TYPES[entityType];
  const entityApi = useDecryptedEntityApi(entity);

  const save = async (validatedData) => {
    let {id} = validatedData;
    let create = false;

    if (id) {
      await entityApi.patch(
        `${baseUrl}${id}/`,
        validatedData,
      );
    } else {
      create = true;
      const result = await entityApi.post(
        `${baseUrl}`,
        validatedData,
        {
          createEntities: true,
        },
      );

      id = result?.data?.id;
    }

    if (onSubmitSuccessful) {
      onSubmitSuccessful(id);
    }

    if (create && onCreate) {
      onCreate(id);
    }

    if (!create && onUpdate) {
      onUpdate(id);
    }
  };

  if (renderDefaultChildren !== false && !children && !postChildren) {
    renderDefaultChildren = true;
  }

  let fieldNamesToRender;
  if (renderDefaultChildren) {
    fieldNamesToRender = fields?.filter(field => !excludeDefaultChildren?.includes(field));
  }

  return (
    <DecryptedEntityFormDialog
      entityType={entityType}
      entityIdFieldName='id'
      baseUrl={baseUrl}
      open={open}
      data={data}
      submit={!readOnly ? save : null}
      title={title}
      deleteCaption={deleteCaption}
      deleteConfirmation={deleteConfirmation}
      schema={schema}
      hideSave={hideSave || readOnly}
      allowDelete={allowDelete && !readOnly}
      {...props}
    >
      <DialogContent>
      {children}
      {fieldNamesToRender?.length > 0 && (
        <Grid container spacing={2}>
          {fieldNamesToRender.map((field, i) => (
            <Grid
              key={field || i}
              item
              xs={12}
            >
              <YupField name={field}/>
            </Grid>
          ))}
        </Grid>
      )}
      {postChildren}
      </DialogContent>
    </DecryptedEntityFormDialog>
  );
}
