import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Typography from "@mui/material/Typography";
import {Close as CloseIcon} from "@mui/icons-material";

export const DialogTitle = (props => {
  const {children, onClose, disableTypography, sx, ...other} = props;

  let wrappedChildren = children;
  if (!disableTypography) {
    wrappedChildren = (
      <Typography variant="h2">
        {children}
      </Typography>
    );
  }

  return (
    <MuiDialogTitle component="div" sx={{m: 0, p: 2, ...sx}} {...other}>
      {onClose ? (
        <IconButton aria-label="close" size="large" sx={{position: 'absolute', zIndex: 1, right: 8, top: 8, color: 'grey[500]', ml: 6, '& ~ *': {mr: 6}}} onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      ) : null}
      {wrappedChildren}
    </MuiDialogTitle>
  );
});
