import {TEMPLATE_SCHEMA} from "../validation";
import React from "react";
import AddressEntityFormDialog from "./AddressEntityFormDialog";
import {Collapse, FormControl, FormLabel, Grid} from "@mui/material";
import {YupField} from "../../../packages/react-hook-form-mui-yup-helpers";
import {useFormContext} from "react-hook-form";
import Box from "@mui/material/Box";
import {useTemplateExample} from "../template";

function TemplateFormContent() {
  const {watch} = useFormContext();

  const template = watch('template');
  const example = useTemplateExample({template});

  return (
    <>
      <Grid item xs={12} pb={2}>
        <YupField name="type"/>
      </Grid>
      <Grid item xs={12} pb={2}>
        <YupField name="template"/>
      </Grid>
      <Collapse in={!!template} component={Grid} item xs={12} pb={2}>
        <FormControl>
          <FormLabel>
            Beispiel
          </FormLabel>
          {example ? (
            <Box sx={{whiteSpace: 'pre'}}>
              {example}
            </Box>
          ) : (
            <>
              <i>Diese Vorlage führt zu einer leeren Ausgabe.</i>
            </>
          )}
        </FormControl>
      </Collapse>
      <Grid item xs={12} pb={2}>
        <YupField name="is_global"/>
      </Grid>
    </>
  );
}
export function TemplateForm({
  databaseId,
  editTemplate,
  onClose,
  ...props
}) {
  return (
    <AddressEntityFormDialog
      baseUrl="/api/addresses/templates/"
      databaseId={databaseId}
      id='edit-template'
      entityId={editTemplate}
      entityName="Vorlage"
      entityType="addresses_template"
      onClose={onClose}
      schema={TEMPLATE_SCHEMA}
      renderDefaultChildren
      excludeDefaultChildren={[
        'type',
        'template',
        'is_global',
      ]}
      allowDelete
      {...props}
    >
      <Grid container>
        <TemplateFormContent/>
      </Grid>
    </AddressEntityFormDialog>
  );
}
