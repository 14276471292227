import {
  useAddressesLocation,
  useAddressesPerson,
  useAddressesPersonLink, useRenderedPersonLinkData
} from "../../features/addresses/addresses-hooks";
import React from "react";
import {Chip, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import {SimpleRow} from "./layout";
import {Card, CardContent} from "@mui/material";
import VCard from 'vcard-creator';

export function AlphabeticalPersonName({id}) {
  const {name, first_name, title_prefix, title_suffix, type} = useAddressesPerson({id});

  let formattedName = (
    <>
      {name}
    </>
  );

  if (first_name) {
    formattedName = (
      <>
        {formattedName}, {first_name}
      </>
    );
  }

  if (title_prefix) {
    formattedName = (
      <>
        {formattedName}, {title_prefix}
      </>
    );
  }

  if (title_suffix) {
    formattedName = (
      <>
        {formattedName}{title_prefix ? null : ','} {title_suffix}
      </>
    );
  }

  return formattedName;
}

export function getPersonName({name, first_name, title_prefix, title_suffix, type, showGender}) {
  let formattedName = name || '';

  if (first_name) {
    formattedName = `${first_name} ${formattedName}`;
  }

  if (title_prefix) {
    formattedName = `${title_prefix} ${formattedName}`;
  }

  if (title_suffix) {
    formattedName = `${formattedName} ${title_suffix}`;
  }

  if (showGender && type) {
    const gender = type === 'male' ? "Herr" : type === 'female' ? "Frau" : type === 'neutral' ? "" : type === 'company' ? "Firma" : type;
    if (gender) {
      formattedName = `${gender} ${formattedName}`;
    }
  }

  return formattedName;
}

export function usePersonName({id, ...props}) {
  const person = useAddressesPerson({id});
  return getPersonName({...person, ...props});
}

export const PersonName = usePersonName;

export function usePersonLinkName({id, wrap}) {
  const {parent_person: parentPersonId, child_person: childPersonId, type} = useAddressesPersonLink({
    id,
    observe: true
  });

  const childPersonName = usePersonName({id: childPersonId});
  const parentPersonName = usePersonName({id: parentPersonId});

  let personName = childPersonName;

  if (type) {
    personName = `${personName} (${type})`;
  }

  if (parentPersonId !== childPersonId) {
    if (wrap) {
      return (
        <>
          {personName}<br/>
          {parentPersonName}
        </>
      );
    } else {
      personName = `${personName}, ${parentPersonName}`;
    }
  }

  return personName;
}

export function PersonAddressBox({id, contextPersonId, alphabetical, onShowPerson}) {
  const {
    parent_person: parentPersonId,
    child_person: childPersonId,
    location: locationId,
    type,
    phone,
    phone2,
    mobile,
    fax,
    email,
  } = useAddressesPersonLink({id, observe: true});
  const {address_line, zip, city, po_box, po_box_zip, notes} = useAddressesLocation({id: locationId, observe: true});

  return (
    <>
      {alphabetical ? (
        <>
          {parentPersonId !== childPersonId && (
            <Box sx={{color: '#aaaaaa'}}>
              <AlphabeticalPersonName id={parentPersonId}/>
            </Box>
          )}
          <Box>
            <AlphabeticalPersonName id={childPersonId}/>
            {type && (
              <>
                {' '}<Chip size="small" label={type}/>
              </>
            )}
          </Box>
        </>
      ) : (
        <>
          <Box>
            {childPersonId !== contextPersonId && onShowPerson ? (
              <Box sx={{cursor: 'pointer'}} onClick={() => onShowPerson(childPersonId)} component="span">
                <PersonName id={childPersonId}/>
              </Box>
            ) : (
              <PersonName id={childPersonId}/>
            )}
            {type && (
              <>
                {' '}<Chip size="small" label={type}/>
              </>
            )}
          </Box>
          {parentPersonId !== childPersonId && (
            <Box sx={{color: '#aaaaaa'}}>
              {parentPersonId !== contextPersonId && onShowPerson ? (
                <Box sx={{cursor: 'pointer'}} onClick={() => onShowPerson(parentPersonId)} component="span">
                  <PersonName id={parentPersonId}/>
                </Box>
              ) : (
                <PersonName id={parentPersonId}/>
              )}
            </Box>
          )}
        </>
      )}
      <Box>
        {address_line}
      </Box>
      <Box>
        <Box>
          {zip} {city}
        </Box>

        {(po_box || po_box_zip) && (
          (po_box && po_box_zip) ? (
            <Box>
              Postfach {po_box}, PLZ {po_box_zip}
            </Box>
          ) : po_box ? (
            <Box>
              Postfach {po_box}
            </Box>
          ) : null
        )}
      </Box>
    </>
  );
}

export function PersonAddress({id, children, onShowPerson, contextPersonId}) {
  const {
    parent_person: parentPersonId,
    child_person: childPersonId,
    location: locationId,
    type,
    phone,
    phone2,
    mobile,
    fax,
    email,
  } = useAddressesPersonLink({id, observe: true});
  const {address_line, zip, city, po_box, po_box_zip, notes} = useAddressesLocation({id: locationId, observe: true});

  const {renderedSalutation, renderedAddress} = useRenderedPersonLinkData({personLinkId: id});

  return (
    <Box sx={{p: 1}}>
      <Card>
        <CardContent>
          <Grid container spacing={0}>
            <Grid
              item
              md={8}
              xs={12}
            >
              <SimpleRow
                name="Anrede"
                value={renderedSalutation}
                style={{whiteSpace: 'pre'}}
              />
              <SimpleRow
                Component={Box}
                name="Anschrift"
                value={renderedAddress}
                style={{whiteSpace: 'pre'}}
              />
            </Grid>

            <SimpleRow
              Component={Grid}
              item
              md={4}
              xs={12}
              name="Notiz"
              value={notes}
            />
          </Grid>

          <Grid container spacing={0}>
            <SimpleRow
              Component={Grid}
              item
              xs={12}
              name="E-Mail"
              value={email}
            />
            <SimpleRow
              Component={Grid}
              item
              md={4}
              xs={12}
              name="Telefon"
              value={phone}
            />
            <SimpleRow
              Component={Grid}
              item
              md={4}
              xs={12}
              name="Telefon"
              value={phone2}
            />
            <SimpleRow
              Component={Grid}
              item
              md={4}
              xs={12}
              name="Mobil"
              value={mobile}
            />
            <SimpleRow
              Component={Grid}
              item
              md={4}
              xs={12}
              name="Fax"
              value={fax}
            />
          </Grid>

          {children}
        </CardContent>
      </Card>
    </Box>
  );
}

export function getPersonLinkType(parentPersonId, childPersonId, contextPersonId) {
  if (parentPersonId === childPersonId) {
    return "Adresse";
  } else if (!contextPersonId) {
    return "Partner";
  } else if (parentPersonId === contextPersonId) {
    return "Partner an Adresse";
  } else {
    return "Partner an Fremdadresse";
  }
}

export const PERSON_FILTER_OPTIONS = [
  {
    category: null,
    id: 'type',
    label: 'Typ',
    choice: 'company',
    chipLabel: ({
      id,
      choice
    }) => ({
      'male': "nur männliche Personen",
      'female': "nur weibliche Personen",
      'neutral': "nur neutrale Personen",
      'company': "nur Unternehmen",
    }[choice]),
    choiceLabel: ({
      id,
      choice
    }) => ({
      'male': "männlich",
      'female': "weiblich",
      'neutral': "neutral",
      'company': "Unternehmen",
    }[choice]),
    choices: ['male', 'female', 'neutral', 'company'],
  },
];

export function usePersonLinkAsVCardMaker({id}) {
  const {parent_person: parentPersonId, child_person: childPersonId, location: locationId, type, phone, phone2, fax, mobile, email} = useAddressesPersonLink({id});

  const childPersonName = usePersonName({id: childPersonId});
  const parentPersonName = usePersonName({id: parentPersonId});

  const childPerson = useAddressesPerson({id: childPersonId});
  // const parentPerson = useAddressesPerson({id: parentPersonId});
  const location = useAddressesLocation({id: locationId});

  return () => {
    const vcard = new VCard();
    vcard.addName(childPerson.name, childPerson.first_name, undefined, childPerson.title_prefix, childPerson.title_suffix);

    if (parentPersonId !== childPersonId) {
      vcard.addCompany(parentPersonName);

      if (type) {
        vcard.addRole(type);
      }
    }

    if (email) {
      vcard.addEmail(email);
    }

    if (phone) {
      vcard.addPhoneNumber(phone, 'PREF;WORK');
    }
    if (phone2) {
      vcard.addPhoneNumber(phone2, 'WORK');
    }
    if (mobile) {
      vcard.addPhoneNumber(mobile, 'CELL');
    }
    if (fax) {
      vcard.addPhoneNumber(fax, 'FAX');
    }

    if (location) {
      const name = parentPersonId !== childPersonId ? parentPersonName : undefined;
      const extended = undefined;
      const street = location.address_line;
      const city = location.city;
      const region = undefined;
      const zip = location.zip;
      const country = undefined;

      vcard.addAddress(name, extended, street, city, region, zip, country, 'WORK;POSTAL');
    }

    const blob = new Blob([vcard.toString()], {type: "text/vcard"});
    blob.name = `${childPersonName || id}.vcf`;
    return blob;
  };
}
