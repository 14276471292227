import React from 'react';

import {connect} from "react-redux";
import {getProgress} from "../../features/ui/progress";
import {isResultSelected} from "../../features/ui/listing";
import {getDecrypted} from "../../features/e2ee";
import {
  deleteDocument,
  deleteDocumentDraft,
  documentPlaintextSize,
  getDocumentOrDraft,
  isDocumentDeleted,
  isDocumentRead
} from "../../features/entities/document";
import DocumentRow from "./document-row-view";
import {getSessionUser} from "../../features/session";

const mapStateToProps = (state, {documentId, listingId}) => {
  const document = getDecrypted(getDocumentOrDraft(documentId))(state);
  const isDraft = (document.id === undefined);

  const caseId = document.case;

  const isSelected = isResultSelected(state)(listingId)(documentId);

  let inProgress, isError;
  if (!isDraft) {
    // Determine download status.
    const downloadProgress = getProgress('downloadDocument', documentId)(state),
      decryptionProgress = getProgress('decryptDocument', documentId)(state),
      deletionProgress = getProgress(deleteDocument.type, documentId)(state);

    inProgress = (
      downloadProgress.inProgress || decryptionProgress.inProgress || deletionProgress.inProgress ||
      (downloadProgress.success && !decryptionProgress.started)
    );
    isError = downloadProgress.error || decryptionProgress.error || deletionProgress.error;
  } else {
    // Determine upload status.
    const uploadProgress = getProgress('uploadDocument', documentId)(state),
      encryptionProgress = getProgress('encryptDocument', documentId)(state);

    inProgress = (
      uploadProgress.inProgress || encryptionProgress.inProgress ||
      (encryptionProgress.success && !uploadProgress.started)
    );
    isError = encryptionProgress.error || uploadProgress.error;
  }

  return {
    name: document.name,
    description: document.description,

    uploadedAt: document.uploaded_at,
    uploaderId: isDraft ? getSessionUser(state) : document.uploader,

    plaintextSize: documentPlaintextSize(documentId)(state),

    hasFile: !(document.has_file === false),
    relatedObject: document.related_object,

    isRead: isDraft ? true : isDocumentRead(documentId)(state),

    pinned: document.pinned,

    inProgress,
    isError,

    caseId,

    isDeleted: isDocumentDeleted(documentId)(state),

    showCancel: isDraft && isError,

    isSelected,
    isDraft,
  };
};

const mapDispatchToProps = (dispatch, {documentId, toggleItem}) => {
  return {
    toggle: () => toggleItem(documentId),

    deleteDraft: () => dispatch(deleteDocumentDraft({documentId})),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {isDraft, ...otherStateProps} = stateProps;
  const {deleteDraft, ...otherDispatchProps} = dispatchProps;
  return {
    ...ownProps,
    ...otherStateProps,

    cancel: isDraft ? deleteDraft : undefined,

    ...otherDispatchProps,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DocumentRow);
