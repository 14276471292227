import React from "react";
import {DecryptedText} from "../data/text";
import {StatusBadge} from "../layout";

export default class DocumentDownloadLink extends React.Component {
  handleClick = event => {
    this.props.onClick();
    event.preventDefault();
  };

  render() {
    const {url, blobUrl, name, decrypted, saved, isRead, prefetch, hideBadges} = this.props;
    return (
      <span onClick={evt => evt.stopPropagation()}>
        <a
          href={blobUrl || url}
          className={(isRead ? 'document-read' : 'document-unread') + " besides-badge"}
          data-document-element="download-url"
          onClick={evt => this.handleClick(evt)}
          onContextMenu={prefetch}
          download={blobUrl ? name : null}
        >
          <DecryptedText text={name}/>
        </a>
        {saved && !hideBadges ? (
          <> <StatusBadge
            type='primary'
            tooltip="Sie haben das Dokument heruntergeladen."
            placement='right'
            checkmarks={1}
          >
            heruntergeladen
          </StatusBadge></>
        ) : decrypted && !hideBadges ? (
          <> <StatusBadge
            type='success'
            tooltip="Das Dokument wurde in Ihrem Browser entschlüsselt und kann nun gespeichert werden."
            placement='right'
            checkmarks={1}
          >
            entschlüsselt
          </StatusBadge></>
        ) : null}
      </span>
    );
  }
}
