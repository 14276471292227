import {channel} from 'redux-saga';
import {actionChannel, all, call, delay, fork, put, race, select, spawn, take} from 'redux-saga/effects';
import {deleteDecryptedEntity, registerDecryptedEntityObserver, unregisterDecryptedEntityObserver} from "features/decryptedEntity/decrypted-entity-slice";
import {getVersion} from "features/session";
import {ENTITY_LIST_URLS, ENTITY_TYPES} from "api/api-schemas";
import {getDecryptedEntityApi} from "features/decryptedEntity/decrypted-entity-hooks";
import {isEntityVisible, setVisible} from "features/ui/visibility-tracker";

const ENTITY_TYPE_SAGA_ACTION_TYPES = [
  registerDecryptedEntityObserver.type,
  unregisterDecryptedEntityObserver.type,
];

const createEntityTypeSaga = ({type}) => (function*(chan) {
  const registeredIds = {};
  let registeredIdsCount = 0;
  let justAdded = false;

  let warned = false;

  let queuedActions = [];

  const dispatch = function (action) {
    queuedActions.push(action);
  }

  let lastRefreshTime = null;

  while (true) {
    while (queuedActions.length > 0) {
      yield put(queuedActions.pop());
    }

    const {action, shouldUpdate, setVisible: changedVisibility} = yield race({
      action: take(chan),
      ...(registeredIdsCount > 0 ? {
        shouldUpdate: delay(justAdded ? 100 : 2000),
        setVisible: take([setVisible]),
      } : {}),
    });

    if (action) {
      const {id} = action.payload;

      if (action.type === registerDecryptedEntityObserver.type) {
        if (registeredIds[id]) {
          registeredIds[id]++;
        } else {
          registeredIds[id] = 1;
          registeredIdsCount++;
        }
        justAdded = true;
      } else if (action.type === unregisterDecryptedEntityObserver.type) {
        registeredIds[id]--;
        if (!registeredIds[id]) {
          delete registeredIds[id];
          registeredIdsCount--;
        }
      }
    }

    if (shouldUpdate || changedVisibility) {
      const currentTime = new Date();
      if (lastRefreshTime && !(yield select(isEntityVisible))) {
        if (currentTime - lastRefreshTime < 60000) {
          // console.log(`throttle update of ${type} since page is not visible`);
          continue;
        }
      }
      lastRefreshTime = currentTime;

      justAdded = false;

      const ids = Object.keys(registeredIds);

      const schema = ENTITY_TYPES[type];
      const urlTemplate = ENTITY_LIST_URLS[type];

      if (schema && urlTemplate) {
        // Perform updates in chunks to prevent URLs from becoming too long and to limit per-query server load.
        const chunkSize = 30;
        for (let i = 0, j = ids.length; i < j; i += chunkSize) {
          const idsChunk = ids.slice(i, i + chunkSize);

          const id__in = idsChunk.join(',');
          let url = urlTemplate;

          url += `?id__in=${id__in}`;

          // console.log(`do update ${type}: ${ids}`);
          const version = yield select(getVersion);
          const entityApi = getDecryptedEntityApi([schema], dispatch, version);
          try {
            {
              const {normalizedData} = yield call(entityApi.get, url);
              // yield put(setEntities(normalizedData));

              const resultIds = normalizedData?.result?.map(x => `${x}`);  // ids need to be of type string

              for (const id of idsChunk) {
                if (!resultIds.includes(id)) {
                  console.log(`${type}/${id} went missing`);
                  // FIXME: Normalize entity type names.

                  // let pluralType = type + `s`;
                  // if (type.endsWith('ys')) {
                  //   pluralType = type.substring(0, -1) + 'ies';
                  // } else if (type === 'persons') {
                  //   pluralType = 'people';
                  // }
                  yield put(deleteDecryptedEntity(type, id));
                }
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      } else if (!warned) {
        console.log(`should update ${type}: ${ids}`);
        warned = true;
      }
    }
  }
})

function* entityTypeSagasManager() {
  // We do not want to miss any relevant event.
  const entityTypeActionsChan = yield actionChannel(ENTITY_TYPE_SAGA_ACTION_TYPES);

  const entityTypeSagas = {};
  while (true) {
    const action = yield take(entityTypeActionsChan);
    const {type} = action.payload;

    let entityTypeSaga = entityTypeSagas[type];

    if (entityTypeSaga && !entityTypeSaga.task.isRunning()) {
      delete entityTypeSagas[type];
      entityTypeSaga = undefined;
    }

    if (!entityTypeSaga) {
      const chan = yield call(channel);
      entityTypeSagas[type] = entityTypeSaga = {
        chan,
        task: yield fork(createEntityTypeSaga({type}), chan),
      }
    }

    yield put(entityTypeSaga.chan, action);
  }
}

export default function* rootSaga() {
  yield spawn(entityTypeSagasManager);
  yield all([]);
}
