import './statemachine.js';
import $ from 'jquery';

import {
  clearDocumentSet,
  getDocumentDraft,
  getDocumentIdsForSet,
  getDraftDocumentIdsForSet,
  modifyDocumentDraft,
  uploadDocument
} from "../../../features/entities/document";
import {getView, setViewData} from '../../../features/ui/view';
import {updateListing} from '../../../features/ui/listing';
import {store} from "../../../stores";
import {needCase} from "../../../features/entities/case";

$.widget("solesoftware.documentupload", $.solesoftware.statemachine, {
  /*
   * Name of this state machine as used in statemachine-specific data attributes, e.g., data-statemachine-action.
   */
  name: 'documentupload',

  /*
   * State machine that specifies all possible states and transitions during document upload
   */

  _states: {
    /*
     * Initial state in which nothing is known and only a minimal view should be shown.
     */
    start: {
      initial: true,
      action: '_action_start',
      transitions: {
        set_case_url: 'set_case_url',
        prepare: 'determine_state',
        error: 'error'
      },
      views: [
        'documentupload-uninitialized', // overall stage
        'documentupload-can-add-documents', 'documentupload-cannot-upload', 'documentupload-can-change-case', // capabilities
        'documentupload-has-documents', 'documentupload-has-no-case' // state
      ]
    },

    /*
     * States for upload preparation: While in any of the states below, both documents and the target case can be changed arbitrarily.
     */

    has_nothing: {
      action: '_action_reset',
      transitions: {
        set_case_url: 'set_case_url',
        abort: 'start',
        error: 'error'
      },
      views: [
        'documentupload-initialized', 'documentupload-preparation', // overall stage
        'documentupload-can-add-documents', 'documentupload-cannot-upload', 'documentupload-can-change-case', 'documentupload-can-abort', // capabilities
        'documentupload-has-documents', 'documentupload-has-no-case' // state
      ],
      focus_view: 'documentupload-case-number-input'
    },

    has_case: {
      transitions: {
        set_case_url: 'set_case_url',
        remove_case: 'remove_case',
        upload: 'uploading_empty',
        abort: 'start'
      },
      views: [
        'documentupload-initialized', 'documentupload-preparation', // overall stage
        'documentupload-can-add-documents', 'documentupload-can-upload', 'documentupload-can-change-case', 'documentupload-can-abort', // capabilities
        'documentupload-has-documents', 'documentupload-has-case' // state
      ]
    },

    has_documents: {
      transitions: {
        set_case_url: 'set_case_url',
        abort: 'start'
      },
      views: [
        'documentupload-initialized', 'documentupload-preparation', // overall stage
        'documentupload-can-add-documents', 'documentupload-cannot-upload', 'documentupload-can-change-case', 'documentupload-can-abort', // capabilities
        'documentupload-has-documents', 'documentupload-has-no-case' // state
      ],
      focus_view: 'documentupload-case-number-input'
    },

    has_case_and_documents: {
      transitions: {
        set_case_url: 'set_case_url',
        remove_case: 'remove_case',
        upload: 'uploading',
        abort: 'start'
      },
      views: [
        'documentupload-initialized', 'documentupload-preparation', // overall stage
        'documentupload-can-add-documents', 'documentupload-can-upload', 'documentupload-can-change-case', 'documentupload-can-abort', // capabilities
        'documentupload-has-documents', 'documentupload-has-case' // state
      ],
      focus_view: 'documentupload-upload-button'
    },

    /*
     * Intermediate states that allow selection of documents and target case while in preparation phase.
     * (Intermediate states are not associated with any views as they are left almost immediately.)
     */

    set_case_url: {
      action: '_action_set_case_url',
      transitions: {
        success: 'determine_state',
        error: 'error'
      }
    },

    remove_case: {
      action: '_action_remove_case',
      transitions: {
        success: 'determine_state',
        error: 'error'
      }
    },

    determine_state: {
      action: '_action_determine_state',
      transitions: {
        has_nothing: 'has_nothing',
        has_case: 'has_case',
        has_documents: 'has_documents',
        has_case_and_documents: 'has_case_and_documents',
      }
    },

    /*
     * States for the actual upload. When any of the states below are reached, modifications to the target case are no longer possible.
     * Documents can still be removed (unless already uploaded) or added, though.
     */

    uploading_empty: {
      action: '_action_uploading_empty',
      transitions: {
        upload_document: 'upload_document',
        document_updated: 'uploading',
        empty: 'uploading_empty',
        abort: 'determine_state'
      },
      views: [
        'documentupload-initialized', 'documentupload-upload', // overall stage
        'documentupload-can-add-documents', 'documentupload-can-abort', // capabilities
        'documentupload-has-case', 'documentupload-has-documents', 'documentupload-uploading-empty' // state
      ]
    },

    uploading: {
      action: '_action_uploading',
      transitions: {
        upload_document: 'upload_document',
        document_updated: 'uploading',
        empty: 'uploading_empty',
        abort: 'determine_state',
        success: 'uploading_empty',
      },
      views: [
        'documentupload-initialized', 'documentupload-upload', // overall stage
        'documentupload-can-add-documents', // capabilities
        'documentupload-has-documents', 'documentupload-has-case', 'documentupload-uploading' // state
      ]
    },

    /*
     * Fatal error states.
     */

    error: {
      views: [
        'documentupload-error'
      ]
    }
  },

  /*
   * Initialization and other plugin-related functions.
   */

  _create: function () {
    this.data_public = {};

    this.startedUploads = [];

    this._super();
  },

  /*
   * Document upload related actions.
   */

  getDraftDocumentIds() {
    return getDraftDocumentIdsForSet('bulkUploader')(store.getState());
  },

  getUploadedDocumentIds() {
    return getDocumentIdsForSet('bulkUploader')(store.getState());
  },

  getAllDocumentIds() {
    return [
      ...this.getUploadedDocumentIds(),
      ...this.getDraftDocumentIds(),
    ];
  },

  _action_determine_state: function (fire) {
    store.dispatch(setViewData({type: 'bulkUploader', id: 'main', startedUpload: false}));

    const draftDocumentIds = this.getAllDocumentIds();

    if (draftDocumentIds.length && this.data_public.case_slug)
      fire('has_case_and_documents');
    else if (this.data_public.case_slug)
      fire('has_case');
    else if (draftDocumentIds.length > 0)
      fire('has_documents');
    else
      fire('has_nothing');
  },

  _set_case: function (case_slug) {
    store.dispatch(setViewData({type: 'bulkUploader', id: 'main', caseId: case_slug}));

    store.dispatch(needCase({id: case_slug}));

    store.dispatch(updateListing({
      id: 'document-drafts',
      ordering: [],
      meta: {
        caseId: case_slug,
      },
    }));

    // propagate new case to documents
    const draftDocumentIds = this.getDraftDocumentIds();
    this.data_public.case_slug = case_slug;
    for (const draftId of draftDocumentIds) {
      if (Object.keys(getDocumentDraft(draftId)(store.getState())).length) {
        store.dispatch(modifyDocumentDraft({
          documentId: draftId,
          case: case_slug,
        }));
      }
    }
  },

  _action_set_case_url: function (fire, case_slug) {
    this._set_case(case_slug);
    fire('success');
  },

  _action_remove_case: function (fire) {
    this._set_case(undefined);
    fire('success');
  },

  _action_start: function () {
    this._action_reset();

    store.dispatch(setViewData({type: 'bulkUploader', id: 'main', caseId: undefined}));
  },

  _action_reset: function () {
    this._set_case(undefined);
    this._clear_view();
  },

  _action_uploading_empty: function (fire) {
    const view = getView('bulkUploader', 'main')(store.getState());
    if (!view.startedUpload) {
      store.dispatch(setViewData({type: 'bulkUploader', id: 'main', startedUpload: true}));
    }

    let hasNewDocuments = false;
    for (const draftId of this.getDraftDocumentIds()) {
      if (!this.startedUploads.includes(draftId)) {
        hasNewDocuments = true;
        break;
      }
    }

    if (hasNewDocuments) {
      fire('document_updated');
    } else {
      setTimeout(() => fire('empty'), 300);
    }
  },

  _action_uploading: function (fire) {
    store.dispatch(setViewData({type: 'bulkUploader', id: 'main', startedUpload: true}));

    const draftDocumentIds = this.getDraftDocumentIds();
    for (const draftId of draftDocumentIds) {
      if (this.startedUploads.includes(draftId)) {
        continue;
      }
      if (Object.keys(getDocumentDraft(draftId)(store.getState())).length) {
        store.dispatch(uploadDocument({
          documentId: draftId,
        }));
        this.startedUploads.push(draftId);
      }
    }

    if (draftDocumentIds.length > 0) {
      setTimeout(() => fire('document_updated'), 300);
    } else {
      fire('success');
    }
  },

  /*
   * Event handlers for view updates.
   */

  _init_view: function () {
    // Initializes views.
    this._super();

    this._clear_view();
  },

  _clear_view: function () {
    // clear case number search field
    this.element.find('.documentupload-case-number-input').val('').trigger('change');

    // clear new documents from document list
    store.dispatch(clearDocumentSet({setId: 'bulkUploader'}));
    store.dispatch(setViewData({type: 'bulkUploader', id: 'main', uploading: false, startedUpload: false}));
  },
});
