import createSagaMiddleware from 'redux-saga';
import rootReducer, {saga as rootSaga} from '../features';
import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {setDocumentBlob} from "../features/entities/document";
import {e2eeMiddleware} from "../features/e2ee";
import React from "react";
import {createUnloadBlockerStoreEnhancer} from "../features/ui/unload-blocker";
import {createVisibilityTrackerStoreEnhancer} from "../features/ui/visibility-tracker";
import {createDragAndDropStoreEnhancer} from "../features/ui/drag-and-drop";

import {reduxBatch} from '@manaflair/redux-batch';

import log from 'loglevel';

const sagaMiddleware = createSagaMiddleware({
  onError(error, {sagaStack}) {
    log.error({error, errorType: typeof error, sagaStack});
  },
});

const unloadBlockerEnhancer = createUnloadBlockerStoreEnhancer();
const visibilityTrackerEnhancer = createVisibilityTrackerStoreEnhancer();
const dragAndDropEnhancer = createDragAndDropStoreEnhancer();

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [setDocumentBlob.type],
      },
    }),
    e2eeMiddleware,
    sagaMiddleware,
  ],
  enhancers: middlewareEnhancers => [
    reduxBatch,
    ...middlewareEnhancers,
    reduxBatch, // needed twice (before and after middleware)
    unloadBlockerEnhancer,
    visibilityTrackerEnhancer,
    dragAndDropEnhancer,
  ],
  devTools: DEBUG,
});

sagaMiddleware.run(rootSaga);

if (DEBUG) {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    // trackAllPureComponents: true,
  });
}
