import {createAction, createSelector, createSlice} from "@reduxjs/toolkit";
import {getUI} from "../ui-selectors";

import memoize from "lodash.memoize";
import _ from "lodash";

const createSelectReducer = defaultSelection => (state, action) => {
  const {listingId, id} = action.payload;

  let select = defaultSelection;

  const selectedIdsByListing = state.selectedIdsByListing;
  let selectedIds = selectedIdsByListing[listingId];
  if (selectedIds === undefined) {
    selectedIdsByListing[listingId] = selectedIds = [];
  }

  if (select === undefined) {
    select = !selectedIds.includes(id);
  }

  if (select) {
    if (!selectedIds.includes(id)) {
      selectedIds.push(id);
    }
  } else {
    if (selectedIds.includes(id)) {
      selectedIdsByListing[listingId] = selectedIds = selectedIds.filter(existingId => existingId !== id);
    }
  }
};

const listingSlice = createSlice({
  name: 'listing',
  initialState: {
    byId: {},
    filteredIdsByFilter: {},
    selectedIdsByListing: {},
  },
  reducers: {
    selectResult: createSelectReducer(true),
    deselectResult: createSelectReducer(false),
    toggleResult: createSelectReducer(),
    unselectAll(state, action) {
      const {listingId} = action.payload;
      delete state.selectedIdsByListing[listingId];
    },
    bulkSelect(state, action) {
      const {listingId, ids} = action.payload;

      const selectedIdsByListing = state.selectedIdsByListing;
      let selectedIds = selectedIdsByListing[listingId];
      if (selectedIds === undefined) {
        selectedIdsByListing[listingId] = selectedIds = [];
      }


      for (const id of ids) {
        if (!selectedIds.includes(id) && id !== null && id !== undefined) {
          selectedIds.push(id);
        }
      }
    },
    bulkUnselect(state, action) {
      const {listingId, ids} = action.payload;

      const selectedIdsByListing = state.selectedIdsByListing;
      const selectedIds = selectedIdsByListing[listingId];
      if (selectedIds === undefined) {
        return;
      }

      selectedIdsByListing[listingId] = selectedIds.filter(id => !ids.includes(id));
    },
    updateListing(state, action) {
      const {
        id,
        ...listing
      } = action.payload;
      if (state.byId[id] === undefined) {
        state.byId[id] = {id};
      }

      const oldFilterId = getFilterIdFromListing(state.byId[id]);

      const hasCounterChanged = (listing.count !== undefined && state.byId[id].count !== listing.count);
      Object.assign(state.byId[id], {error: undefined, ...listing});

      const filterId = getFilterIdFromListing(state.byId[id]);

      const currentListing = state.byId[id];

      // Reset count if filter has changed.
      if (filterId !== oldFilterId && listing?.count === undefined) {
        currentListing.count = undefined;
      }

      currentListing.currentPageSize = Math.min(
        currentListing.pageSize,
        currentListing.count !== null ? (
          currentListing.count - (currentListing.currentPage - 1) * currentListing.pageSize
        ) : (
          currentListing.pageSize
        ),
      );

      if (hasCounterChanged) {
        // Delete all filtered IDs except for the ones currently shown (they might have become inconsistent).
        state.filteredIdsByFilter[id] = {
          [filterId]: state.filteredIdsByFilter[id]?.[filterId] || [],
        };
      }
    },
    cleanupListing(state, action) {
      const {id} = action.payload;

      delete state.byId[id];
      delete state.filteredIdsByFilter[id];
      delete state.selectedIdsByListing[id];
    },
    announceFilteredIdsExcerpt(state, action) {
      const {
        results = [],
        previous,
        next,
        listingId,
        filterId,
        offset,
        cursor
      } = action.payload;

      if (state.filteredIdsByFilter[listingId] === undefined) {
        state.filteredIdsByFilter[listingId] = {};
      }
      if (state.filteredIdsByFilter[listingId][filterId] === undefined) {
        state.filteredIdsByFilter[listingId][filterId] = [];
      }

      const filteredIds = state.filteredIdsByFilter[listingId][filterId];

      if (filteredIds.length === 0) {
        // console.log(`store received ids as is because I didn't know anything about them before`);
        filteredIds.push({
          results: results.map(result => result.id),
          previous,
          next,
          offset,
          cursor,
        });
        return;
      }

      if (cursor) {
        // console.log(`integrating received ids into ${filterId} via cursor ${cursor}`);
        let insertPosition,
          actualOffset;
        filteredIds.forEach((excerpt, excerptPosition) => {
          if (excerpt.next === cursor) {
            insertPosition = excerptPosition + 1;
            actualOffset = excerpt.offset + excerpt.results.length;
            // console.log(`found insertion candidate after ${excerptPosition}, yielding offset ${actualOffset}`);
          } else if (excerpt.previous === cursor) {
            insertPosition = excerptPosition;
            actualOffset = excerpt.offset - results.length;
            // console.log(`found insertion candidate before ${excerptPosition}, yielding offset ${actualOffset}`);
          }
        });
        if (insertPosition !== undefined) {
          // console.log(`inserting at ${insertPosition}, yielding offset ${actualOffset}`);
          const resultIds = results.map(result => result.id);
          filteredIds.splice(insertPosition, 0, {
            results: resultIds,
            previous,
            next,
            offset: actualOffset,
          });

          if (filteredIds[insertPosition + 1] !== undefined) {
            const subsequentExcerpt = filteredIds[insertPosition + 1];
            if (subsequentExcerpt.offset >= actualOffset + resultIds.length) {
              // The inserted excerpt fits, everything is fine.
            } else if (subsequentExcerpt.offset === actualOffset && _.isEqual(subsequentExcerpt.results, resultIds)) {
              // Subsequent excerpt is identical, potentially due to a refetch.
              state.filteredIdsByFilter[listingId][filterId].splice(insertPosition, 1);
            } else {
              // Subsequent excerpt does not seem to match order, so we clear everything after the position that we
              // have fetched.
              state.filteredIdsByFilter[listingId][filterId] = state.filteredIdsByFilter[listingId][filterId].slice(0, insertPosition + 1);
            }
          }

          return;
        }
      }

      // TODO: This is just a test. Filling ids into the existing state must only happen if either:
      //   - received ids overlap with part of the known state (this is usually not the case when results are provided
      //     by page number), or
      //   - results were fetched via a cursor (the cursor serves as anchor)
      // for (const excerpt of filteredIds) {
      //   const excerptEndOffset = excerpt.offset + excerpt.results.length;
      //   if (offset === excerptEndOffset) {
      //     excerpt.results = [...excerpt.results, ...results.map(result => result.id)];
      //     excerpt.next = next;
      //     return;
      //   }
      // }

      // console.log(`re-initialize ${filterId}`);

      state.filteredIdsByFilter[listingId][filterId] = [{
        results: results.map(result => result.id),
        previous,
        next,
        offset,
      }];
    },
  },
  extraReducers: (builder) => {
    builder.addCase('document/changeDocumentId', (state, action) => {
      const {
        documentId: resultId,
        newDocumentId: newResultId
      } = action.payload;

      for (const selectedIds of Object.values(state.selectedIdsByListing)) {
        const index = selectedIds.indexOf(resultId);
        if (index !== -1) {
          selectedIds[index] = newResultId;
        }
      }
    });
  },
});

export const {
  updateListing,
  cleanupListing,
  announceFilteredIdsExcerpt,
  selectResult,
  unselectResult,
  bulkSelect,
  bulkUnselect,
  toggleResult,
  unselectAll
} = listingSlice.actions;

export const registerListingObserver = createAction('listing/registerObserver');
export const unregisterListingObserver = createAction('listing/unregisterObserver');

export const bulkListingAction = createAction('listing/bulkAction');

export default {
  listings: listingSlice.reducer,
};

export const getListings = createSelector(
  getUI,
  ui => ui.listings.byId,
);

const defaultListing = {};

export const getListing = createSelector(
  getListings,
  listings => id =>
    listings[id] || defaultListing,
);

export const getFilterIdFromListing = listing => Object.entries({
  ...listing.meta, ...listing.meta?.filters || {},
  ordering: listing.ordering
})
  .map(
    ([k, v]) => `${listing?.id}?${encodeURIComponent(k)}=${encodeURIComponent(v)}`
  )
  .join(',');

export const getFilterIdFromListingId = createSelector(
  getListing,
  (getListing) => memoize(listingId => {
    const listing = getListing(listingId);
    return getFilterIdFromListing(listing);
  }),
);

const defaultSelectedIds = [];

export const getSelectedIdsByListingId = createSelector(
  getUI,
  ui => listingId => ui.listings.selectedIdsByListing[listingId] || defaultSelectedIds,
);

export const getIdsByFilter = createSelector(
  getUI,
  ui => ui.listings.filteredIdsByFilter,
);

const defaultIds = [];

export const getFilteredIds = createSelector(
  getIdsByFilter,
  idsByFilter => filterId =>
    idsByFilter[filterId] || defaultIds,
);

export const getFilteredIdsByListingId = createSelector(
  getFilterIdFromListingId,
  getIdsByFilter,
  (getFilterIdFromListingId, idsByFilter) => listingId =>
    idsByFilter?.[listingId]?.[getFilterIdFromListingId(listingId)] || defaultIds,
);

export const getNumberOfCachedIdsInListing = createSelector(
  getFilteredIdsByListingId,
  (getFilteredIdsByListingId) => memoize(listingId => {
    let count = 0;
    for (const excerpt of getFilteredIdsByListingId(listingId)) {
      count += excerpt.results.length;
    }
    return count;
  }),
);

export const getVisibleIdsForListing = createSelector(
  getListing,
  getFilteredIdsByListingId,
  (getListing, getFilteredIdsByListingId) => memoize(listingId => {
    const {
      currentPage,
      pageSize,
      currentPageSize
    } = getListing(listingId);

    const startOffset = (currentPage - 1) * pageSize;
    const endOffset = startOffset + currentPageSize;

    const resultIds = [];
    let offset = startOffset;

    let reachedStart = false,
      reachedEnd = false;

    const ids = getFilteredIdsByListingId(listingId);
    for (const excerptIds of ids) {
      const excerptStartOffset = excerptIds.offset;
      const excerptEndOffset = excerptStartOffset + excerptIds.results.length;

      if (excerptIds.previous === null) {
        reachedStart = true;
      }

      if (excerptIds.next === null) {
        reachedEnd = true;
      }

      if (excerptEndOffset <= offset) {
        // This excerpt lies outside the desired range, but further excerpts might lie inside.
        continue;
      }

      if (excerptStartOffset >= endOffset) {
        // This and all subsequent excerpts lie outside the desired range,
        break;
      }

      // Fill with null results if necessary.
      while (offset < excerptStartOffset) {
        resultIds.push(null);
        offset += 1;
      }

      let currentOffset = excerptStartOffset - 1;
      for (const id of excerptIds.results) {
        currentOffset += 1;

        if (offset !== currentOffset) {
          continue;
        }

        if (offset === startOffset) {
          reachedStart = true;
        }

        resultIds.push(id);

        offset += 1;

        if (offset === endOffset) {
          reachedEnd = true;
        }

        if (offset === endOffset) {
          break;
        }
      }
    }

    if (!reachedStart || !reachedEnd) {
      // Fill with null results.
      while (offset < endOffset) {
        resultIds.push(null);
        offset += 1;
      }
    }

    return resultIds;
  }),
);

export const isResultSelected = createSelector(
  getSelectedIdsByListingId,
  (getSelectedIdsByListingId) => memoize(listingId =>
    memoize(id =>
      getSelectedIdsByListingId(listingId)
        .includes(id)
    ),
  ),
);
