import React, {useMemo, useRef, useState} from 'react';
import {withStyles} from '@mui/styles';
import DialogContent from '@mui/material/DialogContent';
import {Dialog} from "../layout/modal/Dialog";
import {DialogTitle} from "../layout/modal/DialogTitle";
import {getView} from "../../features/ui/view";
import {useSelector, useDispatch} from 'react-redux';
import {getDocument, hideDocument} from "../../features/entities/document";
import Typography from '@mui/material/Typography';
import {getDecrypted} from "../../features/e2ee";
import {DecryptedText} from "../data/text";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AddressDatabase from "../addressdatabase/AddressDatabase";
import {KeyIdProvider} from "../../features/decryptedEntity/decrypted-entity-hooks";
import AddressDatabaseTitle from "../addressdatabase/AddressDatabaseTitle";
import {TemplatesDialog} from "./TemplatesDialog";

const styles = theme => ({
  icon: {
    marginRight: theme.spacing(2),
    float: 'left',
    fontSize: '1.2em',
  },
});

const AddressDatabaseDocumentViewer = withStyles(styles)(React.memo(({id, classes, open, name, related_object, key_id}) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(hideDocument({documentId: id}));

  const [showTemplates, setShowTemplates] = useState(false);
  const closeTemplatesDialog = useMemo(() => {
    return () => setShowTemplates(false);
  }, [setShowTemplates]);

  return (
    <KeyIdProvider keyId={key_id}>
      <Dialog
        open={open}
        onClose={() => onClose()}
        aria-labelledby="address-database-document-dialog-title"
        fullScreen
        disableEnforceFocus
        disableEscapeKeyDown
      >
        <DialogTitle disableTypography onClose={() => onClose()} sx={{color: 'primary.main'}}>
          <AddressDatabaseTitle id={related_object?.id} onShowTemplates={() => setShowTemplates(true)}>
            <Typography variant="h5">
              <FontAwesomeIcon icon={['fa', 'address-book']} className={classes.icon}/>
              <DecryptedText text={name}/>
            </Typography>
          </AddressDatabaseTitle>
        </DialogTitle>

        <DialogContent dividers sx={{p: 0, m: 0, minWidth: 400}}>
          <Typography variant="body1" component="div" style={{height: '100%'}}>
            <AddressDatabase databaseId={related_object?.id} documentId={id}/>
          </Typography>
          <TemplatesDialog
            databaseId={related_object?.id}
            open={showTemplates}
            onClose={closeTemplatesDialog}
          />
        </DialogContent>
      </Dialog>
    </KeyIdProvider>
  );
}));

export default ({id}) => {
  const open = useSelector(getView('viewDocument', id)).visible;
  const {name, related_object, key_id} = useSelector(getDecrypted(getDocument(id)));

  return (
    <AddressDatabaseDocumentViewer
      id={id}
      open={open}
      name={name}
      related_object={related_object}
      key_id={key_id}
    />
  );
};
