import React, {useState} from 'react';
import {Box} from "@mui/material";
import {ListsPanel} from "./ListsPanel";
import {ListPanel} from "./ListPanel";
import {useAddressesDatabase, useAddressesList, useAddressesLists} from "../../features/addresses/addresses-hooks";
import {ListForm} from "./forms/ListForm";

export function ListsTab({databaseId, onShowPerson, ...props}) {
  const {can_update: canUpdate} = useAddressesDatabase({id: databaseId});

  const [editList, setEditList] = useState(null);
  const [selectedLists, setSelectedLists] = useState([]);

  const singleSelectedList = selectedLists.length === 1 ? selectedLists[0] : null;
  let {is_event} = useAddressesLists({ids: selectedLists});
  if (selectedLists.length === 0) {
    is_event = false;
  }
  let {name} = useAddressesList({id: singleSelectedList});
  if (!singleSelectedList) {
    name = `${selectedLists.length} ausgewählte ${is_event ? "Events" : "Verteiler"}`;
  }

  const showList = selectedLists.length !== 0;

  return (
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'row'}}>
      <ListsPanel
        databaseId={databaseId}
        onEditList={canUpdate ? setEditList : null}
        onSelectList={(listId, evt) => {
          const allowMulti = evt?.ctrlKey || evt?.altKey || evt?.shiftKey;
          if (selectedLists.includes(listId)) {
            setSelectedLists(allowMulti ? selectedLists.filter(id => id !== listId) : selectedLists.length > 1 ? [listId] : []);
          } else {
            setSelectedLists(allowMulti ? [...selectedLists, listId] : [listId]);
          }
        }}
        selectedLists={selectedLists}
        sx={{flex: 1}}
        showAdd={canUpdate && !showList}
        listsTableProps={{
          hideActionColumn: showList,
          showUpdatedColumn: !showList,
          showFooter: !showList || selectedLists.length > 1,
        }}
        {...props}
      />
      {showList && (
        <ListPanel
          databaseId={databaseId}
          listIds={selectedLists}
          onEdit={canUpdate && singleSelectedList && (() => setEditList(singleSelectedList))}
          onClose={() => setSelectedLists([])}
          onShowList={(id) => setSelectedLists([id])}
          sx={{flex: 2}}
          searchFieldPlaceholder={`${name} durchsuchen...`}
          onShowPerson={onShowPerson}
        />
      )}
      {editList !== null && (
        <ListForm
          databaseId={databaseId}
          editList={editList}
          is_event={is_event}
          onClose={() => setEditList(null)}
          onCreate={(id) => setSelectedLists([id])}
        />
      )}
    </Box>
  );
}
