import {useFormContext} from "react-hook-form";
import React from "react";
import {YupField} from "../../../packages/react-hook-form-mui-yup-helpers";
import {FormChoicesField} from "../../../packages/react-hook-form-mui-form-fields";
import EditIcon from "@mui/icons-material/Edit";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";

export function YupFieldWithExpandableSelector({name, dialogPostContent, selectedDisplayValue, dialogTitle, selectorPanel, showSelector, onOpen, onClose, allowInlineSelector, ...props}) {
  const {watch} = useFormContext();
  const selectedValue = watch(name);

  const showInlineSelector = allowInlineSelector && !selectedValue;
  const showSelectorDialog = showSelector && !showInlineSelector;

  if (showInlineSelector) {
    return selectorPanel;
  }

  const renderedField = (
    <YupField
      name={name}
      Component={FormChoicesField}
      items={{
        [selectedValue]: selectedDisplayValue,
      }}
      onOpen={onOpen}
      open={false}
      IconComponent={EditIcon}
      {...props}
    />
  );

  const id = `${name}-selector-dialog`;

  if (showSelectorDialog) {
    return (
      <>
        {renderedField}
        <Dialog
          open={true}
          onClose={onClose}
          aria-labelledby={id}
          maxWidth={false}
        >
          <DialogTitle id={id}>
            {dialogTitle || "Wert wählen"}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {selectorPanel}
              </Grid>
              {dialogPostContent}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={onClose}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return renderedField;
}
