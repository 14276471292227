import React, {memo, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {closeDatabase, openDatabase} from "../../features/addresses";
import {useProgress} from "../../features/ui/progress/progress-hooks";
import CircularProgress from "@mui/material/CircularProgress";
import {Box} from "@mui/material";
import Alert from "@mui/material/Alert";
import AddressDatabaseContent from "./AddressDatabaseContent";

const AddressDatabase = memo(function AddressDatabase({databaseId, documentId}) {
  const dispatch = useDispatch();
  const {inProgress, success, error} = useProgress({type: 'loadAddressDatabase', id: databaseId});

  useEffect(() => {
    if (databaseId) {
      dispatch(openDatabase({id: databaseId, documentId}));

      return () => {
        dispatch(closeDatabase({id: databaseId}));
      };
    }
  }, [databaseId, documentId])

  if (inProgress) {
    return (
      <Box sx={{textAlign: 'center', height: "100%", display: "flex", flexDirection: "column"}}>
        <Box sx={{flex: 1}}>
        </Box>
        <Box sx={{flex: 0}}>
          <CircularProgress size='3rem'/><br/>
          lädt...
        </Box>
        <Box sx={{flex: 1}}>
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        Fehler beim Laden der Datenbank: {error}
      </Alert>
    );
  }

  if (!success) {
    return undefined;
  }

  return (
    <AddressDatabaseContent id={databaseId}/>
  );
});
export default AddressDatabase;
