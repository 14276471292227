import React, {useState} from 'react';
import {useAddressesDatabase} from "../../features/addresses/addresses-hooks";
import {useDispatch, useSelector} from "react-redux";
import {saveDocument} from "../../features/entities/document";
import SimpleMoreMenu from "../../packages/SimpleMoreMenu";
import {GetApp as GetAppIcon, Menu as MenuIcon, ImportExport as ImportIcon, TextSnippet as TextSnippetIcon} from "@mui/icons-material";
import {Divider, ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import {isAddressesDatabaseEmpty} from "../../features/addresses";
import {ImportDialog} from "./ImportDialog";

export default function AddressDatabaseMenu({id, onShowTemplates}) {
  const database = useAddressesDatabase({id});
  const canUpdate = database?.can_update;

  const dispatch = useDispatch();
  const exportDatabase = () => dispatch(saveDocument({documentId: database?.document}));

  const isEmpty = useSelector(isAddressesDatabaseEmpty)(id);

  const [showImportDialog, setShowImportDialog] = useState(false);

  const showMenuButton = !isEmpty || canUpdate;

  return (
    <>
      {showMenuButton ? (
        <SimpleMoreMenu
          id={`address-database-${id}`}
          size="large"
          title=""
          icon={<MenuIcon color="action"/>}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {isEmpty && canUpdate ? (
            <MenuItem
              onClick={() => setShowImportDialog(true)}
            >
              <ListItemIcon>
                <ImportIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>
                Adressdatenbank importieren...
              </ListItemText>
            </MenuItem>
          ) : !isEmpty ? (
            <MenuItem
              onClick={exportDatabase}
            >
              <ListItemIcon>
                <GetAppIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>
                Adressdatenbank exportieren...
              </ListItemText>
            </MenuItem>
          ) : null}
          <Divider/>
          {onShowTemplates ? (
            <MenuItem
              onClick={onShowTemplates}
            >
              <ListItemIcon>
                <TextSnippetIcon fontSize="small"/>
              </ListItemIcon>
              <ListItemText>
                Vorlagen verwalten...
              </ListItemText>
            </MenuItem>
          ) : null}
        </SimpleMoreMenu>
      ) : null}
      {showImportDialog ? (
        <ImportDialog
          open={showImportDialog}
          onClose={() => setShowImportDialog(false)}
          id="address-database-import-dialog"
          databaseId={database?.id}
        />
      ) : null}
    </>
  );
}
