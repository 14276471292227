import React from "react";
import {Chip} from "@mui/material";
import {AlphabeticalPersonName, getPersonLinkType, PersonAddressBox} from "./person";
import {
  useAddressesLocation,
  useAddressesPerson,
  useAddressesPersonLink
} from "../../features/addresses/addresses-hooks";
import {Box} from "@mui/material";
import {DeletedCells, ViewableTableCell} from "./layout";
import {SortableAddressesEntitiesTable} from "./tables";
import StarIcon from '@mui/icons-material/Star';


function PersonLinkRowContent({id, contextPersonId, onView, selected}) {
  const {
    parent_person: parentPersonId,
    child_person: childPersonId,
    location: locationId,
    type,
    phone,
    phone2,
    mobile,
    fax,
    email,
    isDeleted,
  } = useAddressesPersonLink({id, observe: true});
  const parent_person = useAddressesPerson({id: parentPersonId, observe: true});
  const child_person = useAddressesPerson({id: childPersonId, observe: true});
  const {address_line, zip, city, po_box, po_box_zip, notes} = useAddressesLocation({id: locationId, observe: true});

  const isDefault = (parentPersonId === contextPersonId || !contextPersonId) && parent_person?.default_person_link === id;

  if (isDeleted) {
    return (
      <DeletedCells colSpan="3">
        Dieser Kontakt wurde gelöscht.
      </DeletedCells>
    );
  }

  return (
    <>
      <ViewableTableCell
        view={onView}
        sx={selected && {
          background: '#ddd',
        }}
      >
        <Box sx={{display: 'flex'}}>
          {isDefault && (
            <Box sx={{flex: 0, mr: 1}}>
              <StarIcon size="medium" sx={{verticalAlign: 'middle'}} color="primary"/>{' '}
            </Box>
          )}
          <Box sx={{flex: 1}}>
            {parentPersonId === childPersonId || !contextPersonId ? (
              <PersonAddressBox id={id} alphabetical/>
            ) : parentPersonId === contextPersonId ? (
              <>
                <AlphabeticalPersonName id={childPersonId}/>
                {type && (
                  <>
                    {' '}<Chip size="small" label={type}/>
                  </>
                )}
              </>
            ) : (
              <>
                <AlphabeticalPersonName id={parentPersonId}/>
                {type && (
                  <>
                    {' '}<Chip size="small" label={type}/>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </ViewableTableCell>
      {contextPersonId && (
        <ViewableTableCell
          view={onView}
          sx={selected && {
            background: '#ddd',
          }}
        >
          <Chip size="small" label={getPersonLinkType(parentPersonId, childPersonId, contextPersonId)}/>
        </ViewableTableCell>
      )}
    </>
  );
}

export function PersonLinksTable({ids, contextPersonId, onViewContact, appliedFilters, selectedPersonLink, ...props}) {
  const columns = [
    {field: 'contact', name: "Kontakt"},
  ];

  if (contextPersonId) {
    columns.push({field: 'type', name: "Typ"});
  }

  return (
    <SortableAddressesEntitiesTable
      entityType="addresses_person_link"
      entityNameSingular="Kontakt"
      entityNamePlural="Kontakte"
      expandedEntityFields={['parent_person', 'child_person', 'location']}
      ids={ids}
      itemContent={(index, id) => (
        <PersonLinkRowContent
          id={id}
          contextPersonId={contextPersonId}
          onView={() => onViewContact(id)}
          selected={id === selectedPersonLink}
        />
      )}
      columns={columns}
      searchFields={[
        'child_person.name',
        'child_person.first_name',
        'child_person.title_prefix',
        'child_person.title_suffix',
        'parent_person.name',
        'parent_person.first_name',
        'parent_person.title_prefix',
        'parent_person.title_suffix',
        'location.address_line',
        'location.zip',
        'location.city',
      ]}
      sortFieldGetters={{
        'contact': ({id, parent_person, child_person, location, ...props}) => {
          const otherPerson = parent_person?.id === contextPersonId ? child_person : parent_person;
          let sortString = `${otherPerson?.name}, ${otherPerson?.first_name}, ${otherPerson?.title_prefix}, ${otherPerson?.title_suffix}`;

          if (parent_person?.id === child_person?.id) {
            sortString = `${sortString}, ${location?.address_line}, ${location?.zip}, ${location?.city}`;
          }

          sortString = `${(parent_person?.id === contextPersonId || !contextPersonId) && parent_person?.default_person_link === id ? '0, ' : '1, '}${sortString}`;

          return sortString;
        },
        'type': ({parent_person, child_person}) => (
          getPersonLinkType(parent_person?.id, child_person?.id, contextPersonId)
        ),
      }}
      defaultSortField='contact'
      defaultSortOrder='asc'
      appliedFilters={appliedFilters}
      hideActionColumn
      showFooter
      {...props}
    />
  );
}
