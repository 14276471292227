import React from 'react';

import {store} from "../stores";
import {theme} from "./theme";
import {Provider} from "react-redux";
import {ThemeProvider} from "@mui/material/styles";

export function Providers({children}) {
  return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </Provider>
  );
}
