import {Grid} from "@mui/material";
import {YupField} from "../../../packages/react-hook-form-mui-yup-helpers";
import React from "react";
import {usePersonLinkFieldSuggestions} from "../../../features/addresses/addresses-hooks";

export function DefaultPersonLinkFormFields({children, databaseId}) {
  const typeSuggestions = usePersonLinkFieldSuggestions({databaseId, field: 'type'});

  return (
    <>
      <Grid item xs={12}>
        <YupField
          name="type"
          suggestions={typeSuggestions}
        />
      </Grid>
      {children}
      <Grid item xs={12}>
        <YupField name="email"/>
      </Grid>
      <Grid item md={6} xs={12}>
        <YupField name="phone"/>
      </Grid>
      <Grid item md={6} xs={12}>
        <YupField name="phone2"/>
      </Grid>
      <Grid item md={6} xs={12}>
        <YupField name="mobile"/>
      </Grid>
      <Grid item md={6} xs={12}>
        <YupField name="fax"/>
      </Grid>
    </>
  );
}

export const DEFAULT_PERSON_LINK_FORM_FIELDS = [
  'type',
  'phone',
  'phone2',
  'mobile',
  'fax',
  'email',
];
