import addressesReducers from './addresses-slice';
import {RELATED_OBJECT_DOCUMENT_VIEWERS} from "../../components/document/viewer/DocumentViewer";
import AddressDatabaseDocumentViewer from "../../components/addressdatabase/AddressDatabaseDocumentViewer";
export {default as saga} from './addresses-sagas';

export default {
  ...addressesReducers,
};

export * from './addresses-slice';

RELATED_OBJECT_DOCUMENT_VIEWERS['addressdatabase'] = AddressDatabaseDocumentViewer;
