import {useFormContext} from "react-hook-form";
import {useAddressesDatabase} from "../../../features/addresses/addresses-hooks";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {AddressTabPanel} from "../layout";
import {PeopleTable} from "../PeopleTable";
import {PERSON_FILTER_OPTIONS, usePersonName} from "../person";
import {YupFieldWithExpandableSelector} from "./YupFieldWithExpandableSelector";

export function PersonSelectorYupField({name, databaseId, mostRecentlyAddedPerson, entityName="Person", sx, expandedSelectorBoxProps: {sx: expandedSelectorBoxSx, ...expandedSelectorBoxProps}={}, allowInlineSelector, ...props}) {
  const {watch, setValue} = useFormContext();

  const selectedPerson = watch(name);
  const [showSelector, setShowSelector] = useState(false);

  // Hide selector whenever we have selected a value.
  useEffect(() => {
    setShowSelector(false);
  }, [selectedPerson]);

  const {people} = useAddressesDatabase({id: databaseId});
  const [appliedFilters, setAppliedFilters] = useState([]);

  // If a person is added while this field is rendered, it must have been performed using this field panel's onAdd
  // method, so we update the form's child_person to the recently added one.
  const [initialMostRecentlyAddedPerson, _] = useState(mostRecentlyAddedPerson);
  useEffect(() => {
    if (mostRecentlyAddedPerson && mostRecentlyAddedPerson !== initialMostRecentlyAddedPerson) {
      setValue('child_person', mostRecentlyAddedPerson, {shouldValidate: true});
    }
  }, [mostRecentlyAddedPerson]);

  const selectedPersonName = usePersonName({id: selectedPerson, showGender: true});

  const selectorPanel = (
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'row', minWidth: 500, ...expandedSelectorBoxSx}} {...expandedSelectorBoxProps}>
      <AddressTabPanel
        filterValue={appliedFilters}
        onFilter={setAppliedFilters}
        searchFieldPlaceholder={`${entityName} suchen...`}
        options={PERSON_FILTER_OPTIONS}
        sx={{...sx, minHeight: 'max(50vh, 350px)', flex: 1}}
        {...props}
      >
        <PeopleTable
          ids={people}
          appliedFilters={appliedFilters}
          selectedPerson={selectedPerson}
          onViewPerson={(id) => {
            setValue(name, id, {shouldValidate: true});
            setShowSelector(false);
          }}
          hideActionColumn
          showFooter={!selectedPerson}
        />
      </AddressTabPanel>
    </Box>
  );

  return (
    <YupFieldWithExpandableSelector
      name={name}
      dialogTitle={`${entityName} wählen`}
      selectorPanel={selectorPanel}
      selectedDisplayValue={selectedPersonName}
      showSelector={showSelector}
      onOpen={() => setShowSelector(true)}
      onClose={() => setShowSelector(false)}
      allowInlineSelector={allowInlineSelector}
    />
  );
}
