import {useFormContext} from "react-hook-form";
import {
  useAddressesPersonLink,
  usePersonRelatedLocations,
  useRenderedPersonLinkData
} from "../../../features/addresses/addresses-hooks";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {AddressTabPanel} from "../layout";
import {LocationsTable} from "../LocationsTable";
import {YupFieldWithExpandableSelector} from "./YupFieldWithExpandableSelector";
import {TemplateChoiceYupField} from "./TemplateChoiceYupField";
import {Grid} from "@mui/material";

export function LocationSelectorYupField({name, addressTemplateFieldName, databaseId, contextPersonLinkId, mostRecentlyAddedLocation, sx, expandedSelectorBoxProps: {sx: expandedSelectorBoxSx, ...expandedSelectorBoxProps}={}, address_template, parentPersonId, childPersonId, onAdd, type, allowInlineSelector, ...props}) {
  const {watch, setValue} = useFormContext();

  const selectedLocation = watch(name);
  const [showSelector, setShowSelector] = useState(false);

  const {parent_person: contextSourcePerson} = useAddressesPersonLink({id: contextPersonLinkId});
  const parentPerson = watch('parent_person');
  const locations = usePersonRelatedLocations({id: contextSourcePerson || parentPerson});

  const [appliedFilters, setAppliedFilters] = useState([]);

  // If a location is added while this field is rendered, it must have been performed using this field panel's onAdd
  // method, so we update the form's location to the recently added one.
  const [initialMostRecentlyAddedLocation, _] = useState(mostRecentlyAddedLocation);
  useEffect(() => {
    if (mostRecentlyAddedLocation && mostRecentlyAddedLocation !== initialMostRecentlyAddedLocation) {
      setValue('location', mostRecentlyAddedLocation, {shouldValidate: true});
    }
  }, [mostRecentlyAddedLocation]);

  const {renderedAddress} = useRenderedPersonLinkData({personLinkId: contextPersonLinkId, locationId: selectedLocation, address_template, parentPersonId, childPersonId, type});

  const selectorPanel = (
    <Box sx={{flex: 1, display: 'flex', flexDirection: 'row', minWidth: 500, ...expandedSelectorBoxSx}} {...expandedSelectorBoxProps}>
      <AddressTabPanel
        filterValue={appliedFilters}
        onFilter={setAppliedFilters}
        searchFieldPlaceholder="Adresse suchen..."
        sx={{...sx, minHeight: 'max(50vh, 350px)', flex: 1}}
        onAdd={onAdd && ((...args) => {
          setShowSelector(false);
          return onAdd(...args);
        })}
        {...props}
      >
        <LocationsTable
          ids={locations}
          appliedFilters={appliedFilters}
          selectedLocation={selectedLocation}
          onViewLocation={(id) => setValue(name, id, {shouldValidate: true})}
        />
      </AddressTabPanel>
    </Box>
  );

  return (
    <YupFieldWithExpandableSelector
      name={name}
      dialogTitle={`Adresse wählen`}
      selectorPanel={selectorPanel}
      selectedDisplayValue={(
        <Box component="span" sx={{whiteSpace: 'pre'}}>
          {renderedAddress}
        </Box>
      )}
      showSelector={showSelector}
      onOpen={() => setShowSelector(true)}
      onClose={() => setShowSelector(false)}
      dialogPostContent={addressTemplateFieldName && (
        <Grid item xs={12}>
          <TemplateChoiceYupField
            name={addressTemplateFieldName}
            label="Format"
            databaseId={databaseId}
            type="address"
          />
        </Grid>
      )}
      allowInlineSelector={allowInlineSelector}
    />
  );
}
