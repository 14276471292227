import {combineReducers} from "@reduxjs/toolkit";
import confirmationReducer from "./confirmation";
import dragDropReducer from "./drag-and-drop";
import dropzoneReducer from "./dropzone";
import helpReducer from "./help";
import listingReducer from "./listing";
import messageWriterReducer from "./message-writer";
import navigationReducer from "./navigation";
import progressReducer from "./progress";
import unloadBlocker from "./unload-blocker";
import viewReducer from "./view";
import visibilityTracker from "./visibility-tracker";

export default {
  ui: combineReducers({
    ...confirmationReducer,
    ...dragDropReducer,
    ...dropzoneReducer,
    ...helpReducer,
    ...listingReducer,
    ...messageWriterReducer,
    ...navigationReducer,
    ...progressReducer,
    ...unloadBlocker,
    ...viewReducer,
    ...visibilityTracker,
  }),
};
