import {EVENT_SCHEMA, LIST_SCHEMA} from "../validation";
import React from "react";
import AddressEntityFormDialog from "./AddressEntityFormDialog";
import {Grid} from "@mui/material";
import {YupField} from "../../../packages/react-hook-form-mui-yup-helpers";
import {useListFieldSuggestions} from "../../../features/addresses/addresses-hooks";

export function ListForm({id='edit-list', databaseId, editList, onClose, is_event, ...props}) {
  const whereSuggestions = useListFieldSuggestions({databaseId, field: 'where'});

  return (
    <AddressEntityFormDialog
      baseUrl="/api/addresses/lists/"
      databaseId={databaseId}
      id={id}
      entityId={editList}
      entityName={is_event ? "Event" : "Verteiler"}
      entityType="addresses_list"
      onClose={onClose}
      schema={is_event ? EVENT_SCHEMA : LIST_SCHEMA}
      renderDefaultChildren
      excludeDefaultChildren={[
        'name',
        'when',
        'where',
        'url',
      ]}
      allowDelete
      {...props}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <YupField name="name"/>
        </Grid>
        {is_event ? (
          <>
            <Grid item xs={12} sm={6}>
              <YupField name="when"/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <YupField
                name="where"
                suggestions={whereSuggestions}
              />
            </Grid>
            <Grid item xs={12}>
              <YupField name="url"/>
            </Grid>
          </>
        ) : null}
      </Grid>
    </AddressEntityFormDialog>
  );
}
