import React from 'react';

import {forEachMatchingElement, uimanager} from 'ui-manager';
import {ConfirmationDialog} from "../components/dialog";
import {getOrCreateRoot} from "../utils/renderer-utils";
import {Providers} from "../ui/providers";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-confirmation-manager]", node => {
    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <ConfirmationDialog/>
      </Providers>
    );
  })
);
