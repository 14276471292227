import React, {useEffect, useRef, useState} from "react";
import {
  Button,
  Chip, CircularProgress, Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import {
  useAddressesEntities,
  useAddressesList,
  useTemplateRendererGetter
} from "../../features/addresses/addresses-hooks";
import {AddressTabPanel} from "./layout";
import {SortableTable} from "./tables";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {getPersonName} from "./person";
import {renderToString} from "react-dom/server";
import {LoadingButton} from "@mui/lab";
import DoneIcon from '@mui/icons-material/Done';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import {SplitButton} from "../../packages/SplitButton";
import {arrayToCsv} from "../../packages/csv-utils";
import {DownloadButton} from "./DownloadButton";
import Dropzone from "../document/dropzone-view";
import Alert from "@mui/material/Alert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function ExportRowContent({id, columns, entities}) {
  const entity = entities?.filter(({id: entityId}) => entityId === id)[0];

  return (
    <>
      {columns.map(({field, sx}) => (
        <TableCell key={field} sx={sx}>
          {entity?.[field]}
        </TableCell>
      ))}
    </>
  );
}

function CopyToClipboardDialog({id="copy-to-clipboard-dialog", content, onClose}) {
  const textAreaRef = useRef();

  const [renders, setRenders] = useState(0);

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      textArea.select();

      // try to copy to clipboard
      let success;
      try {
        success = document.execCommand('copy');
      } catch (e) {
        // browser support seems to be missing
        success = false;
      }

      if (success) {
        onClose();
      }
    } else {
      // Check again soon - the will appear shortly.
      setTimeout(() => setRenders(renders + 1), 10);
    }
  }, [textAreaRef.current, renders]);

  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby={id}
    >
      <DialogTitle id={id}>
        In Zwischenablage kopieren
      </DialogTitle>

      <DialogContent>
        <textarea
          ref={textAreaRef}
          style={{width: '100%'}}
          rows={10}
          value={content}
          readOnly
        />
      </DialogContent>

      <DialogActions>
        {onClose ? (
          <Button
            onClick={onClose}
            color="primary"
          >
            Schließen
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

function CopyToClipboardButton({buildBlob, options, defaultOption, ...props}) {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  useEffect(() => {
    setSelectedOption(defaultOption);
  }, [defaultOption]);

  const selectedOptionData = options?.filter(({id}) => id === selectedOption)[0];

  const [isCopying, setIsCopying] = useState(false);
  const [copyResult, setCopyResult] = useState(undefined);

  const [showAsDialog, setShowAsDialog] = useState(null);

  const performCopyToClipboard = async () => {
    setIsCopying(true);

    // Try to request clipboard-write permission if provided by the browser.
    const permissionCheckResult = {};
    navigator.permissions.query({name: "clipboard-write"}).then((result) => {
      permissionCheckResult.hasPermission = (result.state === "granted" || result.state === "prompt");
    }).catch((e) => {
      console.warn(e);
    });

    let blob;
    try {
      blob = await buildBlob(selectedOption);
    } catch(e) {
      console.error(e);
      alert("Die Daten konnten nicht exportiert werden.");
    }

    try {
      if (permissionCheckResult.hasPermission !== false) {
        await navigator.clipboard.write([new ClipboardItem({[blob.type]: blob})]);
      } else {
        throw "missing clipboard-write permission";
      }
      setCopyResult(true);
    } catch (e) {
      try {
        if (blob.type === 'text/plain') {
          const content = await blob.text();
          setShowAsDialog(content);
          return;
        } else {
          throw "non-text blob";
        }
      } catch (e) {
        setCopyResult(false);
        alert("Die Daten konnten nicht in die Zwischenablage geschrieben werden. Diese Funktion wird von Ihrem Browser anscheinend nicht unterstützt.");
      }
    }
    setIsCopying(false);

    setTimeout(() => {
      setCopyResult(undefined);
    }, 1000);
  };

  const loading = isCopying || copyResult !== undefined;

  return (
    <SplitButton
      options={options}
      selectedOption={selectedOption}
      onSelectOption={setSelectedOption}
      disabled={loading}
      menuListId="copy-to-clipboard-split-button-menu"
      aria-label="copy to clipboard split button"
      choiceAriaLabel="select copy to clipboard strategy"
    >
      <LoadingButton
        onClick={performCopyToClipboard}
        loading={loading}
        loadingIndicator={isCopying ? undefined : copyResult ? <DoneIcon/> : <ReportProblemIcon/>}
        {...props}
      >
        {selectedOptionData?.caption}
      </LoadingButton>
      {showAsDialog ? (
        <CopyToClipboardDialog
          content={showAsDialog}
          onClose={() => {
            setShowAsDialog(null);
            setCopyResult(true);
            setIsCopying(false);

            setTimeout(() => {
              setCopyResult(undefined);
            }, 1000);
          }}
        />
      ) : null}
    </SplitButton>
  );
}


export function ListExportDialog({singleListId, listEntryIds, open, onClose, excludedItems, name, isEvent, id = "list_export_dialog"}) {
  const {when, where, url} = useAddressesList({id: singleListId});

  let columns = [];

  const [showName, setShowName] = useState(false);
  if (showName) {
    columns.push({field: 'name', name: "Name"});
  }

  const [showType, setShowType] = useState(false);
  if (showType) {
    columns.push({field: 'type', name: "Funktion"});
  }

  const [showOrg, setShowOrg] = useState(false);
  if (showOrg) {
    columns.push({field: 'org', name: "Organisation"});
  }

  const [showSalutation, setShowSalutation] = useState(false);
  if (showSalutation) {
    columns.push({field: 'salutation', name: "Anrede"});
  }

  const [showAddress, setShowAddress] = useState(false);
  if (showAddress) {
    columns.push({field: 'address', name: "Adresse", sx: {whiteSpace: 'pre'}});
  }

  const [showEmail, setShowEmail] = useState(true);
  if (showEmail) {
    columns.push({field: 'email', name: "E-Mail"});
  }

  const [showTel, setShowTel] = useState(false);
  if (showTel) {
    columns.push({field: 'tel', name: "Telefon", sx: {whiteSpace: 'pre'}});
  }

  const [showConfirmed, setShowConfirmed] = useState(false);
  if (showConfirmed) {
    columns.push({field: 'confirmed', name: "Zusage"});
  }

  const entities = useAddressesEntities({
    type: 'addresses_list_entry',
    expandedFields: ['person_link', 'person_link.child_person', 'person_link.parent_person', 'person_link.location'],
    ids: listEntryIds,
  });

  const getTemplateRenderer = useTemplateRendererGetter();

  const data = entities?.map(({id, ...data}) => ({
    id,
    name: showName ? getPersonName(data?.person_link?.child_person) : undefined,
    type: showType ? data?.person_link?.type : undefined,
    org: showOrg && (data?.person_link?.parent_person?.id !== data?.person_link?.child_person?.id) ? getPersonName(data?.person_link?.parent_person) : undefined,
    salutation: showSalutation ? getTemplateRenderer(data?.person_link?.salutation_template)(data?.person_link) : undefined,
    address: showAddress ? getTemplateRenderer(data?.person_link?.address_template)(data?.person_link) : undefined,
    email: showEmail ? data?.person_link?.email : undefined,
    tel: showTel ? ([
      data?.person_link?.phone ? `Tel: ${data?.person_link?.phone}` : undefined,
      data?.person_link?.phone2 ? `Tel: ${data?.person_link?.phone2}` : undefined,
      data?.person_link?.fax ? `Fax: ${data?.person_link?.fax}` : undefined,
      data?.person_link?.mobile ? `Mobil: ${data?.person_link?.mobile}` : undefined,
    ].filter(x => x).join('\n')) : undefined,
    confirmed: showConfirmed ? (data?.confirmed ? "ja" : "nein") : undefined,
  }));

  const hasParticipants = entities?.some(({confirmed}) => confirmed === true);
  const hasNonParticipants = entities?.some(({confirmed}) => confirmed === false);

  const participationListName = (hasParticipants && hasNonParticipants) ? "Zusagen und Absagen" : hasParticipants ? "Teilnehmerliste" : hasNonParticipants ? "Absagen" : "Liste";

  // Dedupe data.
  const observedItems = new Set();
  const dataById = {};
  const dedupedData = data?.filter(({id, ...data}) => {
    // Skip empty entities.
    if (Object.values(data).filter(value => value).length === 0) {
      return false;
    }

    // Skip duplicates.
    const serializedData = JSON.stringify(data);
    if (observedItems.has(serializedData)) {
      return false;
    }
    observedItems.add(serializedData);
    dataById[id] = data;

    return true;
  });

  const [appliedFilters, setAppliedFilters] = useState([]);
  const [displayedIds, setDisplayedIds] = useState(listEntryIds);
  const isFiltered = displayedIds?.length !== dedupedData?.length;

  const emailAddresses = columns?.filter(({field}) => field === 'email').length > 0 ? dedupedData?.map(({email}) => email).filter(email => email) : undefined;

  const buildBlob = async (type) => {
    const displayedData = displayedIds?.map(id => dataById[id]);
    let blob;

    switch (type) {
      case "list":
        const field = columns[0].field;
        const text = displayedData?.map(row => row?.[field]).join("\n");
        blob = new Blob([text], {type: "text/plain"});
        blob.name = `${name}.txt`;
        break;

      case "json":
        const jsonData = displayedData?.map((row, i) => (
          Object.fromEntries(columns?.map(({field, name}) => ([
            name,
            row?.[field]
          ])))
        ));
        const json = JSON.stringify(jsonData);
        blob = new Blob([json], {type: "application/json"});
        blob.name = `${name}.json`;
        break;

      case "csv":
        const array = [
          columns?.map(({name}) => name),
          ...displayedData?.map((row) => (
            columns?.map(({field}) => row?.[field])
          )),
        ];
        const csv = arrayToCsv(array);
        blob = new Blob([csv], {type: "text/csv"});
        blob.name = `${name}.csv`;
        break;

      case "table":
        const html = renderToString(
          <table>
            <thead>
              <tr>
                {columns?.map(({field, name}) => (
                  <th key={field}>{name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {displayedData?.map((row, i) => (
                <tr key={i}>
                  {columns?.map(({field}) => (
                    <td key={field}>{row?.[field]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        );
        blob = new Blob([html], {type: "text/html"});
        blob.name = `${name}.html`;
        break;

      case "participation_list":
        const participationList = renderToString(
          <html lang="de">
          <head>
              <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
              <title>{`${participationListName} ${name}`}</title>
          </head>
            <body style={{
              fontFamily: 'sans-serif',
            }}>
              <h1 style={{textAlign: 'center'}}>{participationListName}</h1>
              <h2 style={{textAlign: 'center', marginBottom: 0}}>{name}</h2>
              {when && where ? (
                <h2 style={{textAlign: 'center', marginTop: 0}}>{when} &ndash; {where}</h2>
              ) : when ? (
                <h2 style={{textAlign: 'center', marginTop: 0}}>{when}</h2>
              ) : where ? (
                <h2 style={{textAlign: 'center', marginTop: 0}}>{where}</h2>
              ) : null}
              {url ? (
                <h3 style={{textAlign: 'center'}}>{url}</h3>
              ) : null}
              <ol>
                {displayedData?.map((row, i) => (
                  <li key={i} style={{margin: 16, breakInside: 'avoid'}}>
                    {row?.name !== undefined ? (
                      <div style={{whiteSpace: 'pre'}}>
                        {row?.name}
                        {row?.type ? (
                          <>
                            {' '}
                            &ndash;
                            {' '}
                            {row?.type}
                          </>
                        ) : null}
                      </div>
                    ) : row?.type ? (
                      <div>
                        {row?.type}
                      </div>
                    ) : null}
                    {row?.org ? (
                      <div style={{whiteSpace: 'pre'}}>
                        {row?.org}
                      </div>
                    ) : null}

                    {row?.salutation ? (
                      <div style={{whiteSpace: 'pre', marginTop: 8, marginBottom: 8}}>
                        {row?.salutation}
                      </div>
                    ) : null}

                    {row?.address ? (
                      <div style={{whiteSpace: 'pre', marginTop: 8, marginBottom: 8}}>
                        {row?.address}
                      </div>
                    ) : null}

                    {(row?.email || row?.tel) ? (
                      <div style={{whiteSpace: 'pre', marginTop: 8, marginBottom: 8}}>
                        {row?.email ? (
                          <div>
                            E-Mail: {row?.email}
                          </div>
                        ) : null}
                        {row?.tel}
                      </div>
                    ) : null}

                    {row?.confirmed !== undefined ? (
                      <div style={{marginTop: 8}}>
                        {row?.confirmed === "ja" ? "nimmt teil" : "nimmt nicht teil"}
                      </div>
                    ) : null}
                  </li>
                ))}
              </ol>
            </body>
          </html>
        );
        blob = new Blob([participationList], {type: "text/html"});
        blob.name = `${name}.html`;
        break;

      default:
        throw `unsupported buildBlob type: ${type}`;
    }

    return blob;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby={id}
      maxWidth='lg'
      fullWidth
    >
      <DialogTitle id={id}>
        {excludedItems ? (
          <>
            Teil-Datenexport aus {name}
            {' '}
            <Chip
              label={(
                <>
                  enthält {listEntryIds?.length} von {listEntryIds?.length + excludedItems} {isEvent ? "Teilnehmern" : "Kontakten"}
                </>
              )}
              size="small"
              color="warning"
            />
          </>
        ) : (
          <>
            Datenexport aus {name}
            {' '}
            <Chip
              label={(
                <>
                  {listEntryIds?.length} {isEvent ? "Teilnehmer" : "Kontakte"}
                </>
              )}
              size="small"
            />
          </>
        )}
      </DialogTitle>

      <DialogContent>
        <FormControlLabel
          label="Name"
          control={(
            <Checkbox
              checked={showName}
              onChange={() => setShowName(!showName)}
            />
          )}
        />
        <FormControlLabel
          label="Funktion"
          control={(
            <Checkbox
              checked={showType}
              onChange={() => setShowType(!showType)}
            />
          )}
        />
        <FormControlLabel
          label="Organisation"
          control={(
            <Checkbox
              checked={showOrg}
              onChange={() => setShowOrg(!showOrg)}
            />
          )}
        />
        <FormControlLabel
          label="Anrede"
          control={(
            <Checkbox
              checked={showSalutation}
              onChange={() => setShowSalutation(!showSalutation)}
            />
          )}
        />
        <FormControlLabel
          label="Adresse"
          control={(
            <Checkbox
              checked={showAddress}
              onChange={() => setShowAddress(!showAddress)}
            />
          )}
        />

        <FormControlLabel
          label="E-Mail"
          control={(
            <Checkbox
              checked={showEmail}
              onChange={() => setShowEmail(!showEmail)}
            />
          )}
        />
        <FormControlLabel
          label="Telefon"
          control={(
            <Checkbox
              checked={showTel}
              onChange={() => setShowTel(!showTel)}
            />
          )}
        />

        {isEvent ? (
          <FormControlLabel
            label="Zusage"
            control={(
              <Checkbox
                checked={showConfirmed}
                onChange={() => setShowConfirmed(!showConfirmed)}
              />
            )}
          />
        ) : null}

        <Box sx={{flex: 1, display: 'flex', flexDirection: 'row', mx: -3}}>
          <AddressTabPanel
            filterValue={appliedFilters}
            onFilter={setAppliedFilters}
            sx={{flex: 1, minHeight: 'max(50vh, 300px)'}}
            searchFieldPlaceholder={"Eintrag suchen..."}
          >
            <SortableTable
              entities={dedupedData}
              entityNameSingular={dedupedData?.length === data?.length ? "Datensatz" : "Datensatz (ohne Duplikate und leere Einträge)"}
              entityNamePlural={dedupedData?.length === data?.length ? "Datensätze" : "Datensätze (ohne Duplikate und leere Einträge)"}
              onChangeIdsList={setDisplayedIds}
              itemContent={(index, id) => (
                <ExportRowContent
                  id={id}
                  columns={columns}
                  entities={dedupedData}
                />
              )}
              columns={columns}
              appliedFilters={appliedFilters}
              hideActionColumn
              nonVirtual
              showFooter
            />
          </AddressTabPanel>
        </Box>
      </DialogContent>

      <DialogActions>
        {!isFiltered && emailAddresses?.length > 0 && (
          <Button
            color="primary"
            onClick={async () => {
              location.href = "mailto:?bcc=" + emailAddresses.join(',');
            }}
            variant="outlined"
          >
            E-Mail erstellen
          </Button>
        )}
        {!isFiltered && displayedIds?.length > 0 && (
          <>
            <CopyToClipboardButton
              color="primary"
              buildBlob={buildBlob}
              variant="outlined"
              options={[
                {
                  id: 'table',
                  name: "Tabelle",
                  caption: "Tabelle in Zwischenablage kopieren",
                },
                {
                  id: 'list',
                  name: "Liste",
                  caption: "Liste in Zwischenablage kopieren",
                  disabled: columns?.length > 1,
                },
              ]}
              defaultOption={columns?.length === 1 ? "list" : "table"}
            />
            <DownloadButton
              color="primary"
              buildBlob={buildBlob}
              variant="outlined"
              options={[
                {
                  id: 'csv',
                  name: "CSV",
                  caption: "als CSV herunterladen",
                },
                {
                  id: 'json',
                  name: "JSON",
                  caption: "als JSON herunterladen",
                },
                ...(isEvent ? [{
                  id: 'participation_list',
                  name: participationListName,
                  caption: `${participationListName} herunterladen`,
                }] : []),
              ]}
              defaultOption={isEvent ? "participation_list" : "csv"}
            />
          </>
        )}
        <Button
          color="inherit"
          onClick={onClose}
        >
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
