import addressesReducers from "./addresses";
import configReducers from "./config";
import e2eeReducers from "./e2ee";
import entitiesReducers from "./entities";
import decryptedEntityReducers from "./decryptedEntity";
import extrasReducers from "./extras";
import sessionReducers from "./session";
import uiReducers from "./ui";

export default {
  ...addressesReducers,
  ...configReducers,
  ...e2eeReducers,
  ...entitiesReducers,
  ...decryptedEntityReducers,
  ...extrasReducers,
  ...sessionReducers,
  ...uiReducers,
};
