import {forEachMatchingElement, uimanager} from 'ui-manager';

import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "@mui/material";
import "components/icons/fontawesome";
import {getOrCreateRoot} from "../utils/renderer-utils";
import {Providers} from "../ui/providers";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-react-fa-icon]", node => {
    const props = JSON.parse(node.dataset.reactFaIcon);
    let icon = <FontAwesomeIcon {...props}/>;
    if (props.tooltip) {
      icon = <Tooltip title={props.tooltip} arrow><span>{icon}</span></Tooltip>;
    }

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        {icon}
      </Providers>
    );
  })
);
