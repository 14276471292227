import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Popover} from "@mui/material";

function PopOverSurface({children, content, open, onClose}) {
  const anchorEl = React.useRef(null);

  return (
    <div ref={anchorEl}>
      {children}
      <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div
          style={{
            marginTop: "-7px",
            padding: "15px",
            paddingTop: "5px",
            paddingBottom: "5px",
            maxWidth: "350px",
            zIndex: 10000,
          }}
        >
          <IconButton
            aria-label="Close"
            style={{position: 'absolute', right: '5px', top: '5px'}}
            onClick={onClose}
          >
            <FontAwesomeIcon icon={['fas', 'times']} style={{minWidth: '1em'}}/>
          </IconButton>
          {content}
        </div>
      </Popover>
    </div>
  );
}

export class PopOverButton extends React.Component {
  static propTypes = {
    content: PropTypes.node.isRequired,
    icon: PropTypes.node.isRequired,
    loadTimeoutMs: PropTypes.number,

    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    tabIndex: 0,

    onOpen: () => {
    },
    onClose: () => {
    },
  };

  state = {
    open: false,
    loadingTimedOut: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {open} = this.state;
    const prevOpen = prevState.open;

    if (open && !prevOpen) {
      // Reset loading timeouts.
      clearTimeout(this.loadTimeout);
      this.setState({loadingTimedOut: false});

      // Make sure that surface is displayed after timeout, irrespective of whether loading has finished.
      const {loadTimeoutMs} = this.props;
      if (loadTimeoutMs) {
        this.loadTimeout = setTimeout(() => {
          this.setState({loadingTimedOut: true});
        }, loadTimeoutMs);
      }
    }

    // Trigger events.
    const {onOpen, onClose} = this.props;
    if (open && !prevOpen) {
      onOpen();
    } else if (!open && prevOpen) {
      onClose();
    }
  }

  render() {
    const {content, loading, label, icon, loadTimeoutMs, onOpen, onClose, className, ...props} = this.props;
    const {open, loadingTimedOut} = this.state;

    return (
      <PopOverSurface
        content={content}
        open={open && (!loading || loadingTimedOut)}
        onClose={() => this.setState({open: false})}
      >
        <IconButton
          className={className}
          aria-label={label}
          onClick={() => this.setState(({open}) => ({open: !open}))}
          {...props}
        >
          {open && loading ? (
            <FontAwesomeIcon icon={['fas', 'spinner']} spin/>
          ) : (
            icon
          )}
        </IconButton>
      </PopOverSurface>
    );
  }
}
