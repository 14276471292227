import {createSimpleAPICall} from "./api-helpers";

export const fetchDocuments = createSimpleAPICall({
  apiName: 'listDocuments',
  lookupField: 'caseId',
  createPayload: action => ({
    case_id: action.payload.caseId,
    page: action.payload.page,
    cursor: action.payload.cursor,
    page_size: action.payload.pageSize,
    expand: 'viewer_read',
    ordering: action.payload.ordering,
    filterId: action.payload.filterId,
    pinned: action.payload.pinned,
  }),
  throwErrors: true,
  //successAction: ({case_id, filterId, result}) => announceFilteredIdsExcerpt({case_id, filterId, ...result}),
});

export const ENTITY_FETCHERS = {
  document: fetchDocuments,
};
