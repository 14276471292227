import React from "react";
import {renderTemplate} from "../../features/addresses/addresses-hooks";

export const EXAMPLE_PERSON_LINK = {
  type: "Geschäftsführerin",
  child_person: {
    type: 'female',
    first_name: 'Maria',
    name: 'Musterfrau',
    title_prefix: 'Prof. Dr.',
    title_suffix: 'LL. M.',
  },
  parent_person: {
    gender: 'company',
    name: 'Musterfirma GmbH',
  },
  location: {
    address_line: "Musterstraße 1",
    zip: "4711",
    city: "Musterstadt",
  },
};

export function useTemplateExample({template}) {
  return renderTemplate(template, EXAMPLE_PERSON_LINK);
}
