import React from 'react';

import {forEachMatchingElement, uimanager} from 'ui-manager';
import {store} from "../stores";
import {TextDocumentWriter} from "../components/document/writer";
import {viewDocument} from "../features/entities/document";
import {updateListing} from "../features/ui/listing";
import {
  DocumentContent,
  DocumentDownloadButton,
  DocumentProgress,
  DocumentsTable,
  DocumentStatus, DocumentViewButton,
  RecentlyUploadedDocumentsTable,
  UploadDocumentsButton
} from "../components/document";
import {Pagination} from "../components/paginator";
import {BulkUploaderDocuments} from "../components/document/bulk-uploader";
import {CaseUploadSummary} from "../components/case";
import BulkUploaderSummary from "../components/document/bulk-uploader/bulk-uploader-summary-redux";
import {createDocumentDraftsFromFiles} from "../events/document";
import BulkUploaderCaseNumber from "../components/document/bulk-uploader/bulk-uploader-case-number-redux";
import {DocumentDetailsMenu} from "../components/document/menu";
import PinnedDocumentsTable from "../components/document/PinnedDocumentsTable";
import DocumentViewer from "../components/document/viewer/DocumentViewer";
import {getOrCreateRoot} from "../utils/renderer-utils";
import {Providers} from "../ui/providers";

uimanager.add(el =>
  forEachMatchingElement(el, "div[data-document-details-menu]", node => {
    const {document_id, case_id} = JSON.parse(node.dataset.documentDetailsMenu);

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <DocumentDetailsMenu documentId={document_id} caseId={case_id}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "div[data-document-viewer]", node => {
    const {document_id, case_id} = JSON.parse(node.dataset.documentViewer);

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <DocumentViewer id={document_id} caseId={case_id}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "div[data-document-download-button]", node => {
    const {document_id} = JSON.parse(node.dataset.documentDownloadButton);

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <DocumentDownloadButton documentId={document_id}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "div[data-document-view-button]", node => {
    const {document_id} = JSON.parse(node.dataset.documentViewButton);

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <DocumentViewButton documentId={document_id}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-document-status]", node => {
    const {document_id} = JSON.parse(node.dataset.documentStatus);

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <DocumentStatus documentId={document_id}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-document-content]", node => {
    const {document_id} = JSON.parse(node.dataset.documentContent);

    store.dispatch(viewDocument({documentId: document_id}));

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <DocumentContent documentId={document_id}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-document-progress]", node => {
    const {document_id} = JSON.parse(node.dataset.documentProgress);

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <DocumentProgress documentId={document_id}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-listing]", node => {
    const {id, currentPage, count, ...other} = JSON.parse(node.dataset.listing);
    const {caseId} = other;

    if (currentPage !== undefined) {
      try {
        store.dispatch([
          updateListing({
            id,
            meta: other,
            count,
            currentPage,
            ordering: ['-creation_date'],
            pageSize: 30,
            searchQuery: '',
            entityType: 'document',
          }),
        ]);
      } catch (e) {
        console.error(e);
      }
    }

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <CaseUploadSummary caseId={caseId} listingId={id}/>
        <DocumentsTable {...other} listingId={id}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-pinned-listing]", node => {
    const {id, currentPage, count, ...other} = JSON.parse(node.dataset.pinnedListing);
    const {caseId} = other;

    if (currentPage !== undefined) {
      try {
        store.dispatch([
          updateListing({
            id,
            meta: other,
            count,
            currentPage,
            ordering: ['-creation_date'],
            pageSize: 30,
            searchQuery: '',
            entityType: 'document',
          }),
        ]);
      } catch (e) {
        console.error(e);
      }
    }


    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <PinnedDocumentsTable {...other} listingId={id}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-bulk-uploader]", node => {
    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <BulkUploaderDocuments/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-bulk-uploader-summary]", node => {
    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <BulkUploaderSummary/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-bulk-uploader-case-number]", node => {
    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <BulkUploaderCaseNumber/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-pagination]", node => {
    const listingId = node.dataset.pagination;

    const listItems = [...node.querySelectorAll('ul li')];
    const existingContent = node.querySelector('ul');

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <Pagination listingId={listingId} existingListItems={listItems} existingContent={existingContent}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-recently-uploaded-documents-table]", node => {
    const caseId = node.dataset.recentlyUploadedDocumentsTable;

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <RecentlyUploadedDocumentsTable caseId={caseId}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-upload-documents-button]", node => {
    const caseId = node.dataset.uploadDocumentsButton;

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <UploadDocumentsButton caseId={caseId}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-document-message-writer]", node => {
    const {case_id} = JSON.parse(node.dataset.documentMessageWriter);

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <TextDocumentWriter caseId={case_id}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-allow-document-upload]", node => {
    if (node.processed) {
      return;
    }
    node.processed = true;

    const {caseId} = JSON.parse(node.dataset.allowDocumentUpload);
    node.addEventListener('drop', evt => {
      const files = evt.dataTransfer.files;
      createDocumentDraftsFromFiles({files, caseId, startUpload: true}, store.dispatch).then(() => {
      });
    });
  })
);
