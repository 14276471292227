import React, {useRef, useState} from "react";
import {Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Dropzone from "../document/dropzone-view";
import Alert from "@mui/material/Alert";
import {Box} from "@mui/material";
import {COMPACT_ADDRESSES_DATABASE} from "../../api/api-schemas";
import {useDecryptedEntityApi,} from "../../features/decryptedEntity/decrypted-entity-hooks";
import _ from "lodash";

export function ImportDialog({id, databaseId, open, onClose}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const entityApi = useDecryptedEntityApi(COMPACT_ADDRESSES_DATABASE);

  const importAction = async (files) => {
    setError(null);
    setIsProcessing(true);

    const fail = msg => {
      setError(msg);
      setIsProcessing(false);
    };

    // Sanity checks.
    if (files?.length !== 1) {
      return fail("Es darf nur genau eine Datei importiert werden.");
    }

    // Read JSON data.
    let database;
    try {
      const text = await files[0].text();
      database = JSON.parse(text);
    } catch (e) {
      console.error(e);
      return fail(`Die Datei konnte nicht verarbeitet werden: ${e}`);
    }

    let response;
    try {
      response = await entityApi.put(
        `/api/addresses/databases/${databaseId}/`,
        database,
        // {
        //   createEntities: true,
        // },
      );

      if (response?.data?.status !== 'import_successful') {
        throw "Ein unerwarteter Fehler ist aufgetreten.";
      }
    } catch (e) {
      if (e?.name === "ValidationError") {
        const data = e?.data;
        let msg;
        if (_.isArray(data)) {
          msg = data.join("\n");
        } else if (_.isString(data)) {
          msg = data;
        }

        if (msg) {
          return fail(msg);
        }
      }

      console.error(e);
      return fail(`Die Adressdatenbank konnte nicht importiert werden: ${e?.message || e}`);
    }

    // Fetch created database.
    setIsProcessing("loading");
    await entityApi.get(
      `/api/addresses/databases/${databaseId}/`,
      database,
    );

    return onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={(evt) => {
        if (isProcessing) {
          return false;
        }
        return onClose(evt);
      }}
      aria-labelledby={id}
    >
      <DialogTitle id={id}>
        Adressdatenbank importieren
      </DialogTitle>

      {isProcessing ? (
        <Box sx={{textAlign: 'center'}}>
          <CircularProgress size='3rem'/><br/>
          {isProcessing === "loading" ? (
            <>
              Import erfolgreich.<br/>
              Lade importierte Datenbank...
            </>
          ) : (
            <>
              wird importiert...
            </>
          )}
        </Box>
      ) : (
        <Box sx={{px: 2}}>
          <table style={{width: '100%'}}>
            <tbody>
              <Dropzone
                isVisible
                isPermanentlyVisible
                caption="Adressdatenbank-Export hier hineinziehen"
                buttonCaption="Datei vom Computer auswählen"
                uploadFiles={importAction}
                style={{padding: 10}}
              />
            </tbody>
          </table>
        </Box>
      )}

      <Collapse in={!!error}>
        <DialogContent>
          <Alert severity="error" variant="filled" icon={!!error ? undefined : false}>
            {error}
          </Alert>
        </DialogContent>
      </Collapse>

      <DialogActions>
        <Button
          color="inherit"
          onClick={onClose}
          disabled={!!isProcessing}
        >
          Abbrechen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
