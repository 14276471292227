import React, {useState} from 'react';
import {
  useAddressesListEntry,
  useAddressesPersonLink
} from "../../features/addresses/addresses-hooks";
import {DeletedCells, ViewableTableCell, ViewActionCell} from "./layout";
import {SortableAddressesEntitiesTable} from "./tables";
import {Chip, Switch} from "@mui/material";
import Box from "@mui/material/Box";
import {useDecryptedEntityApi} from "../../features/decryptedEntity/decrypted-entity-hooks";
import {ENTITY_LIST_URLS, ENTITY_TYPES} from "../../api/api-schemas";
import {AlphabeticalPersonName} from "./person";
import {useSelector} from "react-redux";
import {getAddressesListEntryGetter} from "../../features/addresses";

function ListRowContent({id, onClick, children}) {
  const {person_link: personLinkId, isDeleted} = useAddressesListEntry({id, observe: true});
  const {parent_person: parentPersonId, child_person: childPersonId, type} = useAddressesPersonLink({
    id: personLinkId,
    observe: true
  });
  // const view = () => onSelect(id);

  if (isDeleted) {
    return (
      <DeletedCells colSpan="3">
        Dieser Eintrag wurde gelöscht.
      </DeletedCells>
    );
  }

  return (
    <>
      <ViewableTableCell view={onClick}>
        <AlphabeticalPersonName id={childPersonId}/>
        {type && (
          <>
            {' '}<Chip size="small" label={type}/>
          </>
        )}
        {parentPersonId !== childPersonId && (
          <Box sx={{color: '#aaaaaa'}}>
            <AlphabeticalPersonName id={parentPersonId}/>
          </Box>
        )}
      </ViewableTableCell>
      {children}
      <ViewActionCell view={onClick}/>
    </>
  );
}

function EventRowContent({id, onClick, readOnly, ...props}) {
  const {confirmed} = useAddressesListEntry({id});

  const entityApi = useDecryptedEntityApi(ENTITY_TYPES['addresses_list_entry']);
  const baseUrl = ENTITY_LIST_URLS['addresses_list_entry'];

  const [updating, setUpdating] = useState(false);

  const update = async (validatedData) => {
    const {id} = validatedData;

    setUpdating(true);

    try {
      await entityApi.patch(
        `${baseUrl}${id}/`,
        validatedData,
      );
    } finally {
      setUpdating(false);
    }
  };

  return (
    <ListRowContent
      id={id}
      onClick={onClick}
      {...props}
    >
      <ViewableTableCell>
        <Switch
          key={id}
          checked={confirmed}
          disabled={updating}
          onClick={!readOnly ? (() => update({id, confirmed: !confirmed})) : null}
        />
      </ViewableTableCell>
    </ListRowContent>
  );
}

export function ListTable({ids, isEvent, onSelectItem, appliedFilters, readOnly, ...props}) {
  const columns = [
    {field: 'contact', name: "Kontakt"},
  ];

  if (isEvent) {
    columns[0].name = "Teilnehmer";
    columns.push({field: 'confirmed', name: "Zusage", sx: {width: 86}});
  }

  const RowContent = isEvent ? EventRowContent : ListRowContent;

  const getListEntry = useSelector(getAddressesListEntryGetter);
  let confirmedCount = 0;
  if (isEvent) {
    confirmedCount = ids?.map(getListEntry)?.filter(({confirmed}) => confirmed)?.length;
  }

  return (
    <SortableAddressesEntitiesTable
      entityType="addresses_list_entry"
      entityNameSingular={isEvent ? "Teilnehmer" : "Kontakt"}
      entityNamePlural={isEvent ? "Teilnehmer" : "Kontakte"}
      expandedEntityFields={['person_link', 'person_link.child_person', 'person_link.parent_person']}
      ids={ids}
      itemContent={(index, id) => (
        <RowContent
          id={id}
          onClick={onSelectItem && (() => onSelectItem(id))}
          readOnly={readOnly}
        />
      )}
      columns={columns}
      searchFields={[
        'person_link.child_person.name',
        'person_link.child_person.first_name',
        'person_link.child_person.title_prefix',
        'person_link.child_person.title_suffix',
        'person_link.parent_person.name',
        'person_link.parent_person.first_name',
        'person_link.parent_person.title_prefix',
        'person_link.parent_person.title_suffix',
        'person_link.type',
      ]}
      sortFieldGetters={{
        'contact': ({person_link: {child_person: {name, first_name, title_prefix, title_suffix}}}) =>
          `${name}, ${first_name}, ${title_prefix}, ${title_suffix}`,
      }}
      defaultSortField='contact'
      defaultSortOrder='asc'
      appliedFilters={appliedFilters}
      showFooter
      additionalFooterText={isEvent ? (
        <>
          {' '}({confirmedCount} {confirmedCount === 1 ? "Zusage" : "Zusagen"})
        </>
      ) : null}
      {...props}
    />
  );
}
