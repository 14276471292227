import React from 'react';

import {forEachMatchingElement, uimanager} from 'ui-manager';
import {store} from "../stores";
import {CaseDataContainer} from "../components/case";
import {bytesToUint8Array, stringToBytes} from "../packages/binary-data-helpers";
import {createDocumentDraftFromFile} from "../events/document";
import {updateListing} from "../features/ui/listing";
import {getOrCreateRoot} from "../utils/renderer-utils";
import {Providers} from "../ui/providers";

uimanager.add(el =>
  forEachMatchingElement(el, "[data-case-field]", node => {
    const {id, field} = JSON.parse(node.dataset.caseField);

    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <CaseDataContainer caseId={id} field={field}/>
      </Providers>
    );
  })
);

uimanager.add(el =>
  forEachMatchingElement(el, "[data-message-writer-form]", node => {
    const caseId = node.dataset.messageWriterForm;

    node.querySelectorAll('form').forEach(form => {
      form.addEventListener('submit', event => {
        event.preventDefault();

        let $message_writer = $(node);

        // fetch description and content from respective input fields
        let description = $message_writer.find('input[name="description"]').val();
        let content = $message_writer.find('textarea[name="content"]').val().replace(/\r?\n/g, '\r\n');

        if (description || content) {
          // generate appropriate filename
          let date = new Date();
          let month = date.getMonth() + 1;
          month = month < 10 ? '0' + month : month;
          let day = date.getDate();
          day = day < 10 ? '0' + day : day;
          let hours = date.getHours();
          hours = hours < 10 ? '0' + hours : hours;
          let minutes = date.getMinutes();
          minutes = minutes < 10 ? '0' + minutes : minutes;
          let now_str = day + '.' + month + '.' + date.getFullYear() + ' ' + hours + ':' + minutes;
          let filename = 'Textnachricht vom ' + now_str + '.txt';

          // generate blob
          let blob = new Blob([
            bytesToUint8Array(stringToBytes(content))
          ], {
            type: 'text/plain'
          });
          blob.name = filename;
          blob.description = description;
          blob.charset = 'utf8';

          // add blob like any other document
          store.dispatch(updateListing({id: 'main', currentPage: 1}));
          createDocumentDraftFromFile({blob, caseId, startUpload: true}, store.dispatch);
        }

        // hide message writer modal dialogue
        $message_writer.modal('hide');
        $message_writer.find('input, textarea').val('');
      });
    });
  })
);
