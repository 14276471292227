import React, {useState} from 'react';
import {AddressTabPanel} from "./layout";
import {PeopleTable} from "./PeopleTable";
import {useAddressesDatabase} from "../../features/addresses/addresses-hooks";
import {PERSON_FILTER_OPTIONS} from "./person";


export function PeoplePanel({databaseId, onEditPerson, onShowPerson}) {
  const {people} = useAddressesDatabase({id: databaseId});

  const [appliedFilters, setAppliedFilters] = useState([]);

  return (
    <AddressTabPanel
      filterValue={appliedFilters}
      onFilter={setAppliedFilters}
      onAdd={onEditPerson ? () => onEditPerson(-1) : null}
      searchFieldPlaceholder="Person suchen..."
      options={PERSON_FILTER_OPTIONS}
    >
      <PeopleTable
        ids={people}
        onViewPerson={onShowPerson}
        appliedFilters={appliedFilters}
      />
    </AddressTabPanel>
  );
}
