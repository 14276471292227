import {connect} from "react-redux";
import {
  getDocument,
  isDocumentRead,
  markDocumentRead,
  markDocumentUnread,
  saveDocument
} from "../../../features/entities/document";
import {getURL} from "../../../features/ui/navigation";
import DocumentDetailsMenu from "./document-details-menu-view";

const mapStateToProps = (state, {documentId, caseId}) => {
  const {can_update, can_delete, can_transfer, has_file} = getDocument(documentId)(state);

  return {
    menuType: 'detail',
    isRead: isDocumentRead(documentId)(state),
    updateURL: can_update ? getURL('document_update', {case: caseId, document: documentId})(state) : undefined,
    deleteURL: can_delete ? getURL('document', {case: caseId, document: documentId})(state) : undefined,
    transferURL: can_transfer ? getURL('document_transfer', {case: caseId, document: documentId})(state) : undefined,
    hasFile: !(has_file === false),
  };
};

const mapDispatchToProps = (dispatch, {documentId, caseId}) => ({
  markRead: () => dispatch(markDocumentRead({documentId, caseId})),
  markUnread: () => dispatch(markDocumentUnread({documentId, caseId})),

  save: () => dispatch(saveDocument({documentId, caseId})),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {isRead, hasFile} = stateProps;
  const {markRead, markUnread, onDelete, save, ...otherDispatchProps} = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,

    // Filter irrelevant actions.
    markRead: !isRead ? markRead : undefined,
    markUnread: isRead ? markUnread : undefined,
    save: hasFile ? save : undefined,

    ...otherDispatchProps,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(DocumentDetailsMenu);
