import React from 'react';
import {NaturalTime} from '../components/data/time';
import {forEachMatchingElement, uimanager} from 'ui-manager';
import {Unshrinkable} from "../components/layout/helpers/Unshrinkable";
import {getOrCreateRoot} from "../utils/renderer-utils";
import {Providers} from "../ui/providers";

uimanager.add(el =>
  // Instantiate react-timeago components.
  forEachMatchingElement(el, ".time-ago", node => {
    const {date, inSentence} = node.dataset;
    const root = getOrCreateRoot(node);
    root.render(
      <Providers>
        <Unshrinkable><NaturalTime date={date} inSentence={inSentence}/></Unshrinkable>
      </Providers>
    );
  })
);
