//import {store} from "../stores";
import {is_compatible} from 'utils/is-browser-compatible';
//import {reportClientError} from "../features/telemetry";
import {telemetryApi} from "../api";

import log from 'loglevel';

import {serializeError} from "serialize-error";

const reportLoggedErrorsToAPI = logger => {
  // based on https://github.com/artemyarulin/loglevel-serverSend/
  if (!logger || !logger.methodFactory) {
    throw new Error("loglevel instance has to be specified in order to be extended");
  }
  const originalMethodFactory = logger.methodFactory;
  logger.methodFactory = (methodName, logLevel) => {
    const rawMethod = originalMethodFactory(methodName, logLevel);

    return message => {
      if (typeof message === 'string' || message instanceof String) {
        message = {message};
      }

      const errorReport = {
        severity: logLevel,
        error: serializeError(message),
      };

      // Try to report error via redux, but fall back to straight API call.
      // try {
      //   store.dispatch(reportClientError(errorReport));
      // } catch (e) {
      //   console.warn("Failed to report client-side error via redux, try alternative...", e);
        try {
          telemetryApi.then(api => {
            try {
              api.reportClientError({}, {requestBody: errorReport});
            } catch(e) {
              console.error("Failed to report client-side error, giving up.", e, errorReport);
            }
          });
        } catch (e) {
          console.error("Failed to report client-side error, giving up.", e);
          // Note that window.onerror must not be triggered as to avoid loops.
        }
      // }

      rawMethod(message);
    };
  };
  logger.setLevel(logger.levels.WARN);
};

// if compatible, log javascript errors to server
if (is_compatible) {
  try {
    reportLoggedErrorsToAPI(log);

    window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
      if (errorObj && errorObj.ignore_error) {
        return;
      }
      log.error({errorMsg, url, lineNumber, column, errorObj, errorType: typeof errorObj});
    }
  } catch (e) {
    console.error(e);
  }
}
