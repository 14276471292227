import React, {useEffect, useState} from 'react';
import {useAddressesDatabase, useAddressesLists} from "../../features/addresses/addresses-hooks";
import {AddressTabPanel} from "./layout";
import {ListItemIcon, ListItemText, MenuItem, Divider} from "@mui/material";
import {ListEntryForm} from "./forms/ListEntryForm";
import {Add as AddIcon, ImportExport as ImportExportIcon} from "@mui/icons-material";
import {ListForm} from "./forms/ListForm";
import {ListTable} from "./ListTable";
import {CreateListEntryForm} from "./forms/CreateListEntryForm";
import {ListExportDialog} from "./ListExportDialog";

export function ListPanel(
  {
    databaseId,
    listIds,
    onEdit,
    onClose,
    onShowList,
    searchFieldPlaceholder = "Eintrag suchen...",
    onShowPerson,
    sx
  }
) {
  const {can_update: canUpdate} = useAddressesDatabase({id: databaseId});

  const listId = listIds?.length === 1 ? listIds[0] : null;
  const isSingleList = !!listId;

  const {entries, names, is_event, isDeleted} = useAddressesLists({ids: listIds});
  const [createEvent, setCreateEvent] = useState(null);

  const [idsList, setIdsList] = useState(entries);

  const [editListEntry, setEditListEntry] = useState(null);
  const [createListEntry, setCreateListEntry] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState([]);

  const [showExport, setShowExport] = useState(false);

  const excludedItems = entries?.length - idsList?.length;

  useEffect(() => {
    if (isDeleted) {
      onClose();
    }
  }, [isDeleted])

  const menuItems = [
    isSingleList && canUpdate && idsList?.length > 0 ? (
      <Divider key="export-email-divider"/>
    ) : null,
    idsList?.length > 0 ? (
      <MenuItem
        key="export-data"
        onClick={() => setShowExport(true)}
      >
        <ListItemIcon>
          <ImportExportIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText>
          {excludedItems ? "Ansicht" : "Daten"} exportieren...
        </ListItemText>
      </MenuItem>
    ) : null,
  ];

  if (isSingleList && !is_event && canUpdate) {
    menuItems.push(
      <Divider key="create-event-divider"/>
    );
    menuItems.push(
      <MenuItem
        key="create-event"
        onClick={() => setCreateEvent(-1)}
      >
        <ListItemIcon>
          <AddIcon fontSize="small"/>
        </ListItemIcon>
        <ListItemText>
          Event erstellen...
        </ListItemText>
      </MenuItem>
    );
  }

  return (
    <AddressTabPanel
      filterValue={appliedFilters}
      onFilter={setAppliedFilters}
      onClose={onClose}
      sx={{flex: 1, ...sx}}
      searchFieldPlaceholder={searchFieldPlaceholder}
      onEdit={canUpdate ? onEdit : null}
      onAdd={canUpdate && isSingleList ? () => setCreateListEntry(-1) : null}
      menuItems={menuItems}
      options={is_event ? (
        [
          {
            category: null,
            id: 'confirmed',
            label: 'Zusage',
            choice: true,
            chipLabel: ({
              id,
              choice
            }) => ({
              [true]: "nur Zusagen",
              [false]: "nur Absagen",
            }[choice]),
            choiceLabel: ({
              id,
              choice
            }) => ({
              [true]: "ja",
              [false]: "nein",
            }[choice]),
            choices: [true, false],
          },
        ]
      ) : []}
    >
      <ListTable
        ids={entries}
        isEvent={is_event}
        onSelectItem={setEditListEntry}
        appliedFilters={appliedFilters}
        readOnly={!canUpdate}
        onChangeIdsList={setIdsList}
      />
      {editListEntry !== null ? (
        <ListEntryForm
          databaseId={databaseId}
          listEntryId={editListEntry}
          onClose={() => setEditListEntry(null)}
          onShowPerson={onShowPerson}
          readOnly={!canUpdate || !isSingleList}
        />
      ) : null}
      {isSingleList && createListEntry !== null ? (
        <CreateListEntryForm
          listId={listId}
          listEntryId={createListEntry}
          onClose={() => setCreateListEntry(null)}
        />
      ) : null}
      {createEvent !== null ? (
        <ListForm
          id="create-event"
          databaseId={databaseId}
          editList={createEvent}
          onClose={() => setCreateEvent(null)}
          is_event
          initialData={{parent: listId, is_event: true}}
          onSubmitSuccessful={(id) => onShowList(id)}
        />
      ) : null}
      {showExport ? (
        <ListExportDialog
          singleListId={listId}
          open={showExport}
          onClose={() => setShowExport(false)}
          listEntryIds={idsList}
          excludedItems={excludedItems}
          name={isSingleList ? names?.[0] : `${names?.length} ${is_event ? "Events" : "Verteilern"}`}
          isEvent={is_event}
        />
      ): null}
    </AddressTabPanel>
  );
}
