import {OWN_ADDRESS_PERSON_LINK_SCHEMA} from "../validation";
import React from "react";
import AddressEntityFormDialog from "./AddressEntityFormDialog";
import {TemplateChoiceYupField} from "./TemplateChoiceYupField";
import {Grid} from "@mui/material";
import {YupField} from "../../../packages/react-hook-form-mui-yup-helpers";
import {DEFAULT_PERSON_LINK_FORM_FIELDS, DefaultPersonLinkFormFields} from "./PersonLinkFormFields";

export function OwnPersonLinkForm({
  databaseId,
  editPersonLink,
  onClose,
  initialData,
  ...props
}) {
  return (
    <AddressEntityFormDialog
      baseUrl="/api/addresses/own_person_links/"
      databaseId={databaseId}
      id='edit-person-link'
      entityId={editPersonLink}
      entityName="Adresse"
      entityType="addresses_person_link"
      expandedFields={['location']}
      onClose={onClose}
      schema={OWN_ADDRESS_PERSON_LINK_SCHEMA}
      renderDefaultChildren
      excludeDefaultChildren={[
        ...DEFAULT_PERSON_LINK_FORM_FIELDS,
        'location.address_line',
        'location.zip',
        'location.city',
        'location.po_box_zip',
        'location.po_box',
        'location.notes',
        'salutation_template',
        'address_template',
      ]}
      allowDelete
      initialData={initialData}
      {...props}
    >
      <Grid container spacing={2}>
        <DefaultPersonLinkFormFields databaseId={databaseId}>
          <Grid item xs={12}>
            <TemplateChoiceYupField name="salutation_template" databaseId={databaseId} initialData={initialData} type="salutation"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="location.address_line"/>
          </Grid>
          <Grid item md={4} xs={12}>
            <YupField name="location.zip"/>
          </Grid>
          <Grid item md={8} xs={12}>
            <YupField name="location.city"/>
          </Grid>
          <Grid item md={4} xs={12}>
            <YupField name="location.po_box_zip"/>
          </Grid>
          <Grid item md={8} xs={12}>
            <YupField name="location.po_box"/>
          </Grid>
          <Grid item xs={12}>
            <YupField name="location.notes"/>
          </Grid>
          <Grid item xs={12}>
            <TemplateChoiceYupField name="address_template" databaseId={databaseId} initialData={initialData} type="address"/>
          </Grid>
        </DefaultPersonLinkFormFields>
      </Grid>
    </AddressEntityFormDialog>
  );
}
