import Skeleton from "@mui/material/Skeleton";
import React, {useState} from "react";

export const MultiLineSkeleton = ({size = 1000, maxLines = 6}) => {
  if (size <= 50) {
    return (
      <Skeleton width={(size * 2) + '%'}/>
    );
  }

  if (size <= 100) {
    return (
      <>
        <Skeleton width={'100%'}/>
        <Skeleton width={((size - 50) * 2) + '%'}/>
      </>
    );
  }

  const lines = Math.min(Math.ceil(size / 50), maxLines);

  // For large sizes, display some random skeleton consisting of multiple lines.
  const [numbers, setNumbers] = useState(
    Array(lines).fill(0).map(() => Math.floor(Math.random() * 81) + 20)
  );

  return (
    <>
      {numbers.map((width, i) => (
        <Skeleton key={i} width={numbers[i] + '%'}/>
      ))}
    </>
  );
};
