import React from "react";
import {useFormContext} from "react-hook-form";
import {TextField} from "packages/react-hook-form-mui";

export default function FormTextField({name, ...props}) {
  const {register, formState: {errors, isSubmitting}} = useFormContext();

  return (
    <TextField
      name={name}
      register={register}
      errors={errors}
      disabled={isSubmitting}
      {...props}
    />
  );
}
